import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'FG10-U8-P69-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
    component: T6,
    inputSize: 170,
    // isHiddenCheck: true,
    maxLength: 15,
    textAlign: 'center',
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '',
        title: `
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div style=" background-repeat: no-repeat; background-size: 95%; padding: 30px 100px 20px 110px; font-size: 23px" >
              <span><strong style="color: rgb(62, 63, 153)">Revision:</strong> <strong>Student Book page 100</strong></span>
              <div style="margin-top: 20px">
                <h1 style="font-size: 25px; color: black"><span style="margin-right: 10px">1</span>Complete the sentences with nouns formed
                from the verbs and adjectives below in brackets. </h1>
              </div>
              <div style="margin-top: 30px">
                <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                  <li><span style="font-weight: normal;">The Hubble telescope can measure the #  (move) of distant galaxies.
                  </span></li>
                  <li><span style="font-weight: normal;">The city centre was destroyed in the war. The #  (reconstruct) took many years.</span></li>
                  <li><span style="font-weight: normal;">Hard disk recorders allow us to make #  (record) of TV programmes.
                  </span></li>
                  <li><span style="font-weight: normal;">Many men hope doctors will invent a cure for # (bald).</span></li>
                  <li><span style="font-weight: normal;">The aeroplane is a wonderful # (invent).</span></li>
                  <li><span style="font-weight: normal;">That's a lovely flower # (arrange).</span></li>
                  <li><span style="font-weight: normal;">Beethoven suffered from # (deaf)
                  during the last 25 years of his life.</span></li>
                  <li><span style="font-weight: normal;">Before the # (discover) of America
                  in 1492, many people thought the Earth was
                  flat.</span></li>
                </ol>
              </div>
            </div>
            `,
        answer: [
          'movement',
          'reconstruction',
          'recordings',
          'baldness',
          'invention',
          'arrangement',
          'deafness',
          'discovery',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FG10-U8-P69-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
    component: T6,
    selectStyle: {
      color: 'black',
      fontWeight: 'bold',
      width: 150,
      textAlign: 'center',
      border: '2px solid black',
    },
    selectOptionValues: ['text 1', 'text 2', 'text 3'],
    titleQuestion: [
      {
        num: '2',
        title: `
        Read the texts and match two of the inventions with the
        photos (A–B).
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div style = "display: flex">
              <div>
                <div>
                  <b><i style='color:rgb(97, 156, 236)'>Answer:</i></b>
                  <div style = "display: flex"><b>A -&ensp;</b><select id='0'></select></div>
                  <div style = "display: flex"><b>B -&ensp;</b><select id='1'></select></div>
                        
                </div>
                <div style="height: 400px; width: 400px;border: 5px solid black; border-radius: 100rem; position: relative">
                  <img src="img/FriendsPlus/Page69/E2/1.jpg" alt="" style="width: 100%; height: 100%; object-fit: cover; border-radius: 100rem;"/>
                  <div style="padding: 0 10px; position: absolute;top: 40px; left: 40px; background-color: white; border: 1px solid">A</div>
                </div>
                <div style="height: 400px; width: 400px;border: 5px solid black; border-radius: 100rem; position: relative">
                  <img src="img/FriendsPlus/Page69/E2/2.jpg" alt="" style="width: 100%; height: 100%; object-fit: cover; border-radius: 100rem;"/>
                  <div style="padding: 0 10px; position: absolute;top: 40px; left: 40px; background-color: white; border: 1px solid">B</div>
                </div>
              </div>
              <div style="background-image: url('img/FriendsPlus/Page69/E2/3.jpg'); background-repeat: no-repeat; background-size: 100%; width: 900px; height: 1400px; z-index: 99; position: relative;" >
                <div style="position: absolute; top: 90px; left: 80px; font-size: 22px; width: 780px">
                  <span style="padding: 0 10px; border: 1px solid; margin-right: 10px"><strong>1</strong></span>
                  <span><strong>Hippo-Roller, photo ___</strong></br>
                  <span>Many women and children in Africa spend up to nine
                  hours a day carrying water to their homes. They use 20-litre
                  plastic buckets, which they carry on their heads. The buckets
                  are very heavy and often cause back and neck injuries. So
                  engineers Pettie Petzer and Johan Jonker designed a ‘rolling
                  wheel' which can carry ninety litres of water and is much
                  easier to move. 33,000 ‘Hippo-Rollers' have been delivered to
                  remote villages in Africa. The results are good: women have
                  more time to look after their families, the children can go to
                  school, and there are fewer injuries.</span>
                </div>
                <div style="position: absolute; top: 550px; left: 80px; font-size: 22px; width: 780px">
                  <span style="padding: 0 10px; border: 1px solid; margin-right: 10px"><strong>2</strong></span>
                  <span><strong>LifeStraw, photo  ___</strong></span></br>
                  <span>There are 780 million people in the world who don't have
                  clean safe drinking water. This fact led the Swiss company
                  Vestergaard to invent a special kind of straw that allows
                  people to drink dirty water without getting ill. The LifeStraw
                  is a long thin tube that cleans the water as you drink.
                  LifeStraw is very light and can clean up to 1,000 litres of
                  water before you need to replace it. LifeStraw was used to
                  help people after the Haiti earthquake, and floods in Pakistan
                  and Thailand.</span>
                </div>
                <div style="position: absolute; top: 950px; left: 80px; font-size: 22px; width: 780px">
                  <span style="padding: 0 10px; border: 1px solid; margin-right: 10px"><strong>3</strong></span>
                  <span><strong>AidPod, photo ___</strong></span></br>
                  <span>When Simon Berry was travelling in Zambia, he noticed that he
                  could buy fizzy drinks in the remotest villages. But the people
                  had no medicines, and one in five children were dying before
                  their fifth birthday. He had an idea. ‘Why don't they bring
                  medicines when they deliver the fizzy drinks?' So he invented the
                  AidPod, a triangular packet of medicines that fits between the
                  bottles in a crate. Berry is testing his ideas in Zambia. If his plan
                  works, he hopes to do the same thing in other African countries.</span>
                </div>
              </div>
            </div>
            `,
        answer: ['text 3', 'text 2'],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FG10-U8-P69-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
    component: T6,
    selectStyle: {
      color: 'black',
      fontWeight: 'bold',
      width: 100,
      textAlign: 'center',
      border: '2px solid black',
    },
    selectOptionValues: ['T', 'F', 'DNS'],
    titleQuestion: [
      {
        num: '3',
        title: `
        Read the <span style="color: rgb(109, 68, 153)">Listening Strategy</span>. Then decide if the sentences
        below are true (T), false (F) or ‘does not say' (DNS).
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 1100px; gap: 30px 0; margin-top: 20px">
                <div style="background-image: url('img/FriendsPlus/Page69/E3/1.jpg'); background-repeat: no-repeat; background-size: 700px auto; height: 320px;width:700px; padding: 20px 100px 30px 30px; border-radius: 16px">
                  <h2 style="font-size: 24px; color: rgb(109, 68, 153)">Reading Strategy</h2>
                  <span style="font-size: 21px">Sometimes, true / false tasks include a third option:
                  the text does not say. Choose the third option if
                  the text does not contain enough information to
                  clearly say if the sentence is true or false. Do not
                  use your own knowledge (or guesswork) to fill any
                  information gaps!</span>
                </div>
                <div>
                  <div style='display:flex; gap:15px;'>
                    <b>1</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>Carrying water on your head can cause neck injuries.</div>
                        <select id='0'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>2</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>With the Hippo-Roller people can carry more water than with a normal bucket. </div>
                        <select id='1'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>3</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>The LifeStraw is made from natural materials. </div>
                        <select id='2'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>4</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>The LifeStraw never stops working. </div>
                        <select id='3'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>5</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>A fizzy drinks company worked with Simon Berry to design the AidPod. </div>
                        <select id='4'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>6</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 600px;'>
                        <div>The AidPod is used successfully in many different countries. </div>
                        <select id='5'></select>
                    </div>
                  </div>
                </div>
              </div>
            `,
        answer: ['T', 'T', 'DNS', 'F', 'DNS', 'F'],
      },
    ],
  },
};

export default json;
