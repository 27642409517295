import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 5',
		id: 'WB10-U5-P44-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Complete the text with the prefixes below.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
			width: 80,
			borderBottom: 'none',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page44/E1/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
          <div style="width: 1100px; display: flex;align-items: stretch; column-gap: 10px ">
            <div style=" position: relative;">
              <img src="img/FriendsPlus/Page44/E1/1.png" style="height: 700px; width: 600px; object-fit: contain;">
              <div style="position: absolute; top: calc(50% + 4px + 22px); left: calc(50% - 90px)">#</div>
              <div style="position: absolute; top: calc(50% + 4px + 86px); left: calc(50% - 108px)">#</div>
              <div style="position: absolute; top: calc(50% + 4px + 86px); left: calc(50% + 70px)">#</div>
              <div style="position: absolute; top: calc(50% + 4px + 150px); left: calc(50% + 105px)">#</div>
              <div style="position: absolute; top: calc(80% + 8px); left: calc(20% + 54px )">#</div>
              <div style="position: absolute; top: calc(80% + 30px); left: calc(20% + 84px )">#</div>
            </div>
            <div style="display: flex; flex-wrap: wrap; gap: 20px; width: calc(500px - 10px); height: 30px; margin-top: 20px">
              <span style="color: rgb(117 6 58); font-weight: 700">ex</span>
              <span style="color: rgb(117 6 58); font-weight: 700">micro</span>
              <span style="color: rgb(117 6 58); font-weight: 700">multi</span>
              <span style="color: rgb(117 6 58); font-weight: 700">sub</span>
              <span style="color: rgb(117 6 58); font-weight: 700">over</span>
              <span style="color: rgb(117 6 58); font-weight: 700">under</span>
            </div>
          </div>
        `,
				answer: ['multi', 'sub', 'under', 'ex', 'micro', 'over'],
			},
		],
	},
	2: {
		unit: 'Unit 5',
		id: 'WB10-U5-P44-E2',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the sentences with the prefixes below.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
			// fontSize: 20,
			width: 80,
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
        <div style="display: flex; flex-wrap: wrap; gap: 20px; width: 800px;">
          <span style="color: rgb(117 6 58); font-weight: 700">co</span>
          <span style="color: rgb(117 6 58); font-weight: 700">mini</span>
          <span style="color: rgb(117 6 58); font-weight: 700">mis</span>
          <span style="color: rgb(117 6 58); font-weight: 700">multi</span>
          <span style="color: rgb(117 6 58); font-weight: 700">over</span>
          <span style="color: rgb(117 6 58); font-weight: 700">post</span>
          <span style="color: rgb(117 6 58); font-weight: 700">re</span>
          <span style="color: rgb(117 6 58); font-weight: 700">semi</span>
        </div>

        <div style="margin-top: 20px">
          <div>
            <strong>1</strong>
            <span style="margin-left: 20px">My dad is</span>
            <input  />
            <span>-retired - he still works two or three mornings a week</span>
          </div>
          <div>
            <strong>2</strong>
            <span style="margin-left: 20px">The company lost millions because of</span>
            <input  />
            <span>management at the highest level.</span>
          </div>
          <div>
            <strong>3</strong>
            <span style="margin-left: 20px">Six of my</span>
            <input  />
            <span>-workers have lost their jobs in the past year.</span>
          </div>
          <div>
            <strong>4</strong>
            <span style="margin-left: 20px">My last proper holiday from work was four years ago, although I've had a few</span>
            <input  />
            <span>-breaks</span>
          </div>
          <div>
            <strong>5</strong>
            <span style="margin-left: 20px">If we can't find the right person for the job, we'll just have to</span>
            <input  />
            <span>-advertise</span>
          </div>
          <div>
            <strong>6</strong>
            <span style="margin-left: 20px">She finished her university degree and then did a </span>
            <input  />
            <span>graduate qualification</span>
          </div>
          <div>
            <strong>7</strong>
            <span style="margin-left: 20px">She never stops for lunch: she just drinks coffee and takes</span>
            <input  />
            <span>vitamin pills!</span>
          </div>
          <div>
            <strong>8</strong>
            <span style="margin-left: 20px">I don't want to sound</span>
            <input  />
            <span>confident, but I'm sure I'll be good at this job.</span>
          </div>
        </div>
        `,
				answer: ['semi', 'mis', 'co', 'mini', 're', 'post', 'multi', 'over'],
			},
		],
	},
	3: {
		unit: 'Unit 5',
		id: 'WB10-U5-P44-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title:
					'Complete the second sentence so that it means the same as the first. Use one word only, beginning with a prefix from exercise 1 or 2.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
			width: 200,
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page44/E3/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
        <div >
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>1</strong>
            <div>
              <p>You cooked this meat for too long.</p>
              <p>You <span style="font-style: oblique; font-size: 23px; color: rgb(0 68 98);">overcooked</span> this meat.</p>
            </div>
          </div>
        
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>2</strong>
            <div>
              <p>Our team did not perform well enough last season.</p>
              <p>Our team <input /> last season.</p>
            </div>
          </div>
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>3</strong>
            <div>
              <p>I met a former school friend in a café in town.</p>
              <p>I met an <input /> in a café in town.</p>
            </div>
          </div>
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>4</strong>
            <div>
              <p>She's an expert in history after World War II.</p>
              <p>She's an expert in <input /> history.</p>
            </div>
          </div>
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>5</strong>
            <div>
              <p>I'm sorry, I typed your name incorrectly.</p>
              <p>I'm sorry, I <input /> your name.</p>
            </div>
          </div>
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>6</strong>
            <div>
              <p>They bought the house, but sold it again a year later.</p>
              <p>Our team <input /> last season.</p>
            </div>
          </div>
          <div style=" display: flex;align-items: stretch; column-gap: 20px">
            <strong>7</strong>
            <div>
              <p>Ben Stiller wrote Zoolander with two other writers.</p>
              <p>Ben Stiller and two other writers  <input /> Zoolander.</p>
            </div>
          </div>
        
        
          
        </div>
        `,
				answer: [
					'underperformed',
					'ex-school friend ',
					'post-war ',
					'mistyped',
					'resold',
					'co-wrote ',
				],
			},
		],
	},
	4: {
		unit: 'Unit 5',
		id: 'WB10-U5-P44-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="font-style: oblique; color: rgb(117 6 58);">Vocab boost!</span> box. Add these words and phrases to the mind-map for jobs.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			textAlign: 'left',
			fontSize: 20,
			width: 400,
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page44/E4/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html */ `
        <div style="width: 1100px; display: flex;align-items: stretch; column-gap: 40px ">
          <div style=" position: relative;">
            <img src="img/FriendsPlus/Page44/E4/1.png" style="height: 700px; width: 600px; object-fit: contain;">
          </div>
          <div>
          <div style="display: flex; flex-wrap: wrap; gap: 20px; width: calc(500px - 10px); height: 250px; margin-top: 50px">
            <span style="color: rgb(117 6 58);">answer the phone</span>
            <span style="color: rgb(117 6 58);">badly-paid</span>
            <span style="color: rgb(117 6 58);">gardener</span>
            <span style="color: rgb(117 6 58);">hairdresser</span>
            <span style="color: rgb(117 6 58);">lorry driver</span>
            <span style="color: rgb(117 6 58);">paramedic</span>

            <span style="color: rgb(117 6 58);">programmer</span>
            <span style="color: rgb(117 6 58);">receptionist</span>
            <span style="color: rgb(117 6 58);">repetitive</span>
            <span style="color: rgb(117 6 58);">serve customers</span>
            <span style="color: rgb(117 6 58);">tiring</span>
            <span style="color: rgb(117 6 58);">well-paid</span>
          </div>
          <div>
          <div style="margin-top: 20px; display: flex;justify-content: space-between;">
            <strong >indoors:</strong>
            <input  />
          </div>
          <div style="margin-top: 20px; display: flex;justify-content: space-between;">
            <strong >activities:</strong>
            <input  />
          </div>
          <div style="margin-top: 20px; display: flex;justify-content: space-between;">
            <strong >outdoors:</strong>
            <input  />
          </div>
          <div style="margin-top: 20px; display: flex;justify-content: space-between;">
            <strong >describing:</strong>
            <input  />
          </div>
        </div>
          </div>
        
      </div>
      
        `,
				answer: [
					'hairdresser, programmer, receptionist',
					'answer the phone, serve customers',
					'gardener, lorry driver, paramedic',
					'badly-paid, repetitive, tiring, well-paid',
				],
			},
		],
	},
}

export default json
