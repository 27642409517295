import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P25-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page25/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Choose the correct words to complete the sentences.",
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "some / any",
          "some / any",
          "Some / any",
          "some / any",
          "some / any",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-4"],
        initialValue: [],
      },
      Layout: `
      <div style='line-height: 45px;'>
        <b>1</b>&emsp;I've got <input id='0' type='Circle' /> homework to finish this evening.<br>
        <b>2</b>&emsp;Have you got <input id='1' type='Circle' /> pets? <br>
        <b>3</b>&emsp;<input id='2' type='Circle' /> people play video games every day. <br>
        <b>4</b>&emsp;Is there <input id='3' type='Circle' /> ice on the road?<br>
        <b>5</b>&emsp;There weren't <input id='4' type='Circle' /> special effects in the film.
      </div>
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P25-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E2/key/answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the text messages with some or any.
			  `,
        color: "black",
      },
    ],
    inputSize: 138,
    stylesTextInput: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: 22,
    },
    maxLength: 4,
    titleImage: "",
    questions: [
      {
        title: `
          <div style="position: relative">
            <div><img src='img/FriendsPlus/Page25/E2/1.jpg' style='max-width: 90%;' /></div>
            
            <div style=" position: absolute; top: 140px; left: 293px; ">#</div>
            <div style=" position: absolute; top: 230px; left: 281px; ">#</div>
            <div style=" position: absolute; top: 259px; left: 293px; ">#</div>
            <div style=" position: absolute; top: 302px; left: 232px; ">#</div>
            <div style=" position: absolute; top: 370px; left: 266px; ">#</div>
            <div style=" position: absolute; top: 436px; left: 378px; ">#</div>
            <div style=" position: absolute; top: 464px; left: 318px; ">#</div>
            <div style=" position: absolute; top: 527px; left: 378px; ">#</div>
            <div style=" position: absolute; top: 620px; left: 201px; ">#</div>
          </div>
          `,
        answer: [
          "some",
          "any",
          "some",
          "some",
          "any",
          "some",
          "some",
          "any",
          "any",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P25-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E3/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with <i>a few</i> or <i>a little</i>.
			  `,
        color: "black",
      },
    ],
    inputSize: 138,
    stylesTextInput: {
      borderBottom: "dash",
      textAlign: "center",
      // backgroundColor: "#fee4cb",
      // fontSize: 23,
    },
    maxLength: 8,

    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:45px'>
            <b>1</b>&emsp;I saw Harry#hours ago.<br>
            <b>2</b>&emsp;I only want#water. I'm not very thirsty.<br>
            <b>3</b>&emsp;There are#good cafés in our town.<br>
            <b>4</b>&emsp;Please spend#time tidying your room.<br>
            <b>5</b>&emsp;I only speak#French.<br>
        </div>

			  `,
        answer: ["a few", "a little", "a few", "a little", "a little"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P25-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E4/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "4",
        title: `
				Complete the questions with <i>How much</i> or <i>How many</i>.
			  `,
        color: "black",
      },
    ],
    inputSize: 140,
    stylesTextInput: {
      borderBottom: "dash",
      textAlign: "center",
      // backgroundColor: "#fee4cb",
      // fontSize: 23,
    },
    maxLength: 8,

    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:45px'>
            <b>1</b>&emsp;#films do you see at the cinema each month?<br>
            <b>2</b>&emsp;#time do you spend watching TV each day?<br>
            <b>3</b>&emsp;#text messages do you send each day?<br>
            <b>4</b>&emsp;#money do you spend on DVDs and video games each month?<br>
            <b>5</b>&emsp;#televisions are there in your home?<br>
        </div>

			  `,
        answer: ["How many", "How much", "How many", "How much", "How many"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P25-E5",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    exerciseKey: "",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title: `
				Answer the questions in exercise 4.
			  `,
        color: "black",
      },
    ],
    inputSize: 740,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:45px'>
            <b>1</b>&emsp;__________ films do you see at the cinema each month?<br>#<br>
            <b>2</b>&emsp;__________ time do you spend watching TV each day?<br>#<br>
            <b>3</b>&emsp;__________ text messages do you send each day?<br>#<br>
            <b>4</b>&emsp;__________ money do you spend on DVDs and video games each month?<br>#<br>
            <b>5</b>&emsp;__________ televisions are there in your home?<br>#<br>
        </div>
			  `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "WB10-U3-P25-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page25/E6/key/answerKey.png",
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "6",
        title: `
				Complete the video game review with the words and phrases below. Use each word or phrase only once.
			  `,
        color: "black",
      },
    ],
    inputSize: 150,
    stylesTextInput: {
      borderBottom: "1px solid gray",
      textAlign: "center",
      backgroundColor: "transparent",
      fontSize: 22,
      height: 23,
    },
    maxLength: 8,
    titleImage: "",
    questions: [
      {
        title: `
					<div style="display: flex; flex-wrap: wrap; gap: 30px; color: rgb(246, 141, 31); font-size: 22px;">
						<span>a few</span><span>a little</span><span>any</span><span>how many</span>
						<span>how much</span><span>many</span><span>much</span><span>some</span>
					</div>
					<div style="margin-top: 20px; display: flex; gap: 20px; flex-wrap: wrap;">
						<div style="flex: 1; background: rgb(254, 228, 203); padding: 20px; border-radius: 20px; font-size: 22px;">
							I really enjoyed this game. The aim is to build a city,
							but there are a lot of problems to solve along the way.
							<sup>1</sup># houses, hotels, roads and factories can you build?
							<sup>2</sup># money and energy do you need to build them?
							Players use money called 'megabucks'. You start with just <sup>3</sup># megabucks,
							so you must spend them carefully. It's difficult to build a city on your own,
							so <sup>4</sup># players work together. For example, if you have only got 5# energy,
							you can borrow or buy <sup>6</sup># electricity from a neighbour.
							But selfish and greedy players don't make <sup>7</sup># friends.
							If they haven't got <sup>8</sup># money or energy,
							no one helps them and they can't finish their city.
							So it's best to be friendly to the other players!
						</div>
						<div style="flex: 1;">
							<img src="img/FriendsPlus/Page25/E6/2.jpg" style="border-radius: 20px; max-width: 100%; height: auto;" />
						</div>
					</div>
			  `,
        answer: [
          "How many",
          "How much",
          "a few",
          "many",
          "a little",
          "some",
          "any",
          "much",
        ],
      },
    ],
  },
};

export default json;
