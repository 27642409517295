import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E1',
		component: T6,
		maxLength: 1,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderRadius: '10px',
			border: '1px solid',
			padding: 10,
			fontSize: 22,
			flex: 'end',
		},
		titleQuestion: [
			{
				num: '1',
				title:
					'Match sentences a–f with uses of the article or no article (1–6).',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
		inputSize: 33,
		questionImage: [],
		questions: [
			{
				title: `
               <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> it is clear what we are talking about</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> saying what someone's job is</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> something mentioned for the first time</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> the only one of something</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> a generalisation</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12"> something mentioned for the second time </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex; margin-top: 30px"><div style="display: inline-block; width: 40px; text-align: right;"><b>a </b></div><div style="margin-left: 30px; flex: 1; ">There is <b>a</b> strange man in our street.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>b </b></div><div style="margin-left: 30px; flex: 1; ">Chad has a new car. <b>The</b> car is black and silver.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>c </b></div><div style="margin-left: 30px; flex: 1; "><b>The</b> moon is very bright tonight.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>d </b></div><div style="margin-left: 30px; flex: 1; ">We meet in <b>the</b> café after school.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>e </b></div><div style="margin-left: 30px; flex: 1; ">Gemma is <b>a</b> shop assistant.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>f </b></div><div style="margin-left: 30px; flex: 1; ">I'm really interested in <b>(–)</b> science.</div></div>
              </div>
        `,
				answer: ['d', 'e', 'a', 'c', 'f', 'b'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E2',
		component: T6,
		selectStyle: {
			color: '#00ADFE',
			width: 66,
			textAlign: 'center',
			fontSize: 17,
			marginInline: '20px',
		},
		selectOptionRandom: true,
		selectInputSize: 'middle', // large, middle(default), small
		exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
		selectOptionValues: ['the', '–'],
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the gaps with the or no article (–).',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I enjoy walking in <select id='0'></select> rain – it's so peaceful.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Some young people don't see <select id='1'></select> point of studying <select id='2'></select> history. </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I'll meet you at <select id='3'></select> railway station at 6 o'clock.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Do you like <select id='4'></select> action films?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I don't like to see <select id='5'></select> young children playing <select id='6'></select> violent sports like rugby.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">It's likely I'll be late for <select id='7'></select> school tomorrow.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I love <select id='8'></select> food in this restaurant, but then I always enjoy <select id='9'></select> Italian food.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">All over <select id='10'></select> world, <select id='11'></select> people have similar problems.</div></div>
            </div>
        `,
				answer: [
					'the',
					'the',
					'–',
					'the',
					'–',
					'–',
					'–',
					'–',
					'the',
					'–',
					'the',
					'–',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E3',
		component: T6,
		selectStyle: {
			color: '#00ADFE',
			width: 80,
			textAlign: 'center',
			fontSize: 17,
			marginInline: '20px',
		},
		selectOptionRandom: true,
		selectInputSize: 'large', // large, middle(default), small
		exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
		selectOptionValues: ['a', 'an', 'the', '–'],
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: 'Complete the gaps with a / an, the or no article (–).',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">This is <select id='0'></select> school where I study English.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">She has <select id='1'></select> nice car, but she wants a better one.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Do you watch <select id='2'></select> TV often?</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">There is <select id='3'></select> interesting article in <select id='4'></select> newspaper.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I want to go to <select id='5'></select> cinema this evening.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I like watching <select id='6'></select> baseball.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">She is in <select id='7'></select> hospital because she had <select id='8'></select> accident.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Julie's mum is <select id='9'></select> doctor.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">It is so nice to see <select id='10'></select> sun again!</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Is it time to go to <select id='11'></select> bed already?</div></div>
            </div>
        `,
				answer: [
					'the ',
					'a ',
					'– ',
					'an',
					'the ',
					'the ',
					'– ',
					'–',
					'an ',
					'a ',
					'the ',
					'–',
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E4',
		audio: '',
		video: '',
		component: T6,
		maxLength: 1,
		textAlign: 'center',
		isHiddenCheck: true,
		checkUppercase: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '4',
				title: 'Complete the gaps with a / an, the or no article (–).',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page7/E4/Key/answerKey.png',
		inputSize: 90,
		questionImage: [],
		questions: [
			{
				title: `
        <div style="padding: 20px; border-radius: 2px; background-image: linear-gradient(to top right,white, white, rgb(204, 205, 207), rgb(204, 205, 207));box-shadow: -3px 3px 5px rgb(227, 226, 227), 3px -3px 5px rgb(227, 226, 227); font-size: 22px; line-height: 30px; transform: rotate(-2deg) ">
          <div style="transform: rotate(2deg)">
            Hi Mason,</br></br>
            How are you? Are you enjoying <sup>1</sup> # winter holidays? </br>
            You asked about my school, so I'll tell you a bit about it. It's </br>
            <sup>2</sup> # very old building – they built <sup>3</sup> #</br>
            ‘new' part in 1930! It's very nice inside, though. <sup>4</sup> #</br>
            classrooms are bright and clean, and some of them have </br>
            interactive whiteboards. Of course, everyone wants to use </br>
            <sup>5</sup> # classrooms with <sup>6</sup> # new </br>
            whiteboards! We have lovely playing fields near the school, but </br>
            there isn't <sup>7</sup> # swimming pool.</br>
            I looked up your school online, and it looks like <sup>8</sup> #</br>
            very new building from <sup>9</sup> # photographs I saw. </br>
            Do you like it there?</br></br>
            Write to me soon.</br></br>
            Best wishes,</br></br>
            Tom</br></br>
          </div>
        </div>
        `,
				answer: [
					'the ',
					'a ',
					'the ',
					'The ',
					'the ',
					'the ',
					'a ',
					'a ',
					'the ',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E5',
		component: T6,
		selectStyle: {
			color: '#00ADFE',
			width: 140,
			textAlign: 'center',
			fontSize: 17,
			marginInline: '5px',
		},
		selectOptionRandom: true,
		selectInputSize: 'large', // large, middle(default), small
		exerciseKey: 'img/FriendsPlus/Page7/E5/Key/answerKey.png',
		selectOptionValues: ['There are ', 'There is ', 'there is ', 'there are '],
		isHiddenCheck: true,
		checkUppercase: true,
		titleQuestion: [
			{
				num: '5',
				title: 'Complete the sentences with there is or there are.',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
            <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='0'></select> three new students in our class.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='1'></select>a great drama class at my school.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='2'></select>about 50 laptops in our computer lab.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I think<select id='3'></select> a good film on at the cinema.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='4'></select>only two shows I watch on TV.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='5'></select>some really nice clothes in that shop.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">Ethan,<select id='6'></select> a man here who wants to talk to you.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; display: flex ">I know <select id='7'></select> an answer to this problem, but I can't find it.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; display: flex "><select id='8'></select> people from many different countries in London.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; display: flex "> I want to go home.<select id='9'></select> a storm coming.</div></div>
            </div>
        `,
				answer: [
					'There are ',
					'There is ',
					'There are ',
					'there is ',
					'There are ',
					'There are ',
					'there is ',
					'there is ',
					'There are ',
					'There is',
				],
			},
		],
	},

	6: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P7-E6 ',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page7/E6/Key/answerKey.png',
		inputSize: 160,
		titleQuestion: [
			{
				num: '6',
				title: 'Find and correct the mistakes in the sentences.',
			},
		],
		questions: [
			{
				title: `
            <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">It is <b>a</b> first day of school tomorrow. <b>✗ the first day</b></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">They are three students from China in my class this year. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">I play in football team with classmates from my school. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">My dad leaves for the work at 7 a.m. every morning. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">Kelly's sister is an dancer. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">It is a great film on TV tonight. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; ">We have the great computer lab in our school. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">We have a new car. A car is very fast. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; ">I'm giving Maggie the CD of dance music for her birthday. <b>✗</b> # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>10. </b></div><div style="margin-left: 30px; flex: 1; ">I'm interested in the geography. It's my favourite subject. <b>✗</b> # </div></div>
              </div>
        `,
				answer: [
					'There are three ',
					'in a football ',
					'for work ',
					'is a dancer ',
					'There is a ',
					'have a great ',
					'The car is',
					'Maggie a CD ',
					'in geography',
				],
			},
		],
	},
};

export default json;
