import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB10-U2-P18-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        <b>
          <span style="color: rgb(192,19,78);text-transform: uppercase">use of english</span> Read the text about BASE jumping and
          choose the correct answers (a– c) to complete gaps 1–5
        </b>
        `,
      },
    ],
    exerciseKey: "/img/FriendsPlus/Page18/E1/Key/answerKey.png",
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: "5px 0",
          border: "none",
          borderColor: "transparent",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "solid 2px",
          borderColor: "#00a8ec",
          margin: "-2px",
          padding: "2px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;alike&emsp;&emsp;&ensp;&ensp;&nbsp;&nbsp;&nbsp; b|&emsp;like&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c|&emsp;similar",
          "a|&emsp;However&emsp;&emsp; b|&emsp;Otherwise&emsp;&emsp;&emsp; c|&emsp;Instead",
          "a|&emsp;part&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&nbsp b|&emsp;place&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|&emsp;off",
          "a|&emsp;thanks&emsp;&emsp;&emsp; b|&emsp;because&emsp;&emsp;&emsp;&ensp;&nbsp c|&emsp;according",
          "a|&emsp;invented&ensp;&ensp;&ensp;&ensp;&nbsp b|&emsp;decided&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; c|&emsp;thought",
        ],
        answers: ["0-8", "1-8", "2-4", "3-0", "4-8"],
        initialValue: [],
      },
      Layout: `
				<div style="display: flex; gap: 20px; align-items: start;">
					<div style="flex: 1; padding: 10px; border-radius: 10px; border: 1px solid rgb(200, 51, 80); font-size: 22px;">
						<div>
							<span style="margin-right: 10px;"><b>1 </b> </span>
							<span> <input type="Circle" id="0"></span>
						</div>
						<div>
							<span style="margin-right: 10px;"><b>2 </b> </span>
							<span> <input type="Circle" id="1"></span>
						</div>
						<div>
							<span style="margin-right: 10px;"><b>3 </b> </span>
							<span> <input type="Circle" id="2"></span>
						</div>
						<div>
							<span style="margin-right: 10px;"><b>4 </b> </span>
							<span> <input type="Circle" id="3"></span>
						</div>
						<div>
							<span style="margin-right: 10px;"><b>5 </b> </span>
							<span> <input type="Circle" id="4"></span>
						</div>
					</div>

					<div style="flex: 1; padding: 10px; border-radius: 10px; background-color: rgb(243, 214, 211);">
						<img src="/img/FriendsPlus/Page18/E1/E1.png"  style="max-width: 100%;">
					</div>
				</div>
      `,
    },
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P18-E2",
    exerciseKey: "/img/FriendsPlus/Page18/E2/Key/answerKey.png",
    audio: "/Audios/1.06.mp3",
    video: "",
    component: T6,
    inputSize: 150,
    maxLength: 16,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: "center",
      fontSize: 22,
    },
    titleQuestion: [
      {
        num: "2",
        title: `
					<headphone name="1.06"></headphone>
					<b>
						Read the <span style="color: rgb(192,19,78);">Listening Strategy</span>. Then listen to Alex
						talking about wingsuit flying. Write the words he uses
						instead of the underlined words
					</b>
      `,
      },
    ],
    questions: [
      {
        title: `
					<div style="display: flex; gap: 20px; align-items: center; font-size: 22px;">
						<div style="flex: 1; padding: 20px 10px; border-radius: 10px; border: 1px solid rgb(188, 5, 61);">
							<div style="display: flex; gap: 20px;">
								<div style="width: 40px; font-weight: bold; text-align: right;">1</div>
								<div>He spent <span style="text-decoration: underline;">a long time</span> trying to find the right sport. <br />#</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="width: 40px; font-weight: bold; text-align: right;">2</div>
								<div>He <span style="text-decoration: underline;">disliked</span> team games when he was a teenager. <br />#</div>
							</div>
							<div style="display: flex; gap: 20px;">
								<div style="width: 40px; font-weight: bold; text-align: right;">3</div>
								<div>He thinks wingsuit flying is a <span style="text-decoration: underline;">great</span> feeling. <br />#</div>
							</div>
							<div style="margin-top: 7px; display: flex; gap: 20px;">
								<div style="width: 40px; font-weight: bold; text-align: right;">4</div>
								<div>Unfortunately, <span style="text-decoration: underline;">it is expensive.</span> <br />#</div>
							</div>
						</div>
						
						<div style="flex: 1; ">
							<img src="/img/FriendsPlus/Page18/E2/E2.jpg" style="max-width: 100%;">
						</div>
					</div>
					`,
        answer: ["ages", `didn't enjoy`, "amazing", `it isn't cheap`],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB10-U2-P18-E3",
    exerciseKey: "/img/FriendsPlus/Page18/E3/Key/answerKey.png",
    audio: "/Audios/1.07.mp3",
    video: "",
    component: T6,
    inputSize: 40,
    maxLength: 1,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontWeight: "bold",
      backgroundColor: "transparent",
      borderBottom: "none",
      fontSize: 18,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
      <headphone name="1.07"></headphone>
      <b>
        Listen to three other people talking about the
        extreme sports in the photos above. Match speakers 1–3
        with photos A–C.
      </b>
      `,
      },
    ],

    questions: [
      {
        title: `
     
    <div style='display: flex;'>
              <div style='position: relative;margin-left: 250px;'>
              <div><img src="/img/FriendsPlus/Page18/E3/E3.png"></div>
              <div style='position: absolute; top: 279px; left: 111px;'>#</div>
              <div style='position: absolute; top: 506px; left: 103px;'>#</div>
              <div style='position: absolute; top: 724px; left: 97px;'>#</div>
            </div>
          </div>

      `,
        answer: ["2", "3", "1"],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB10-U2-P18-E4",
    exerciseKey: "/img/FriendsPlus/Page18/E4/Key/answerKey.png",
    audio: "/Audios/1.07.mp3",
    video: "",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    inputSize: 40,
    maxLength: 1,
    stylesTextInput: {
      backgroundColor: "transparent",
      borderRadius: "10px",
      border: "1px solid",
      padding: 10,
      fontSize: 22,
      // flex: 'end',
    },
    titleQuestion: [
      {
        num: "4",
        title: `
					<headphone name="1.07"></headphone>
					<b>
						Listen again. Are these sentences true (T) or false
						(F)? To help you, listen for synonyms or antonyms of the
						underlined words.
					</b>
					`,
      },
    ],
    questions: [
      {
        title: `
					<div style="line-height: 50px;">
						<div><b>Speaker 1</b></div>
						<b>1 </b> <span> When he first saw the sport, he thought it <span style="text-decoration: underline;">looked impossible</span> .</span> <span> #</span> <br>
						<b>2 </b> <span> He thinks the sport <span style="text-decoration: underline;"> feels worse</span> when you're actually
						doing it. </span> <span> #</span> <br>
					</div>
					
					<div style="line-height: 50px;">
						<div><b>Speaker 2</b></div>
						<b>3 </b> <span> She wanted a sport that was <span style="text-decoration: underline;">physically difficult</span>. </span> <span> #</span> <br>
						<b>4 </b> <span> She <span style="text-decoration: underline;">often thinks</span> about the risks involved in her sport.</span> <span> #</span> <br>
					
					</div>
					<div style="line-height: 50px;">
					<div><b>Speaker 3</b></div>
					<b>5 </b> <span> He <span style="text-decoration: underline;">was familiar with</span> the sport before he went to Nicaragua.</span> <span> #</span> <br>
					<b>6 </b> <span> He found the experience <span style="text-decoration: underline;">not very exciting</span> .</span> <span> #</span> <br>
					</div>
      `,
        answer: ["T", "F", "T", "F", "F", "F"],
      },
    ],
  },
};

export default json;
