import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	1: {
		unit: 'Unit 4',
		id: 'WB10-U4-P35-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
		component: T6v2,
		textAlign: 'center',
		inputSize: 210,
		titleQuestion: [
			{
				num: '1',
				title: `
        Complete the facts with the superlative form of the adjectives in brackets and the correct answer, a, b or c.
        `,
				color: 'black',
			},
		],
		isHiddenCheck: true,
		selectStyle: {
			color: 'black',
			fontWeight: 'bold',
			width: 40,
			textAlign: 'center',
			border: '2px solid black',
			borderRadius: '20%',
		},
		selectOptionValues: ['a', 'b', 'c'],

		questions: [
			{
				title: `  
          <div style="display: flex; flex-direction: column; gap: 20px;">

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">1</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (high) mountain in Europe is</span> 
                  <select id='9'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>Elbrus</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>Everest</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>Kilimanjaro</span></div>
                </div>
              </div>
            </div>
            
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">2</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (far) planet from the Sun is </span> 
                  <select id='10'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>Earth</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>Mercury</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>Neptune</span></div>
                </div>
              </div>
            </div>
            
            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">3</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (deep) ocean is </span> 
                  <select id='11'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>the Arctic</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>the Alantic</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>the Pacific</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">4</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (sunny) part of England is </span> 
                  <select id='12'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>the north</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>the south</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>the centre</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">5</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (polluted) city in the world is </span> 
                  <select id='13'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>NewYork</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>New Orleans</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>New Delhi</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">6</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (common) cause of a tsunami is </span> 
                  <select id='14'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>an earthquake</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>a volcanic eruption</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>a storm</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">7</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The country with the # (long) coast is </span> 
                  <select id='15'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>Russia</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>Spain</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>Canada</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">8</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (large) island in the world is </span> 
                  <select id='16'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>Great Britain</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>Greenland</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>Iceland</span></div>
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 20px;">
              <div style="font-weight: bold;">9</div>
              <div>
                <div style="display: flex; gap: 10px;">
                  <span>The # (dry) continent in the world is </span> 
                  <select id='17'></select>.
                </div>
                <div style="display: flex; justify-content: space-between; margin-top: -15px;">
                  <div style="display: flex; gap: 10px;"><strong>a</strong><span>Antarctica</span></div>
                  <div style="display: flex; gap: 10px;"><strong>b</strong><span>Australia</span></div>
                  <div style="display: flex; gap: 10px;"><strong>c</strong><span>Africa</span></div>
                </div>
              </div>
            </div>

          </div>
			  `,
				answer: [
					'highest',
					'furthest / farthest',
					'deepest',
					'sunniest',
					'most polluted',
					'most common',
					'longest',
					'largest',
					'driest',
					'a',
					'c',
					'c',
					'b',
					'c',
					'a',
					'c',
					'b',
					'a',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P35-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '2',
				title: `
        Complete the sentences with the correct superlative form of the adjectives in brackets. Then circle <i>in</i> or <i>of</i>.
        `,
				color: 'black',
			},
		],
		question: {
			Write: {
				isHiddenCheck: true,
				inputStyle: {
					borderBottom: '',
					color: '#000',
					textAlign: 'center',
					maxLength: 16,
				},
				answers: [
					'largest',
					'most dangerous',
					'worst',
					'hottest',
					'most expensive',
				],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					fontWeight: 'bold',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					border: 'solid 3px',
					borderColor: 'gray',
					padding: '5px',
				},
				limitSelect: 1,
				listWords: ['in / of', 'in / of', 'in / of', 'in / of', 'in / of'],
				answers: ['0-0', '1-0', '2-4', '3-4', '4-0'],
				initialValue: [],
			},
			Layout: `
        <div style='display:flex; '>
          <div><b>1 &emsp;</b></div>
          <div>Birmingham is one of the <input id='0' width='200px'/>(large) cities <input id='0' type='Circle' /> England.</div>
        </div>
        <div style='display:flex; '>
          <div><b>2 &emsp;</b></div>
          <div>Crocodiles are one of the <input id='1' width='200px'/>(dangerous) animals <input id='1' type='Circle' /> the world.</div>
        </div>
        <div style='display:flex; '>
          <div><b>3 &emsp;</b></div>
          <div>The special effects are the <input id='2' width='200px'/>(bad) part <input id='2' type='Circle' /> the film <i>Terminator 2</i>.</div>
        </div>
        <div style='display:flex; '>
          <div><b>4 &emsp;</b></div>
          <div>In Australia, the <input id='3' width='200px'/>(hot) month <input id='3' type='Circle' /> the year is usually February.</div>
        </div>
        <div style='display:flex; '>
          <div><b>5 &emsp;</b></div>
          <div>The Dorchester is one of the <input id='4' width='200px'/>(expensive) hotels <input id='4' type='Circle' /> London.</div>
        </div>
      
        `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P35-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page35/E3/Key/answerKey.png',
		textAlign: 'left',
		inputSize: 100,
		titleQuestion: [
			{
				num: '3',
				title: `
        Rewrite the sentences using <i>too</i> or <i>enough</i> and the adjective in brackets. Do not change the meaning.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>This game isn't easy enough for me. (difficult)</div>
						<div><i style='color: rgb(1, 142, 211);'>This game is too difficult for me.</i></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>We're too young to go on holiday alone. (old)</div>
						<div><input id='0' width='660px'></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>This bed is too hard. (soft) </div>
						<div><input id='1' width='660px'></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>This film isn't interesting enough. (boring) </div>
						<div><input id='2' width='660px'></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>5</b>
					<div>
						<div>It isn't warm enough to go to the beach. (cold) </div>
						<div><input id='3' width='660px'></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>6</b>
					<div>
						<div>His family were too poor to go on holiday. (rich)</div>
						<div><input id='4' width='660px'></div>
					</div>
				</div>
        `,
				answer: [
					"We're not old enough to go on holiday alone.",
					"This bed isn't soft enough.",
					'This film is too boring.',
					"It's too cold to go to the beach.",
					"His family weren't rich enough to go on holiday.",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P35-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page35/E4/Key/answerKey.png',
		textAlign: 'center',
		maxLength: 20,
		inputSize: 210,
		titleQuestion: [
			{
				num: '4',
				title: `
        Complete the dialogue. Use <i>too</i> or <i>enough</i> with the adjective in brackets or the superlative form.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style='display:flex; gap:20px;'>
					<div><b>Kate</b></div>
					<div>Now we join Brett Simpson for the <b><sup>1</sup>#</b>(late) news about the wildfires. Brett, what's happening?</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Brett</b></div>
					<div>Well, the police aren't allowing us to get <b><sup>2</sup>#</b>(close) to the fires, but I can feel the heat from here.</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Kate</b></div>
					<div>How are the people in the area reacting? It isn't one of the <b><sup>3</sup>#</b>(rich) parts of the country, is it?</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Brett</b></div>
					<div>No, it isn't. Some people are  <b><sup>4</sup>#</b> (lucky) to have relatives in other cities, so they're 
leaving. But many are 
          <b><sup>5</sup>#</b>(old) or  
          <b><sup>6</sup>#</b>(ill) to travel. They're waiting and 
hoping.
				</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Kate</b></div>
					<div> What's the<b><sup>7</sup>#</b>(good) thing other 
people can do to help?</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Brett</b></div>
					<div>Well, I asked the police that question. They said the <b><sup>8</sup>#</b>(helpful) thing is to stay away 
from the area. Thousands of people are coming 
here just to have a look. This is one of the 
          <b><sup>9</sup>#</b>(big) and 
          <b><sup>10</sup>#</b>(spectacular) fires for decades. But these people 
are getting in the way of the emergency services 
and their work. And their job is 
<b><sup>11</sup>#</b>(difficult) without that!
				</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Kate</b></div>
					<div>What is the  <b><sup>12</sup>#</b>(likely) cause of the 
fire?</div>
				</div>
				<div style='display:flex; gap:20px;'>
					<div><b>Brett</b></div>
					<div>It's <b><sup>13</sup>#</b> (early) to say. Sometimes 
lightning can start a fire like this, if the trees are 
          <b><sup>14</sup>#</b>(dry). But of course, humans are 
the 
          <b><sup>15</sup>#</b>(probable) cause.
          </div>
				</div>
        `,
				answer: [
					'latest',
					'too close',
					'richest',
					'lucky enough',
					'too old',
					'too ill',
					'best',
					'most helpful',
					'biggest',
					'most spectacular',
					'difficult enough',
					'most likely',
					'too early',
					'too dry',
					'most probable',
				],
			},
		],
	},
}

export default json
