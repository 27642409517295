import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P43-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the sentences with who, which, where, or whose.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'center',
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page43/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="margin-bottom: 15px">
            <strong>1</strong>
            <span style="margin-left: 20px">That's the hospital</span>
            <strong><input width="150px"/></strong>
            <span>my dad works.</span>
          </div>
          <div style="margin-bottom: 15px">
            <strong>2</strong>
            <span style="margin-left: 20px">He's someone</span>
            <strong><input width="150px"/></strong>
            <span>face is familiar, but I can't remember his name</span>
          </div>
          <div style="margin-bottom: 15px">
            <strong>3</strong>
            <span style="margin-left: 20px">Do you know a shop</span>
            <strong><input width="150px"/></strong>
            <span>I can buy printer paper?</span>
          </div>
          <div style="margin-bottom: 15px">
            <strong>4</strong>
            <span style="margin-left: 20px">The essay</span>
            <strong><input width="150px"/></strong>
            <span>Tom wrote got top marks.</span>
          </div>
        
        `,
        answer: ['where', 'whose', 'where', 'which'],
      },
    ],
  },
  2: {
    unit: 'Unit 5',
    id: 'WB10-U5-P43-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Join the two sentences with a relative pronoun (who, which, where, or whose)',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      textAlign: 'left',
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page43/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div>
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">I've lost the DVD. You gave it to me.</span>
            </div>
            <div style="width: 500px; border-bottom: 2px solid rgb(0 0 0 ); position: relative; height: 30px">
              <span style="font-style: oblique; font-size: 23px; color: rgb(0 68 98); position: absolute; left:20px; bottom: -10px">I've lost the DVD which you gave me</span>
            </div>
          </div>
          <div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">Who is the boy? He helped you with your homework.</span>
            </div>
            <div>
              <input width="500px" />
            </div>
          </div>
          <div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">Do you know that man? He's looking at us.</span>
            </div>
            <div>
              <input width="500px" />
            </div>
          </div>
          <div>
            <div>
              <strong>4</strong>
              <span style="margin-left: 20px">I opened the cupboard. We keep the glasses there</span>
            </div>
            <div>
              <input width="500px" />
            </div>
          </div>
          <div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">That's the girl. I saw you with her.</span>
            </div>
            <div>
              <input width="500px" />
            </div>
          </div>
          <div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">That's the boy. I borrowed his bicycle.</span>
            </div>
            <div>
              <input width="500px" />
            </div>
          </div>
          
        
        `,
        answer: [
          'Who is the boy who helped you with your homework?',
          `Do you know that man who's looking at us ?`,
          'I opened the cupboard where we keep the glasses.',
          `That's the girl(who) I saw you with.`,
          `That's the boy whose bicycle I borrowed.`,
        ],
  },
    ],
  },
  3: {
    unit: 'Unit 5',
      id: 'WB10-U5-P43-E3',
        audio: '',
          video: '',
            component: T6,
              titleQuestion: [
                {
                  num: '3',
                  title: 'Complete questions 1–5 with clauses a–e. Add a relative pronoun only where necessary.',
                  color: '#000000',
                },
              ],
                stylesTextInput: {
      textAlign: 'left',
        fontSize: 20,
          borderBottom: '2px solid',
            backgroundColor: 'transparent',
      },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKey.png',
      isHiddenCheck: true,
        questions: [
          // Row
          {
            title: /*html */ `
            <div>
              <div>
                <strong>1</strong>
                <span style="margin-left: 20px">Is this the phone</span>
              </div>
              <div style="width: 500px; border-bottom: 2px solid rgb(0 0 0 ); position: relative; height: 30px">
                <span style="font-style: oblique; font-size: 23px; color: rgb(0 68 98); position: absolute; left:20px; bottom: -10px">you used to text me?</span>
              </div>
            </div>
            <div>
              <div>
                <strong>2</strong>
                <span style="margin-left: 20px">What's the name of the hotel </span>
              </div>
              <div>
                <input width="500px" />
              </div>
            </div>
            <div>
              <div>
                <strong>3</strong>
                <span style="margin-left: 20px">Is Jake the boy</span>
              </div>
              <div>
                <input width="500px" />
              </div>
            </div>
            <div>
              <div>
                <strong>4</strong>
                <span style="margin-left: 20px">This is the jacket </span>
              </div>
              <div>
                <input width="500px" />
              </div>
            </div>
            <div>
              <div>
                <strong>5</strong>
                <span style="margin-left: 20px">Do you like people </span>
              </div>
              <div>
                <input width="500px" />
              </div>
            </div>

            <div style="margin-top: 50px">
              <div style="display: flex; gap: 20px">
                <strong>a</strong>
                <span style="text-decoration-line: line-through">you used to text me?</span>
              </div>
              <div style="display: flex; gap: 20px">
                <strong>b</strong>
                <span>we stayed last summer? </span>
              </div>
              <div style="display: flex; gap: 20px">
                <strong>c</strong>
                <span>you introduced me to a few days ago?</span>
              </div>
              <div style="display: flex; gap: 20px">
                <strong>d</strong>
                <span>I wore to Beth's party.</span>
              </div>
              <div style="display: flex; gap: 20px">
                <strong>e</strong>
                <span>show lots of initiative?</span>
              </div>
            </div>
          
            
          
          `,
            answer: [
              'where we stayed last summer?',
              'you introduced me to a few days ago?',
              "I wore to Beth's party.",
              'who show lots of initiative?',
            ],
          },
        ],
  },
  4: {
    unit: 'Unit 5',
      id: 'WB10-U5-P43-E4',
        audio: '',
          video: '',
            component: T6,
              titleQuestion: [
                {
                  num: '4',
                  title: 'Complete the sentences with the relative pronouns.',
                  color: '#000000',
                },
              ],
                stylesTextInput: {
      textAlign: 'center',
        fontSize: 20,
          borderBottom: '2px solid',
            backgroundColor: 'transparent',
      },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page43/E4/Key/answerKey.png',
      isHiddenCheck: true,
        questions: [
          // Row
          {
            title: /*html */ `
            <div>
              <div>
                <strong>1</strong>
                <span style="margin-left: 20px">The company's US offices are in Seattle, </span>
                <input width="150px"/>
                <span>is near the border with Canada.</span>
              </div>
              <div>
                <strong>2</strong>
                <span style="margin-left: 20px">I've applied for a job at the BBC, </span>
                <input width="150px"/>
                <span>my mum used to work.</span>
              </div>
              <div>
                <strong>3</strong>
                <span style="margin-left: 20px">t the job interview, she met Jack White, </span>
                <input width="150px"/>
                <span>father started the company.</span>
              </div>
              <div>
                <strong>4</strong>
                <span style="margin-left: 20px">The company has three hundred employees, </span>
                <input width="150px"/>
                <span>work in three different offices.</span>
              </div>
            </div>
          `,
            answer: ['which', 'where', 'whose', 'who'],
          },
        ],
  },
  5: {
    unit: 'Unit 5',
      id: 'WB10-U5-P43-E5',
        audio: '',
          video: '',
            component: T6,
              titleQuestion: [
                {
                  num: '5',
                  title: 'Complete the text with the relative clauses (a–f).',
                  color: '#000000',
                },
              ],
                stylesTextInput: {
      textAlign: 'center',
        fontSize: 20,
          width: 50,
            borderBottom: 'none',
              backgroundColor: 'transparent',
      },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page43/E5/Key/answerKey.png',
      isHiddenCheck: true,
        questions: [
          // Row
          {
            title: /*html */ `
            <div style="width: 1000px; display: flex;align-items: stretch; column-gap: 40px ">
              <div style=" position: relative;">
                <img style="height: 700px; width: 600px; object-fit: contain;" src="img/FriendsPlus/Page43/E5/1.png" />
                <div style="position: absolute; top: calc(50% + 4px + 22px); left: calc(50% + 20px)">#</div>
                <div style="position: absolute; top: calc(50% + 4px + 72px); left: calc(50% - 20px)">#</div>
                <div style="position: absolute; top: calc(50% + 4px + 96px); left: calc(50% + 85px)">#</div>
                <div style="position: absolute; top: calc(50% + 4px + 144px); left: calc(50% - 50px)">#</div>
                <div style="position: absolute; top: calc(50% + 4px + 192Px); left: calc(50% + 110px)">#</div>
                <div style="position: absolute; top: calc(90% + 12px ); left: calc(50% + 60px)">#</div>
              </div>
              <div>
                <div>
                  <strong style="font-size: 20px">a</strong>
                  <span style="margin-left: 15px; font-size: 20px">which are two qualities that most other companies value highly</span>
                </div>
                <div>
                  <strong style="font-size: 20px">b</strong>
                  <span style="margin-left: 15px; font-size: 20px">where the company has its main headquarters</span>
                </div>
                <div>
                  <strong style="font-size: 20px">c</strong>
                  <span style="margin-left: 15px; font-size: 20px">which receives around 2.5 million CVs every year</span>
                </div>
                <div>
                  <strong style="font-size: 20px">d</strong>
                  <span style="margin-left: 15px; font-size: 20px">who enjoy free leisure facilities (gyms, swimming pools, video games, etc.) and free meals</span>
                </div>
                <div>
                  <strong style="font-size: 20px">e</strong>
                  <span style="margin-left: 15px; font-size: 20px">which makes them unsuitable for a company like this</span>
                </div>
                <div>
                  <strong style="font-size: 20px">f</strong>
                  <span style="margin-left: 15px; font-size: 20px">whose job title is ‘senior vice-president for people'</span>
                </div>
              </div>
            </div>
          `,
            answer: ['d', 'b', 'f', 'c', 'a', 'e'],
          },
        ],
  },
}

export default json
