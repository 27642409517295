import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P60-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page60/E1/Key/answerKey.png",
    textAlign: "center",
    inputSize: 100,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
                Complete the text with the words below.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
		       	<div style='color: rgb(201, 64, 142);'><b>centre&emsp;checks&emsp;class&emsp;crew&emsp;lounge&emsp;pass&emsp;season</b></div>

            <div style=" width: 1350px; background-image: url('img/FriendsPlus/Page60/E1/1.jpg');  background-repeat: no-repeat;background-size: 1350px 720px;">
                <div style='padding:180px 600px 40px 35px;'>
                    <div>At Manchester Airport, an eleven-year-old boy has managed to walk 
through all the security 
                        <b><sup>1</sup>#</b> and fly to Rome. He did not 
have a passport or a boarding 
                        <b><sup>2</sup>#</b> .
                    </div>
                    <div>According to news reports, Liam Corcoran was with his mother in a 
nearby shopping 
                        <b><sup>3</sup>#</b> when he decided to go off on his 
own. He went to Manchester Airport, where he succeeded in reaching 
the departure 
                        <b><sup>4</sup>#</b> without having a ticket or any other 
kind of document. Because it was high 
                        <b><sup>5</sup>#</b> , the airport 
was extremely crowded and nobody noticed him. He boarded a 
Jet2 flight to Rome and the plane took off. During the flight, a few 
other passengers in economy 
                        <b><sup>6</sup>#</b> became suspicious 
about the boy and told the cabin 
                        <b><sup>7</sup>#</b> . The captain then 
radioed the airport and confirmed that they had an extra passenger!
                    </div>
                </div>
            </div>
        `,
        answer: [
          "checks",
          "pass",
          "centre",
          "lounge",
          "season",
          "class",
          "crew",
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB10-U7-P60-E2",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page60/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `Match the two halves of the compounds.
			  `,
        color: "#000",
      },
    ],
    stylesTextInput: { borderBottom: "10px dashed", textAlign: "center" },
    inputSize: 50,
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "135px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "251px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "291px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "370px",
              left: "170px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },

          {
            boxMatchStyle: {
              position: "absolute",
              top: "17px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "58px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "135px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "251px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "291px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "330px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
          {
            boxMatchStyle: {
              position: "absolute",
              top: "370px",
              left: "430px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #dc2c39",
              background: "white",
            },
          },
        ],
        answers: [
          "0-14",
          "1-13",
          "16-2",
          "17-3",
          "12-4",
          "15-5",
          "10-6",
          "18-7",
          "19-8",
          "11-9",
        ],
        initialValue: ["0-14"],
      },
      Layout: `
          <div style='display: flex; justify-content: space-between; width:600px; font-size: 26px;'>
            <div >
                <div><b>&ensp;1 &ensp;</b>flight</div>
                <div><b>&ensp;2 &ensp;</b>low</div>
                <div><b>&ensp;3 &ensp;</b>passport</div>
                <div><b>&ensp;4 &ensp;</b>first</div> 
                <div><b>&ensp;5 &ensp;</b>flight</div>
                <div><b>&ensp;6 &ensp;</b>online</div>
                <div><b>&ensp;7 &ensp;</b>seat</div>
                <div><b>&ensp;8 &ensp;</b>hand</div>
                <div><b>&ensp;9 &ensp;</b>baggage</div>
                <div><b>10 &ensp;</b>single</div>
            </div>    
            <div >
                <div><b>a &ensp;</b>belt</div>
                <div><b>b &ensp;</b>room</div>
                <div><b>c &ensp;</b>attendant</div>
                <div><b>d &ensp;</b>season</div> 
                <div><b>e &ensp;</b>number</div>
                <div><b>f &ensp;</b>check-in</div>
                <div><b>g &ensp;</b>control</div>
                <div><b>h &ensp;</b>class</div>
                <div><b>i &ensp;</b>luggage</div>
                <div><b>j &ensp;</b>carousel</div>
            </div>    
          </div>    
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          <input id='12' type= 'boxMatch' />
          <input id='13' type= 'boxMatch' />
          <input id='14' type= 'boxMatch' />
          <input id='15' type= 'boxMatch' />
          <input id='16' type= 'boxMatch' />
          <input id='17' type= 'boxMatch' />
          <input id='18' type= 'boxMatch' />
          <input id='19' type= 'boxMatch' />
      `,
    },
  },

  3: {
    unit: "Unit 7",
    id: "WB10-U7-P60-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page60/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with the compounds in exercise 2.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 220,
    textAlign: "center",
    maxLength: 15,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <b>&ensp;1</b>
          <div>As his four-year-old son sat down on the # , it started to move.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;2</b>
          <div>Our # is EZY 8512.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;3</b>
          <div>He doesn't always sit in #, even though he owns the airline.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;4</b>
          <div>I asked the # for a pair of headphones.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;5</b>
          <div>You can keep your # under the seat in front of you.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;6</b>
          <div>When several flights land at the same time, there are long queues at #.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;7</b>
          <div>When several flights land at the same time, there are long queues at #.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;8</b>
          <div>Using # can save you time at the airport.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>&ensp;9</b>
          <div>Hotels are a lot cheaper during #.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>10</b>
          <div>You must wear your # when the plane is taking off and landing.</div>
        </div>
			  `,
        answer: [
          "baggage carousel",
          "flight number",
          "first class",
          "flight attendant",
          "hand luggage",
          "passport control",
          "single room",
          "online check-in",
          "low season",
          "seat belt",
        ],
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 7",
    id: "SB10-U7-P60-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "/img/FriendsPlus/Page60/E4/key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: ` Read the <span style='color:rgb(156, 37, 138);'>Vocab boost!</span> box. 
			  `,
        color: "#92278f",
      },
    ],
    selectStyle: {
      color: "#00ADFE",
      width: 120,
      textAlign: "center",
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    selectOptionValues: ["One", "Two", "Three", "Four", "Five"],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
					  <div style=" width: 660px; background-image: url('/img/FriendsPlus/Page60/E4/1.jpg');  background-repeat: no-repeat;background-size: 660px 230px; margin-left: 150px;">
              <div style='padding:25px 15px 25px 55px;'>
                    <div><b style='color:rgb(156, 37, 138);'>VOCAB BOOST!</b></div>
                    <div>When you record new nouns, look for compounds 
which include those nouns. You can sometimes find 
these compounds under the same dictionary entry, but 
sometimes they have their own entries.</div>
                </div>
            </div>


              <div style='display: flex; gap: 15px;'><b> 1 </b><p>How many compounds are there in total?</p> <select id="0" ></select></div>
              <div style='display: flex; gap: 15px;'><b> 2 </b><p>How many separate entries are there? </p> <select id="1" ></select></div>

              
					  <div style=" width: 730px; background-image: url('/img/FriendsPlus/Page60/E4/2.jpg');  background-repeat: no-repeat;background-size: 730px 280px; margin-left: 100px;">
              <div style='padding:35px 65px 25px 45px;'>
              <b><b style='color:rgb(36, 131, 197);'>baggage</b></b> /ˈbæɡɪdʒ/ <i>noun</i> [U] bags, suitcases, etc. used 
for carrying sb's clothes and things on a journey: <b>excess baggage</b> 
 (= baggage weighing more than the airline's 
allowed limit) ◆ <i>I went to wait for my suitcase at 
baggage reclaim</i>. SYN <b>luggage
baggage room (US)</b> = left-luggage office
            </div>
			  `,
        answer: ["Four", "Two"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "WB10-U7-P60-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    textareaStyle: { width: 700 },
    titleQuestion: [
      {
        num: "5",
        title: `
        Use a dictionary to find compounds that include these words.
      `,
      },
    ],

    questions: [
      {
        title: `
        
      <div style="display: flex;margin-top:0px">
        <div><b>1 &ensp;</b></div>
        <div>bus:<textarea rows="2"></textarea></div>
      </div>
      <div style="display: flex;margin-top:0px">
        <div><b>2 &ensp;</b></div>
        <div>tourist:<textarea rows="2"></textarea></div>
      </div>
      
      `,
        answer: [],
      },
    ],
  },
};

export default json;
