import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
const json = {
	1: {
		unit: 'Unit 1',
		id: 'WB10-U1-P4-E1',
		audio: '',
		video: '',
		component: T6,
		questionImage: [],
		stylesTextInput: { fontSize: 20, backgroundColor: 'transparent' },
		inputSize: 170,
		maxLength: 18,
		exerciseKey: 'img/FriendsPlus/Page4/E1/Key/answerKey.png',
		checkUppercase: true,
		textAlign: 'center',
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title:
					'Look at the pictures and complete the chart with the phrases below. Then complete sentences 1–5 with the information from the chart.',
			},
		],
		questions: [
			{
				title: `
        <div style="color: rgb(144, 38, 143); display: flex; font-weight: bold; gap: 30px">
          <span>I love</span>
          <span>I don't like</span>
          <span>I don't mind</span>
          <span>I like</span>
          <span>I can't stand</span>
          <span>I hate</span>
          <span>I'm really keen on</span>
        </div>
        <div style="display: flex; font-size: 20px">
          <div style="position: relative; margin: 10px">
            <div><img src='img/FriendsPlus/Page4/E1/1.jpg' /></div>
            <div>
              <div style=" position: absolute; top: 35px; left: -83px; "><input id='0' /></div>
              <div style=" position: absolute; top: 79px; left: -93px; "><input id='1' width='200px'/></div>
              <div style=" position: absolute; top: 210px; left: -83px; "><input id='2' /></div>
              <div style=" position: absolute; top: 342px; left: -83px; "><input id='3' /></div>
              <div style=" position: absolute; top: 473px; left: -83px; "><input id='4' /></div>
              <div style=" position: absolute; top: 605px; left: -83px; "><input id='5' /></div>
              <div style=" position: absolute; top: 648px; left: -83px; "><input id='6' /></div>
            </div>
          </div>
          <div>
            <div><b>1 &emsp;</b> I can't stand  #.</div>
            <div><b>2 &emsp;</b> I'm really keen on  #.</div>
            <div><b>3 &emsp;</b> # chess.</div>
            <div><b>4 &emsp;</b> # football.</div>
            <div><b>5 &emsp;</b> # video games.</div>
          </div>
        </div>
        `,
				answer: [
					"I love / I'm really keen on",
					"I love / I'm really keen on",
					'I like',
					"I don't mind",
					"I don't like",
					"I can't stand / I hate",
					"I can't stand / I hate",
					'swimming',
					'dancing',
					" I don't mind",
					' I like ',
					" I don't like",
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P4-E2',
		audio: '',
		video: '',
		component: T6,
		// maxLength: 1,
		isHiddenCheck: true,
		textAlign: 'center',
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: 'dotted 1px',
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page4/E2/Key/answerKey.png',
		inputSize: 130,
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the dialogue with the phrases below. More than one answer may be possible.',
			},
		],
		questionImage: [],
		questions: [
			{
				title: `
        <div style="color: rgb(144, 38, 143); display: flex; font-weight: bold; gap: 30px">
          <span>can't stand</span>
          <span>hate</span>
          <span>don't mind </span>
          <span>quite like </span>
          <span>really keen </span>
          <span>love </span>
          <span>terrible </span>
          <span>isn't bad </span>
          <span>is great</span>
        </div>
        <div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Kim</b></div> <div style="flex: 9">So, are you excited about the start of school?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Dave</b></div> <div style="flex: 9">No, I'm not really excited. I <sup>1</sup> # school, but it's not my favourite thing in the world.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Kim</b></div> <div style="flex: 9">We're very different. I <sup>2</sup> # school! I really enjoy learning new things. What subjects do you like?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Dave</b></div> <div style="flex: 9">I <sup>3</sup> # IT, because I'm interested in computers. And there's one subject I really enjoy – drama. I'm <sup>4</sup> # on drama. I think acting <sup>5</sup> #  .</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Kim</b></div> <div style="flex: 9">Really? We're very different that way too. I <sup>6</sup> # drama. I just get too scared in front of people. What do you think of maths?</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Dave</b></div> <div style="flex: 9">I really don't like it. Actually, I <sup>7</sup> # maths! I just can't understand it. It's <sup>8</sup> #  !</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Kim</b></div> <div style="flex: 9">Different again! I like maths, and I enjoy science a lot too.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Dave</b></div> <div style="flex: 9">OK, science <sup>9</sup> #  . It's actually kind of interesting.</div></div>
          <div style="display: flex;"><div style="display: inline-block;flex: 1"><b>Kim</b></div> <div style="flex: 9">Well, I'm happy we agree on something …</div></div>
        </div>
        `,
				answer: [
					"don't mind ",
					'love ',
					"don't mind / quite like",
					'really keen ',
					'is great ',
					"can't stand / hate",
					"can't stand / hate ",
					'terrible ',
					"isn't bad",
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P4-E3',
		audio: '',
		video: '',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page4/E3/Key/answerKey.png',
		inputSize: 150,
		maxLength: 11,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: 'Read definitions 1–9 and match them with the activities below.',
			},
		],
		questions: [
			{
				title: `
              <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>cycling</div>
                <div>volleyball</div>
                <div>chess</div>
                <div>ice hockey</div>
                <div>board games</div>
                <div>basketball</div>
                <div>swimming</div>
                <div>drawing</div>
                <div>video games</div>
              </div>
              <div><img src='img/FriendsPlus/Page4/E3/1.jpg' style="border-radius: 35px; max-width: 85%;" /></div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; "> a game where you bounce a ball and try to throw it through a hoop #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; "> making pictures with a pencil or pen  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; "> exercising in water #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; "> a game with 32 pieces on a board, including a king and queen #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; "> games with pieces that you play on a table #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> games you play on a computer or TV screen  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; "> riding a bike  #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; "> a game you play on ice with a long stick   #</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>9. </b></div><div style="margin-left: 30px; flex: 1; "> a game where you hit a ball with your hands over a high net  #</div></div>
              </div>
        `,
				answer: [
					'basketball',
					'drawing',
					'swimming',
					'chess',
					'board games',
					'video games',
					'cycling',
					'ice hockey',
					'volleyball',
				],
			},
		],
	},
};

export default json;
