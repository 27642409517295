import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "WB10-U2-P17-E1",
    exerciseKey: "/img/FriendsPlus/Page17/E1/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    inputSize: 180,
    maxLength: 16,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: "center",
      fontSize: 23,
    },
    textareaStyle: {},
    titleQuestion: [
      {
        num: "1",
        title: `
      
      <b>
        Complete the sentences with the past continuous form of
        the verbs in brackets.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div style="line-height: 50px;">
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
              <div>Yesterday at 6 p.m. we # (have) dinner</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
              <div>Harry # (not listen) so he didn't know how to do the exercise</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
              <div>Kate # (lie) on the beach and Anna # (eat) an ice cream.</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
              <div>Sam and Ryan # (smile) and Nancy # (take) a photo of them.</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
              <div>Why # you and Zoe # (argue)?</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
              <div>Hannah was annoyed because a man on the train # (talk) loudly on his mobile.</div>
            </div>
          </div>
        `,
        answer: [
          "were having",
          `wasn't listening`,
          "was lying",
          "was eating",
          "were smiling",
          "was taking",
          "were",
          "arguing",
          "was talking",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P17-E2",
    exerciseKey: "/img/FriendsPlus/Page17/E2/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 320,
    titleQuestion: [
      {
        num: "2",
        title: `
      <b>
      Write sentences. Use the past continuous.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
            <div>
              Jake / ride / his bike <br />
              <span style="color: rgb(0,110,147)";>Jake was riding his bike.</span>
            </div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
            <div>the sun / shine ? <br />#</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
            <div>it / not rain <br />#</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
            <div>where / he / go ? <br />#</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
            <div>he / not wear / a helmet <br />#</div>
          </div>	
        `,
        answer: [
          "Was the sun shining?",
          `It wasn't raining.`,
          "Where was he going?",
          `He wasn't wearing a helmet.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "WB10-U2-P17-E3",
    exerciseKey: "/img/FriendsPlus/Page17/E3/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 190,
    maxLength: 14,
    stylesTextInput: {
      textAlign: "center",
      backgroundColor: "transparent",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
      <b>
        Complete the text with the past continuous form of
        the verbs below.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(245,143,32); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>carry</span>
            <span>follow</span>
            <span>hurry</span>
            <span>leave</span>
            <span>rain</span>
            <span>stand</span>
            <span>wear</span>
            <span>not wear</span>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 20px;">
            <div style="flex: 3; padding: 20px; border-radius: 20px; background-color: rgb(254, 242, 226); box-shadow: 3px 3px 5px rgba(0,0,0,0.4);">
              When I got off the coach in London, the weather was awful. 
              It <sup>1</sup># and there was a cold wind. 
              A short, fat man, who <sup>2</sup># a hat and <sup>3</sup># a small briefcase, got off after me. 
              It was five o’clock and lots of people <sup>4</sup># work and <sup>5</sup># to catch the bus or the Underground. 
              I <sup>6</sup># a raincoat so I went into a department store and bought a cheap umbrella. 
              When I came out, the short, fat man from the bus <sup>7</sup># outside the department store. <sup>8</sup># he # me?
            </div>

            <div style="flex: 2;">
              <img src="img/FriendsPlus/Page17/E3/1.jpg" style="max-width: 100%; border-radius: 20px; box-shadow: 3px 3px 5px rgba(0,0,0,0.4);" />
            </div>
          </div>
        `,
        answer: [
          "was raining",
          "was wearing",
          "carrying",
          "were leaving",
          "hurrying",
          `wasn't wearing`,
          "was standing",
          "Was",
          "following",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "WB10-U2-P17-E4",
    exerciseKey: "/img/FriendsPlus/Page17/E4/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 290,
    maxLength: 25,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `
      <b>
        What was happening at the park yesterday afternoon? Complete
        the sentences, using the verbs and phrases below.
        Use the past continuous.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div style="font-weight: bold; color: rgb(245,143,32); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span>eat an ice cream</span>
            <span>drink fruit juice</span>
            <span>have a picnic</span>
            <span>listen to music</span>
            <span>phone someone</span>
            <span>read a magazine</span>
            <span>sunbathe</span>
            <span style="text-decoration: line-through;">text</span>
          </div>

          <div style="margin-top: 20px; display: flex; gap: 20px;">
            <div style="display: flex; flex-direction: column; justify-content: center; flex: 3; padding: 10px; border-radius: 5px; border: 1px solid rgb(245,143,32);">
              <div style="display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
                <div>Tom <span style="color: rgb(0,110,147);">was texting.</span></div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
                <div>Will and Emma #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
                <div>Kim #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
                <div>Max and Eve #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
                <div>George and Susan #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
                <div>Jay #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">7</div>
                <div>Leyla #</div>
              </div>
              <div style="margin-top: 7px; display: flex; gap: 20px;">
                <div style="width: 40px; font-weight: bold; text-align: right;">8</div>
                <div>Zoe #</div>
              </div>
            </div>

            <div style="flex: 2;">
              <img src="img/FriendsPlus/Page17/E4/1.png" style="max-width: 100%;" />
            </div>
          </div>
        `,
        answer: [
          "were having a picnic.",
          "was reading a magazine.",
          "were sunbathing.",
          "were listening to music.",
          "was drinking fruit juice.",
          "was eating an ice cream.",
          "was phoning someone.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "WB10-U2-P17-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    inputSize: 480,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
      <b>
      Write questions with you in the past continuous.
      Then write true answers.
      </b>
      `,
      },
    ],
    questions: [
      {
        title: `
          <div style="width: 500px; font-weight: bold; color: rgb(245,143,32); display: flex; gap: 10px 30px; flex-wrap: wrap;">
            <span style="text-decoration: line-through;">10.30 p.m. yesterday</span>           
            <span>on this day last year</span>           
            <span>3 a.m. this morning</span>           
            <span>7.30 p.m. yesterday</span>           
            <span>8.30 a.m. this morning</span>           
          </div>

          <div style="margin-top: 20px; margin-left: -28px;">
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
              <div>
                <span style="color: rgb(0,110,147);">What were you doing at 10.30 p.m. yesterday ?</span><br />
                <span style="color: rgb(0,110,147);">I was </span> <input id='0' width='415px' />
              </div>
            </div>
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
              <div># ? <br /> #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
              <div># ? <br /> #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
              <div># ? <br /> #</div>
            </div>	
            <div style="margin-top: 7px; display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
              <div># ? <br /> #</div>
            </div>	
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
