import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E1/Key/answerKey.png',
    component: T6,
    inputSize: 200,
    // isHiddenCheck: true,
    maxLength: 15,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '',
        title: `
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div style="background-image: url('img/FriendsPlus/Page70/E1/1.jpg'); background-repeat: no-repeat; background-size: 95%; padding: 30px 100px 20px 110px; height: 1200px" >
              <span><strong style="color: rgb(0, 146, 66)">Revision:</strong> <strong>Student Book page 102</strong></span>
              <div style="margin-top: 20px">
                <h1 style="font-size: 25px; color: black"><span style="margin-right: 10px">1</span>Label the gadgets with the words below.</h1>
              </div>
              <div style="color: rgb(0, 147, 69); font-weight: bold; margin-bottom: 30px;">
                <span style= "margin-right: 50px">digital camera</span>
                <span style= "margin-right: 50px">digital radio</span>
                <span style= "margin-right: 50px">DVD player</span>
                <span style= "margin-right: 50px">e-book reader</span>
                <span style= "margin-right: 50px">games console</span>
                <span style= "margin-right: 50px">headphones</span>
                <span style= "margin-right: 50px">mobile</span>
                <span style= "margin-right: 50px">MP3 player</span>
                <span style= "margin-right: 50px">tablet</span>
              </div>
              <div style="margin-top: 30px; display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); grid-gap: 20px; grid-auto-flow: row;">
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/2.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>1</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/3.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>2</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/4.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>3</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/5.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>4</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/6.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>5</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/7.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>6</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/8.jpg" alt="image" />
                  </div>
                  <div>
                    <span><strong>7</strong>#</span>
                  </div>
                </div>
                <div>
                  <div>
                    <img src="img/FriendsPlus/Page70/E1/9.jpg" alt="image" />
                  </div>
                  <div style="display: flex; gap: 0 60px; margin-top: 20px">
                    <div>
                      <span style="display: flex;"><strong>8</strong> #</span>
                    </div>
                    <div>
                    <span style="display: flex;"><strong>9</strong> #</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            `,
        answer: [
          'games console',
          'digital camera',
          'DVD player',
          'e-book reader',
          'mobile',
          'digital radio',
          'tablet',
          'MP3 player',
          'headphones',
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    component: T6,
    selectStyle: {
      color: 'black',
      width: 74,
      textAlign: 'center',
      border: '2px solid black',
    },
    selectOptionValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    titleQuestion: [
      {
        num: '2',
        title: `
        Match the parts of the gadgets (1–10) with the words
        below (a–j).
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div style = "display: flex; gap: 0 20px">
              <div style="position: relative;">
                <img src="img/FriendsPlus/Page70/E2/1.jpg" alt="image" width=800px />
                <strong>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; top: 30px; left:150px">1</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; top: 130px; right:230px">2</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; top: 110px; right:50px">3</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; bottom: 120px; left: 10px">4</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; bottom: 50px; left: 150px">5</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; bottom: 70px; right: 210px">6</span>
                </strong>
              </div>
              <div style="position: relative;">
                <img src="img/FriendsPlus/Page70/E2/2.jpg" alt="image" width=800px height=408px/>
                <strong>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; top: 30px; left: 100px">7</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; top: 50px; right: 220px">8</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; bottom: 170px; left: 190px">9</span>
                  <span style="background-color: white; padding: 0 10px; border: 1px solid; position: absolute; bottom: 110px; right: 30px">10</span>
                </strong>
              </div>
              
            </div>
            <div style="display: flex; justify-content: center; gap:150px; margin-top: 30px">
                <div>
                  <div style='display:flex; gap:15px;'>
                    <b>a</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>battery</div>
                        <select id='0'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>b</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>remote control </div>
                        <select id='1'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>c</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>case</div>
                        <select id='2'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>d</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>screen</div>
                        <select id='3'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>e</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>on/off button</div>
                        <select id='4'></select>
                    </div>
                  </div>
                </div>
                <div>
                  <div style='display:flex; gap:15px;'>
                    <b style='width: 20px;'>f</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>USB port </div>
                        <select id='5'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b style='width: 20px;'>g</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>power lead </div>
                        <select id='6'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b style='width: 20px;'>h</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>volume control</div>
                        <select id='7'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b style='width: 20px;'>i</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>strap </div>
                        <select id='8'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b style='width: 20px;'>j</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 250px;'>
                        <div>charger </div>
                        <select id='9'></select>
                    </div>
                  </div>
                </div>
            </div>
            `,
        answer: ['4', '5', '7', '9', '8', '6', '1', '2', '10', '3'],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E3',
    audio: 'Audios/2.10.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E3/Key/answerKey.png',
    component: T6,
    inputSize: 180,
    // isHiddenCheck: true,
    maxLength: 20,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
        <headphone name="2.10"></headphone> Listen to a customer complaining in a shop. Then
        answer the questions.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
            <div>
              <div>
                <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                  <li><span style="font-weight: normal;">Which gadget from exercise 1 is faulty?</br>#</span></li>
                  <li><span style="font-weight: normal;">Which three parts of the gadget are mentioned? (Choose
                    from the parts in exercise 2.)</br></span>
                    <ol type="1" style="font-weight: bold; display: flex;gap: 0 30px; font-size: 21px; margin-top: 20px">
                      <li style="width: 250px"><span style="font-weight: normal;">#</span></li>
                      <li style="width: 250px"><span style="font-weight: normal;">#</span></li>
                      <li style="width: 250px"><span style="font-weight: normal;">#</span></li>
                    </ol>  
                  </li>
                </ol>
              </div>
            </div>           
            `,
        answer: ['digital radio', 'volume control', 'power lead', 'remote control'],
      },
    ],
  },
  4: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E4',
    audio: 'Audios/2.10.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E4/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '4',
        title: `
        <headphone name="2.10"></headphone> Listen again and complete the sentences with the
        words below. Circle the correct speaker (C – customer or
        SA – shop assistant) for each sentence.
            `,
        color: '#000',
      },
    ],
    question: {
      Write: {
        inputStyle: {
          width: 170,
          textAlign: 'center',
        },
        answers: ['wrong', 'something', 'money', 'refunds', 'happy', 'credit', 'receipt'],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: '5px 0',
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '50%',
        },
        selectWordStyle: {
          border: 'solid 2px',
          borderColor: '#00a8ec',
          margin: '-2px',
          padding: 5,
        },
        limitSelect: 1,
        listWords: ['C / SA', 'C / SA', 'C / SA', 'C / SA', 'C / SA', 'C / SA', 'C / SA'],
        answers: ['2-0', '7-0', '8-0', '8-4', '0-4', '1-0', '3-4', '4-0', '5-4', '6-4'],
        initialValue: [],
      },
      Layout: `
              <div>
                <div style="color: rgb(20, 149, 75); font-weight: bold; margin-bottom: 30px;">
                  <span style= "margin-right: 50px">credit</span>
                  <span style= "margin-right: 50px">happy</span>
                  <span style= "margin-right: 50px">money</span>
                  <span style= "margin-right: 50px">receipt</span>
                  <span style= "margin-right: 50px">refunds</span>
                  <span style= "margin-right: 50px">something</span>
                  <span style= "margin-right: 50px">wrong</span>
                </div>
                <div>
                  <div>
                    <ol type="1" style="font-weight: bold; display: flex; flex-direction: column; gap: 20px 0;">
                      <li><span style="font-weight: normal;">What's <input type="text" id="0"> with it? &emsp;<b><input type="Circle" id="0"></b></span></li>
                      <li><span style="font-weight: normal;">There's <input type="text" id="1"> wrong with the volume control. &emsp;<b><input type="Circle" id="1"></b></span></li>
                      <li><span style="font-weight: normal;">Can I have my <input type="text" id="2"> back? &emsp;<b><input type="Circle" id="2"></b></span></li>
                      <li><span style="font-weight: normal;">We don't give <input type="text" id="3"> , I'm afraid. &emsp;<b><input type="Circle" id="3"></b></span></li>
                      <li><span style="font-weight: normal;">I'm not <input type="text" id="4"> about that. &emsp;<b><input type="Circle" id="4"></b></span></li>
                      <li><span style="font-weight: normal;">I can give you a <input type="text" id="5"> note. &emsp;<b><input type="Circle" id="5"></b></span></li>
                      <li><span style="font-weight: normal;">Have you got the <input type="text" id="6"> with you? &emsp;<b><input type="Circle" id="6"></b></span></li>
                    </ol>
                  </div>
                </div>
              </div>
      `,
    },
  },
  5: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E5',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E5/Key/answerKey.png',
    component: T6,
    inputSize: 700,
    // isHiddenCheck: true,
    maxLength: 0,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
        Read the <span style="color: rgb(1, 146, 69)">Speaking Strategy</span>.  Then read the speaking task
        and write answers to all the questions below.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 1100px; gap: 30px 0; margin-top: 20px">
                <div>
                  <div style="background-image: url('img/FriendsPlus/Page70/E5/1.jpg'); background-repeat: no-repeat; background-size: 720px auto; height: 270px;width:720px; padding: 20px 100px 30px 30px; border-radius: 16px">
                  <h2 style="font-size: 24px; color: rgb(1, 146, 69)">Speaking Strategy</h2>
                  <span style="font-size: 21px">When you are doing a speaking task, make sure
                  you refer to all of the points in the task. It is also
                  important that you react and respond properly to
                  what is said during the conversation.</span>
                  </div>
                  <div style="background-color: rgb(209, 222, 241); border-radius: 16px; width: 700px; height: auto; font-size: 21px; padding: 10px 20px">
                    <span><strong>You are returning a gadget to a shop because one part of it
                    is faulty. Discuss these four issues during your conversation
                    with the shop assistant:</strong></span>
                    <ul>
                      <li>What the gadget is and how much you paid for it.</li>
                      <li>Which part of the gadget has a problem.</li>
                      <li>When the problem started.</li>
                      <li>What you want the shop to do.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ol type="1" style="font-weight: bold">
                    <li><span style="font-weight: normal;">What type of gadget did you buy?</br> # </span></li>
                    <li><span style="font-weight: normal;">How much did you pay for it?</br> # </span></li>
                    <li><span style="font-weight: normal;">Which part of the gadget has stopped working?</br> #.</span></li>
                    <li><span style="font-weight: normal;">When did the problem start?</br> #</span></li>
                    <li><span style="font-weight: normal;">Do you want to exchange it or do you want a refund?</br> #</span></li>
                  </ol>
                </div>
              </div>
            `,
        answer: [],
      },
    ],
  },
  6: {
    unit: 'Unit 8',
    id: 'FG10-U8-P70-E6',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page70/E6/Key/answerKey.png',
    component: T6,
    inputSize: 700,
    // isHiddenCheck: true,
    maxLength: 0,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '6',
        title: `
        Now do the task using your notes from exercise 5.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
                <div>
                  <ol type="1" style="font-weight: bold">
                    <li><span style="font-weight: normal;">What type of gadget did you buy?</br> # </span></li>
                    <li><span style="font-weight: normal;">How much did you pay for it?</br> # </span></li>
                    <li><span style="font-weight: normal;">Which part of the gadget has stopped working?</br> #.</span></li>
                    <li><span style="font-weight: normal;">When did the problem start?</br> #</span></li>
                    <li><span style="font-weight: normal;">Do you want to exchange it or do you want a refund?</br> #</span></li>
                  </ol>
                </div>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
