import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: {
    unit: 'Unit 8',
    id: 'FG10-U8-P64-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E1/Key/answerKey.png',
    component: T6,
    inputSize: 35,
    isHiddenCheck: true,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
        Complete the list of materials using a, e, i, o and u.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div style="position: relative">
                <img src="img/FriendsPlus/Page64/E1/1.jpg" alt="image" width=1160px/>
                <span style="position: absolute; top: 350px; right: 200px; background-color: white; padding: 5px"><strong style="margin-right:10px">1</strong>w##d</span>
                <span style="position: absolute; bottom: 80px; left: 450px; background-color: white; padding: 5px"><strong style="margin-right:10px">2</strong>st##l</span>
                <span style="position: absolute; top: 200px; left: 350px; background-color: white; padding: 5px"><strong style="margin-right:10px">3</strong>gl#ss</span>
                <span style="position: absolute; top: 250px; right: 100px; background-color: white; padding: 5px"><strong style="margin-right:10px">4</strong>st#n#</span>
              </div>
              <div style="position: relative">
                <img src="img/FriendsPlus/Page64/E1/2.jpg" alt="image" width=1160px/>
                <span style="position: absolute; bottom: 80px; left: 70px; background-color: white; padding: 5px"><strong style="margin-right:10px">5</strong>al#m#n##m</span>
                <span style="position: absolute; top: 20px; left: 450px; background-color: white; padding: 5px"><strong style="margin-right:10px">6</strong>c#rdb##rd</span>
                <span style="position: absolute; bottom: 100px; right: 80px; background-color: white; padding: 5px"><strong style="margin-right:10px">7</strong>pl#st#c</span>
              </div>
            `,
        answer: ['o', 'o', 'e', 'e', 'a', 'o', 'e', 'u', 'i', 'i', 'u', 'a', 'o', 'a', 'a', 'i'],
      },
    ],
  },
  2: {
    unit: 'Unit 8',
    id: 'FG10-U8-P64-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
    component: T6,
    inputSize: 130,
    // isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
        Complete the sentences with the correct materials.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ol type="1" style="font-weight: bold">
                  <li><span style="font-weight: normal;">Shoes are often made of l#</span></li>
                  <li><span style="font-weight: normal;">Expensive rings are often made of g#</span></li>
                  <li><span style="font-weight: normal;">Car tyres are made of  r#</span></li>
                  <li><span style="font-weight: normal;">Cups and plates are often c# but sometimes they are made of c#</span></li>
                  <li><span style="font-weight: normal;">Books are made of p#</span></li>
                  <li><span style="font-weight: normal;">Pavements are often made of c#</span></li>
                  <li><span style="font-weight: normal;">Gates and fences are sometimes made of
                  i#</span></li>
                  <li><span style="font-weight: normal;">Tents and backpacks are usually made of
                  n#</span></li>
                  <li><span style="font-weight: normal;">In the past, coins were often made of c#</span></li>
                </ol>
              </div>
            `,
        answer: ['eather', 'old', 'ubber', 'eramic', 'ardboard', 'aper', 'oncrete', 'ron', 'ylon', 'opper'],
      },
    ],
  },
  3: {
    unit: 'Unit 8',
    id: 'FG10-U8-P64-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E3/Key/answerKey.png',
    component: T6,
    inputSize: 130,
    isHiddenCheck: true,
    maxLength: 10,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
        Answer the questions with the material words from
        exercises 1 and 2. Use each word only once.
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
        <div>
          <ol type="1" style="font-weight: bold">
            <li><span style="font-weight: normal;">Which of the materials come from trees?
            <i style='color:rgb(97, 156, 236);'>paper</i>###</span></li>
            <li><span style="font-weight: normal;">Which of the materials are metals?#####</span></li>
            <li><span style="font-weight: normal;">Which material is made from part of an animal?#</span></li>
            <li><span style="font-weight: normal;">Which very hard material do we find in the ground? #</span></li>
            <li><span style="font-weight: normal;">Which man-made materials do we make from oil? ##</span></li>
            <li><span style="font-weight: normal;">Which materials do we make from other materials which
            we find in the ground?###</span></li>
          </ol>
        </div>
            `,
        answer: [
          'wood',
          'rubber',
          'cardboard',
          'iron',
          'copper',
          'gold',
          'aluminium',
          'steel',
          'leather',
          'stone',
          'nylon',
          'plastic',
          'glass',
          'ceramic',
          'concrete',
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 8',
    id: 'FG10-U8-P64-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E4/Key/answerKey.png',
    component: T6,
    inputSize: 130,
    // isHiddenCheck: true,
    maxLength: 10,
    selectStyle: {
      color: 'black',
      fontWeight: 'bold',
      width: 200,
      textAlign: 'center',
      border: '2px solid black',
    },
    selectOptionValues: ['circular', 'curved', 'rectangular', 'spherical', 'square', 'straight', 'triangular'],
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '4',
        title: `
        Listen again and complete the sentences with the
        words below. Circle the correct speaker (C – customer or
        SA – shop assistant) for each sentence.
                    `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="font-weight: bold; color: rgb(169, 102, 170); margin-bottom: 20px">
                  <span style="margin-right: 20px">circular</span>
                  <span style="margin-right: 20px">curved</span>
                  <span style="margin-right: 20px">rectangular</span>
                  <span style="margin-right: 20px">spherical</span>
                  <span style="margin-right: 20px">square</span>
                  <span style="margin-right: 20px">straight</span>
                  <span style="margin-right: 20px">triangular
                  </span>
                </div>
                <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr)); grid-gap: 20px; margin-bottom: 20px">
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/1.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>1</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='0'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/2.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>2</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='1'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/3.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>3</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='2'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/4.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>4</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='3'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/5.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>5</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='4'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/6.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white;"><strong>6</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='5'></select></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E4/7.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white"><strong>7</strong></div>
                    <div style="position: absolute; top: 160px; left: 40px;"><select id='6'></select></div>
                  </div>
                </div>
              </div>
            `,
        answer: ['rectangular', 'square', 'spherical', 'circular', 'triangular', 'curved', 'straight'],
      },
    ],
  },
  5: {
    unit: 'Unit 8',
    id: 'FG10-U8-P64-E5',
    audio: 'Audios/2.07.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page64/E5/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    // isHiddenCheck: true,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
          <headphone name="2.07"></headphone> Listen to the sentences and match the objects to
        the descriptions. Write the correct letter: A, B or C. 
                    `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div style="display: flex; gap: 40px; margin-bottom: 20px">
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E5/1.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white"><strong>A</strong></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E5/2.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white"><strong>B</strong></div>
                  </div>
                  <div style="position: relative;">
                    <img src="img/FriendsPlus/Page64/E5/3.jpg" alt="image" />
                    <div style="height: 36px; width: 36px; position: absolute; top: 10px; left: 10px; border: 1px solid; text-align: center; background-color: white"><strong>C</strong></div>
                  </div>
                </div>
                <div>
                  <ol type="1" style="font-weight: bold; display: grid; grid-gap: 20px; grid-auto-flow: row; grid-template-columns: repeat(2, minmax(0, 1fr));">
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                    <li><span style="font-weight: normal;">#</span></li>
                  </ol>
                </div>
              </div>
            `,
        answer: ['A', 'B', 'A', 'C', 'A', 'C', 'B'],
      },
    ],
  },
};

export default json;
