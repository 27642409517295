import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: {
    unit: 'Unit 6',
    id: 'WB10-U6-P48-E1',
    audio: 'Audios/1.18.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '1',
        title: '<headphone name="1.18"></headphone> Listen and write the numbers. Use the correct symbols: £, €, $ and ¥.',
        color: '#000000',
      },
    ],
    selectStyle: {
			color: "black",
			fontWeight: "bold",
			width: 40,
			textAlign: "center",
			border: "2px solid black",
			borderRadius: "20%",
		},

		selectOptionValues: ["£", "€", "$", "¥"],
    stylesTextInput: {
      fontSize: 20,
      width: 150,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="display:flex; column-gap: 50px">
          <div>
            <div >
              <strong >a</strong>
              <span style="color: rgb(0 68 98) ; margin-left: 15px">€1,000,000</span>
            </div>
            <div style="display: flex; column-gap: 20px">
              <strong >b</strong>
              <div style="display: flex;">
                <select id='7'></select>
                <input/>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px" >
              <strong >c</strong>
              <div style="display: flex">
                <select id="8"></select>  
                <input/>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px" >
              <strong >d</strong>
              <div style="display: flex">
                <select id="9"></select>  
                <input/>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; column-gap: 20px" >
              <strong >e</strong>
              <div style="display: flex">
                <select id="10"></select>  
                <input/>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px" >
              <strong >f</strong>
              <div style="display: flex">
                <select id="11"></select>  
                <input/>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px" >
              <strong >g</strong>
              <div style="display: flex">
                <select id="12"></select>  
                <input/>
              </div>
            </div>
            <div style="display: flex; column-gap: 20px" >
              <strong >h</strong>
              <div style="display: flex">
                <select id="13"></select>  
                <input/>
              </div>
            </div>
          </div>
        </div>
          
          `,
        answer: [
          "750,000",
          "100",
          "4.6 million",
          "6,900",
          "2.5 billion",
         "2,500",
          "999,000",
          "$","£","¥","¥","£", "$","€",
          // "$750,000",
          // "£100",
          // "¥4.6 million",
          // "¥6,900",
          // "£2.5 billion",
          // "$2,500",
          // "€999,000",
        ],
      },
    ],
  },
  2: {
    unit: 'Unit 6',
    id: 'WB10-U6-P48-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Where can you buy these things? Complete the names of the shops with the correct words.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
      marginTop: 5
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div>
            <div style="display: flex; flex-wrap: wrap;  gap: 20px">
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/1.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>1</strong>
                    <div>
                      <div style="display: flex;">
                        <span>D</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/2.png"/>
                    <div style="display: flex; column-gap: 20px">
                      <strong>2</strong>
                      <div style="display: flex;">
                        <span>b</span>
                        <input />
                      </div>
                    </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/3.png"/>
                    <div style="display: flex; column-gap: 20px">
                      <strong>3</strong>
                      <div>
                        <div style="display: flex;">
                          <span>g</span>
                          <input />
                        </div>
                      </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/4.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>4</strong>
                    <div>
                      <div style="display: flex;">
                        <span>b</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/5.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>5</strong>
                    <div>
                      <div style="display: flex;">
                        <span>c</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/6.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>6</strong>
                    <div>
                      <div style="display: flex;">
                        <span>c</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/7.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>7</strong>
                    <div>
                      <div style="display: flex;">
                        <span>g</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>c</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/8.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>8</strong>
                    <div>
                      <div style="display: flex;">
                        <span>p</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>o</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/9.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>9</strong>
                    <div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: calc(24% - (20px * 1/4))" >
                  <img src="img/FriendsPlus/Page48/E2/10.png"/>
                  <div style="display: flex; column-gap: 20px">
                    <strong>10</strong>
                    <div>
                      <div style="display: flex;">
                        <span>s</span>
                        <input />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          `,
        answer: [
          "IY", //1
          "tore",//1
          "aker's", //2
          "reengrocer's",//3
          "utcher's", //4
          "lothes",//5
          "hop",//5
          "osmetics", //6
          "tore",//6
          "arden",//7
          "entre",//7
          "ost", //8
          "ffice",//8
          "hoe",//9
          "hop",//9
          "tationer's"//100


        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'WB10-U6-P48-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Where can you do these things? Complete the answers with the words below.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',

    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
            <div style="display: flex; flex-wrap: wrap; gap: 20px; width: 600px">
              <span style="font-weight: 500; color: rgb(144 38 143)">bank</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">charity shop</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">deli (delicatessen)</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">estate agent's</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">launderette</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">DIY store</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">optician's</span>
              <span style="font-weight: 500; color: rgb(144 38 143)">takeaway</span>
            </div>
            <div style="margin-top: 50px; width: 700px">
              <h4 style="font-weight: 700px; font-size: 23px">Where can you ...</h4>
              <div>
                <strong>1</strong>
                <span style="margin-left: 20px">buy cold meat, cheese, olives, etc.?</span>
                <input >
              </div>
              <div>
                <strong>2</strong>
                <span style="margin-left: 20px">buy a hot meal to eat at home?</span>
                <input />
              </div>
              <div style="">
                <strong>3</strong>
                <span style="margin-left: 20px">keep your money?</span>
                <input  />
              </div>
              <div>
                <strong>4</strong>
                <span style="margin-left: 20px">get glasses for your eyes?</span>
                <input >
              </div>
              <div>
                <strong>5</strong>
                <span style="margin-left: 20px">buy cheap second-hand things? </span>
                <input />
              </div>
              <div>
                <strong>6</strong>
                <span style="margin-left: 20px">find houses and flats for sale?</span>
                <input />
              </div>
              <div>
                <strong>7</strong>
                <span style="margin-left: 20px">wash your clothes?</span>
                <input />
              </div>
              <div>
                <strong>8</strong>
                <span style="margin-left: 20px">buy things to decorate your house?</span>
                <input />
              </div>
            </div>
          `,
        answer: [
          "deli (delicatessen)",
          "takeaway",
          "bank",
          "optician's",
          "charity shop",
          "estate agent's",
          "launderette",
          "DIY store"
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'WB10-U6-P48-E4',
    audio: 'Audios/1.19.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: '<headphone name="1.19"></headphone> Listen to four dialogues one at a time, pausing after each one. Which shops are the people in?',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
      width: 600
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E4/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 15px; margin-top: 20px">
            <strong>1</strong>
            <input />
          </div>
          <div style="display: flex; column-gap: 15px; margin-top: 20px">
            <strong>2</strong>
            <input />
          </div>
          <div style="display: flex; column-gap: 15px; margin-top: 20px">
            <strong>3</strong>
            <input />
          </div>
          <div style="display: flex; column-gap: 15px; margin-top: 20px">
            <strong>4</strong>
            <input />
          </div>
          
          `,
        answer: [
          "newsagent's",
          "florist's",
          "jeweller's",
          "chemist's"
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'WB10-U6-P48-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: 'Complete the dialogues with the words below',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
      textAlign: 'center',
      width: 200
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page48/E5/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
            <div style="display: flex; column-gap: 15px">
              <strong>1</strong>
              <div style="display:flex; flex-wrap: wrap; column-gap: 30px">
                <span style=" color: rgb(144 38 143)">bargain</span>
                <span style=" color: rgb(144 38 143)">discount</span>
                <span style=" color: rgb(144 38 143)">price tag</span>
                <span style=" color: rgb(144 38 143)">sale</span>
              </div>
            </div>
            <div style=" margin-top: 20px ;padding: 10px 20px">
              <div style="display: flex; column-gap: 40px; justify-content: flex-start; align-items: stretch;">
                <strong style="width: 60px">Emma</strong>
                <div>
                  <span>Are those jeans new?</span>
                </div>
              </div>
              <div style="display: flex; column-gap: 40px; justify-content: flex-start; align-items: stretch;">
                <strong style="width: 60px">Kelly</strong>
                <div>
                  <span >Yes, I bought them in a </span>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 40px; justify-content: flex-start; align-items: stretch;">
                <strong style="width: 60px">Emma</strong>
                <div>
                  <span >How much were they?</span>
                </div>
              </div>
              <div style="display: flex; column-gap: 40px;">
                <strong style="width: 60px">Kelly</strong>
                <div>
                  <span >Well, I looked at the</span>
                  <input />
                  <span>and it said £45. But when I got to the till, the sales assistant gave me a £20</span>
                  <input />
                  <span>, so they were only £25.</span>
                </div>
              </div>
              <div style="display: flex; column-gap: 40px; justify-content: flex-start; align-items: stretch;">
                <strong style="width: 60px">Emma</strong>
                <div>
                  <span >That was a </span>
                  <input />
                  <span>!</span>
                </div>
            </div>
            </div>


            <div style="display: flex; column-gap: 15px">
              <strong>2</strong>
              <div style="display:flex; flex-wrap: wrap; column-gap: 30px">
                <span style=" color: rgb(144 38 143)">coupon</span>
                <span style=" color: rgb(144 38 143)">receipt</span>
                <span style=" color: rgb(144 38 143)">refund</span>
                <span style=" color: rgb(144 38 143)">special offer</span>
              </div>
            </div>

            <div style="padding: 10px 20px">
              <div style="display: flex; column-gap: 20px ">
                <div style="width: 280px" >
                 <strong>Fred</strong>
                </div>
                <div>
                  <span>I'd like to buy this scarf, please. Can I use this</span>
                  <input />
                  <span>? It says I can have a £5 discount.</span>
                </div>
              </div>

              <div style="display: flex; column-gap: 20px">
               <div style="width: 267px" >
                 <strong>Sales assistant</strong>
                </div>
                <div>
                  <span>No, I'm afraid you can only use it on full-price items. This scarf is already on</span>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
              <div style="width: 340px" >
              <strong>Fred</strong>
             </div>
                <div>
                  <span>OK. I'll buy it anyway. Actually, it's a present. If my friend doesn't like it, can she bring it back and get a</span>
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
              <div style="width: 234px" >
              <strong>Sales assistant</strong>
             </div>
                <div>
                  <span>Yes, if you give her the</span>
                  <input />
                </div>
              </div>
            
            </div>
          
          `,
        answer: [
          "sale",
          "price tag ",
          "discount",
          "bargain",
          "coupon",
          "special offer",
          "refund",
          "receipt",
        ],
      },
    ],
  },
}

export default json;