import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  6: {
    unit: "Cumulative",
    id: "FG10-C-P79-E6",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page79/E6/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "6",
        title: `
        <span style="color: rgb(0, 110, 147)"> GRAMMAR AND VOCABULARY</span> 
         Choose the best options to fill in the blanks.
        `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],

      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
        ],
        answers: ['0-8', '1-0', '2-4', '3-0', '4-4', '5-4', '6-8', '7-0', '8-4', '9-4'],
        initialValue: [],
      },
      Layout: `
          <div>
            <div style="position: relative;">
              <img src="img/FriendsPlus/Page79/E6/1.jpg" alt="" style="width:900px;height:1000px;"/>
              <div style="position: absolute; top: 350px; left: 100px; max-width: 680px" >
                <span>Do you often go shopping? If so, how do you usually
                <b><sup>1</sup></b>___ the items you buy? Some people still use
                cash, because it's easier to know <b><sup>2</sup></b>___ money you
                have spent. But if you're <b><sup>3</sup></b>___ of money when you
                reach the supermarket check-out, it can be very
                embarrassing. Other people pay by card, so that
                they <b><sup>4</sup></b>___ carry a lot of money around with them.
                Unfortunately, credit cards often make you spend
                more money <b><sup>5</sup></b>___ you had planned. And it isn't until
                the end of the month that you realise that you <b><sup>6</sup></b>___
                the bank a lot of money. To carry cash or cards, you
                need a wallet and a bag – or quite a big pocket! But
                wouldn't it be great if you <b><sup>7</sup></b>___ your wallet at home?
                If you <b><sup>8</sup></b>___ a smartphone, it seems that you will soon
                be able to do that. The company Vocalink
                <b><sup>9</sup></b>___ a new app called Zapp which will allow people
                to pay by phone in the future. Zapp will completely
                change the way we shop – and our mobile phones
                <b><sup>10</sup></b>___ even more necessary than they already are.</span>
              </div>          
            </div>
            <div style="position: relative; width: 800px; margin-top:10px;">
        
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;1 </b></div>
          <div style='flex: 3;'>buy for</div>
          <div style='flex: 3;'>pay</div>
          <div style='flex: 3;'>pay for</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;2 </b></div>
          <div style='flex: 3;'>how much</div>
          <div style='flex: 3;'>how</div>
          <div style='flex: 3;'>how many</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;3 </b></div>
          <div style='flex: 3;'>few</div>
          <div style='flex: 3;'>short</div>
          <div style='flex: 3;'>little</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;4 </b></div>
          <div style='flex: 3;'>don’t have to</div>
          <div style='flex: 3;'>must</div>
          <div style='flex: 3;'>mustn’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;5 </b></div>
          <div style='flex: 3;'>as</div>
          <div style='flex: 3;'>than</div>
          <div style='flex: 3;'>that</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;6 </b></div>
          <div style='flex: 3;'>borrow</div>
          <div style='flex: 3;'>owe</div>
          <div style='flex: 3;'>lend</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;7 </b></div>
          <div style='flex: 3;'>would leave</div>
          <div style='flex: 3;'>will leave</div>
          <div style='flex: 3;'>could leave</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;8 </b></div>
          <div style='flex: 3;'>have</div>
          <div style='flex: 3;'>will have</div>
          <div style='flex: 3;'>would have</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;9 </b></div>
          <div style='flex: 3;'>develops</div>
          <div style='flex: 3;'>is developing</div>
          <div style='flex: 3;'>had developed</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>10</b></div>
          <div style='flex: 3;'>become</div>
          <div style='flex: 3;'>will become</div>
          <div style='flex: 3;'>would become</div>
        </div>

        <div style="position: absolute; top: 0px; left: 49px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 37px; left: 49px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 76px; left: 49px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 49px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 152px; left: 49px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 49px; "><input id='5' type='Circle' /></div>
        <div style="position: absolute; top: 227px; left: 49px; "><input id='6' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 49px; "><input id='7' type='Circle' /></div>
        <div style="position: absolute; top: 302px; left: 49px; "><input id='8' type='Circle' /></div>
        <div style="position: absolute; top: 340px; left: 49px; "><input id='9' type='Circle' /></div>

      </div>
          </div>
        `,
    },
  },
  7: {
    unit: "Cumulative",
    id: "FG10-C-P79-E7",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "7",
        title: `
        <span style="color: rgb(0, 110, 147)"> WRITING</span> 
        Imagine that you are saving up to buy a new electronic
        device. Write a blog post describing how you have
        managed to save money. Include this information:
        `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div>
            <ul>
              <li>what you want to buy</li>
              <li>three methods you have used to save money</li>
              <li>what you are going to do when you have enough money
              to buy the device.</li>
            </ul>
          </div>
                <textarea id="0" rows="7" ></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
