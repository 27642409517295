import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title:
					"Match sentences 1-5 with the decisions, offers and promises (a-e). Complete a-e with will or won't and the verbs below.",
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			borderBottom: '1px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */ `
        <div style="display: flex; flex-wrap: nowrap; column-gap:20px;color: rgb(246 140 31)">
          <span style="font-size: 25;font-weight: 700;">be</span>
          <span style="font-size: 25;font-weight: 700;">carry</span>
          <span style="font-size: 25;font-weight: 700;">have</span>
          <span style="font-size: 25;font-weight: 700;">help</span>
          <span style="font-size: 25;font-weight: 700;">lose</span>
        </div>
        <div style="width: 500px">
          <div style="display: flex; align-item: center; justify-content: space-between;">
            <div style="display: flex; align-item: center; column-gap: 20px">
              <span  style="font-weight: 600">1</span>
             <span style="font-weight: 500">This bag is very heavy.</span>
            </div>
            <div>
              <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
            </div>
          </div>
          <div style="display: flex; align-item: center; justify-content: space-between;">
            <div style="display: flex; align-item: center; column-gap: 20px">
              <span  style="font-weight: 600">2</span>
              <span style="font-weight: 500">See you at eight o'clock.</span>
            </div>
            <div> <input  width="36px" border="1px solid"; border-radius= "5px" text-align="center" maxlength="1" /> </div>
          </div>
          <div style="display: flex; align-item: center; justify-content: space-between;">
            <div style="display: flex; align-item: center; column-gap: 20px">
              <span  style="font-weight: 600">3</span>
              <span style="font-weight: 500">You can borrow my phone.</span>
            </div>
            <div> <input  width="36px" border="1px solid"; border-radius= "5px" text-align="center" maxlength="1"/> </div>
          </div>
          <div style="display: flex; align-item: center; justify-content: space-between;">
            <div style="display: flex; align-item: center; column-gap: 20px">
              <span  style="font-weight: 600">4</span>
              <span style="font-weight: 500">I can't do this maths calculation. </span>
            </div>
            <div> <input  width="36px" border="1px solid"; border-radius= "5px" text-align="center" maxlength="1"/> </div>
          </div>
          <div style="display: flex; align-item: center; justify-content: space-between;">
            <div style="display: flex; align-item: center; column-gap: 20px">
              <span  style="font-weight: 600">5</span>
              <span style="font-weight: 500">What would you like to drink? </span>
            </div>
            <div> <input maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center"/> </div>
          </div>

        </div>

        <div style="margin-top: 50px">
          <div style="display: flex; column-gap: 20px">
            <span  style="font-weight: 600">a</span>
            <div>
              <span>I</span>
              <input  maxlength="50" width="150px" border="none" borderBottomWidth >
              <span>it for you</span>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <span  style="font-weight: 600">b</span>
            <div>
              <span>OK. </span>
              <input maxlength="50" width="150px" />
              <span>late!</span>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <span  style="font-weight: 600">c</span>
            <div>
              <span>Show it to me and I</span>
              <input maxlength="50" width="150px" />
              <span>you</span>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <span  style="font-weight: 600">d</span>
            <div>
              <span>I</span>
              <input maxlength="50" width="150px" />
              <span>lemonade, please</span>
            </div>
          </div>
          <div style="display: flex; column-gap: 20px">
            <span  style="font-weight: 600">e</span>
            <div>
              <span>Thanks! I</span>
              <input maxlength="50" width="150px" />
              <span>it.</span>
            </div>
          </div>
        </div>
        `,
				answer: [
					'a',
					'b',
					'e',
					'c',
					'd',
					"will carry/'ll carry",
					"won't be",
					"won't lose",
					"will help/'ll help",
					"will have/'ll have",
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E2',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '2',
				title: 'Circle will or be going to to complete the predictions',
				color: '#000000',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page41/E2/Key/answerKey.png',

		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#2CBAEA',
				},
				limitSelect: 1,
				listWords: [
					'will / are&ensp;going&ensp;to',
					'’ll / ’s&ensp;going&ensp;to.', //0
					'won’t / isn’t&ensp;going&ensp;to', //1
					'’ll / ’s&ensp;going&ensp;to', //2
					'will / am&ensp;going&ensp;to', //3
					'’ll / ’s&ensp;going&ensp;to', //4
				],
				answers: ['0-0', '1-4', '2-4', '3-4', '4-0', '5-4'],
				initialValue: [],
			},
			Layout: /*html */ `
      <div style=" display: flex; flex-direction: row; line-height: 48px; ">
        <div>
          <div>
          <strong>1 </strong>
            I don't think Chelsea 
              <strong>
                <input id='0' type='Circle' />
              </strong> 
            win the Champions League next year. Their team isn't strong enough.
          </div>
          <div>
          <strong>2 </strong>
            Look at those clouds. It 
              <strong>
                <input id='1' type='Circle' />
              </strong>
            snow. 
          </div>
          <div>
          <strong>3 </strong>
            Kat hasn't done any revision. She 
              <strong>
                <input id='2' type='Circle' />
              </strong> pass her exams.
          </div>
          <div>
          <strong>4 </strong>
            That car is going much too fast! It 
              <strong>
                <input id='3' type='Circle' />
              </strong> 
            crash! 
          </div>
          <div>
          <strong>5 </strong>
            I
              <strong>
                <input id='4' type='Circle' />
              </strong>
            email you tonight – I promise!
          </div>
          <div>
          <strong>6 </strong>
            Jack's holding a tennis racket. He 
              <strong>
                <input id='5' type='Circle' />
              </strong> 
            play tennis! 
          </div>
        </div>
      </div>
      `,
		},
	},
	3: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title:
					'Look at the pictures and complete the predictions with the affirmative or negative form of be going to.',
				color: '#000000',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page41/E3/Key/answerKey.png',
		questions: [
			{
				title: /*html */ `
          <div style="margin-top: 20px">
            <img src="img/FriendsPlus/Page41/E3/1.png"/>
            <div style="margin-top: 20px">
              <span>1</span>
              <span style="margin-left: 20px">She</span>
              <input width="50"/>
              <span>fall.</span>
            </div>
          </div>
          <div style="margin-top: 20px">
            <img src="img/FriendsPlus/Page41/E3/2.png"/>
            <div style="margin-top: 20px">
              <span>2</span>
              <span style="margin-left: 20px">They</span>
              <input width="50"/>
              <span>arrive on time.</span>
            </div>
          </div>
        `,
				answer: [
					"'s going to / is going to",
					"aren't going to / are not going to",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '4',
				title:
					'Order the words in a–e and then match them with 1–5 to make the first conditional sentences.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			borderBottom: '1px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page41/E4/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html*/ `
          <div style="display: flex; column-gap: 20px;">
            <span style="font-weight: 600">1</span>
            <span>If you don't finish your homework in time, </span>
            <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
          </div>
          <div style="display: flex; column-gap: 20px;">
            <span style="font-weight: 600">2</span>
            <span>He'll do an hour's revision in the morning</span>
            <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
          </div>
          <div style="display: flex; column-gap: 20px;">
            <span style="font-weight: 600">3</span>
            <span>If she needs money over the summer,</span>
            <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
          </div>
          <div style="display: flex; column-gap: 20px;">
            <span style="font-weight: 600">4</span>
            <span>If he decides to become an engineer, </span>
            <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
          </div>
          <div style="display: flex; column-gap: 20px;">
            <span style="font-weight: 600">5</span>
            <span>If I pass all of my exams, </span>
            <input  maxlength="1" width="36px" border="1px solid"; border-radius= "5px" text-align="center" />
          </div>
          <div style="margin-top: 40px; width: 500px; ">
            <div>
                <span>
                  <span style="font-weight: 600; margin-right: 20px">a</span>
                  <span>at university / apply / a place / for / he'll</span>
                </span> </br>
                <input width="440px"  />
            </div>
            <div>
                <span>
                  <span style="font-weight: 600; margin-right: 20px">b</span>
                  <span>teacher / happy / be / our / won't</span>
                </span> </br>
                <input width="440px"  />
            </div>
            <div>
                <span>
                  <span style="font-weight: 600; margin-right: 20px">c</span>
                  <span>wakes / up / enough / if / early / he</span>
                </span> </br>
                <input width="440px"  />
            </div>
            <div>
                <span>
                  <span style="font-weight: 600; margin-right: 20px">d</span>
                  <span>parents / give / will / me / my / a reward</span>
                </span> </br>
                <input width="440px"  />
            </div>
            <div>
                <span>
                  <span style="font-weight: 600; margin-right: 20px">e</span>
                  <span>a / she'll / job / get / part-time</span>
                </span> </br>
                <input width="440px"  />
            </div>
          </div>
        `,
				answer: [
					'b',
					'c',
					'e',
					'a',
					'd',
					"our teacher won't be happy",
					'if he wakes up early enough.',
					"she'll get a part-time job.",
					"he'll apply for a place at university",
					'my parents will give me a reward',
				],
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E5',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '5',
				title: 'Complete the dialogue using the first conditional.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			borderBottom: '2px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page41/E5/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html*/ `
        <div style="max-width: 700px;display:flex; align-items: stretch; column-gap: 2rem">
          <span style="font-weight: 600;">Sarah</span>
          <div>
            <span>Do you fancy going to the café later?</span>
          </div>
        </div>
        <div style="max-width: 700px;display:flex; align-items: stretch; column-gap: 2rem">
          <span style="font-weight: 600;">Claire</span>
          <div>
            <span>No thanks. If I go to the café, I </span>
            <input width="150px" />
            <span>(fail) my exam on Monday.</span>
          </div>
        </div>
        <div style="max-width: 700px;display:flex; align-items: stretch; column-gap: 2rem">
          <span style="font-weight: 600;">Sarah</span>
          <div>
            <span>Really? What do you mean?</span>
          </div>
        </div>
        <div style="max-width: 700px;display:flex; align-items: stretch; column-gap: 2rem">
          <span style="font-weight: 600;">Claire</span>
          <div>
            <span>If I go to the café, I</span>
            <input width="150px" />
            <span>(see) Cathy. If I see Cathy, she</span>
            <input width="150px" />
            <span> (invite) me to her party. If she </span>
            <input width="150px" />
            <span>(invite) me to her party, I'll stay out late on Saturday. If I stay out late on Saturday, I </span>
            <input width="150px" />
            <span>(sleep) all Sunday morning. If I sleep all Sunday morning, I </span>
            <input width="150px" />
            <span> (not do) any revision, I</span>
            <input width="150px" />
            <span>(fail) my exam on Monday!</span>
          </div>
        </div>
        `,
				answer: [
					"will fail/'ll fail",
					"will see/'ll see",
					"will invite/'ll invite",
					'invites',
					"will sleep/'ll sleep",
					"won't do",
					"will fail/'ll fail",
				],
			},
		],
	},
	6: {
		// Exercise num
		unit: 'Unit 5',
		id: 'WB10-U5-P41-E6',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '6',
				title:
					'Now write a dialogue like the one in exercise 3. Use the chain of ideas below.',
				color: '#000000',
			},
		],
		stylesTextInput: {
			fontSize: 20,
			borderBottom: '2px solid rgb(0 0 0 )',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page41/E6/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			// Row
			{
				title: /*html*/ `
          <div style="display: flex; max-width: 850px; column-gap: 10px; flex-wrap: wrap; align-items: center; color:rgb(246 140 31);">
            <span style="font-weight: 600; font-size: 22px">go shoppoing</span>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="fill: rgb(246 140 31)"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
            <span style="font-weight: 600; font-size: 22px">spend all my money</span>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="fill: rgb(246 140 31)"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
            <span style="font-weight: 600; font-size: 22px"> have to stay in on Friday night</span>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="fill: rgb(246 140 31)"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
            <span style="font-weight: 600; font-size: 22px">not see Connor</span>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="fill: rgb(246 140 31)"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
            <span style="font-weight: 600; font-size: 22px">he / not invite me to barbecue</span>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="fill: rgb(246 140 31)"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
            <span style="font-weight: 600; font-size: 22px">not see his sister again</span>
          </div>
          <div>
            <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
              <span style="font-weight: 600">Susie</span>
              <span>Would you like to go shopping later?</span>
            </div>
            <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
              <span style="font-weight: 600">Dave</span>
              <span>I can't. If I go shopping, I won't see Connor's sister
              again.</span>
            </div>
            <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
              <span style="font-weight: 600">Susie</span>
              <span>Really? What do you mean?</span>
            </div>
            <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
              <span style="font-weight: 600">Susie</span>
              <span>If I go shopping, I'll spend all my money.</span>
            </div>
            <div style="display: flex; column-gap: 20px; align-items: stretch;"> 
              <div style="display: flex; font-size: 25px">
                <span style="margin:7px 20px 0 0">If I </span>
                <input width="600px" margin-top="15px"/>
              </div>
            </div>
          <div>
            <input width="650px" />
          </div>
          <div>
            <input width="650px" />
          </div>
          <div>
            <input width="650px" />
          </div>
        `,
				answer: [
					"spend all my money, I'll have to stay in on Friday night.",
					"If I have to stay in, I won't see Connor.",
					'If I don’t see Connor, he won’t invite me to the barbecue.',
					'If he doesn’t invite me to the barbecue, I won’t see his sister again!',
				],
			},
		],
	},
}

export default json
