import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P27-E1",
    exerciseKey: "img/FriendsPlus/Page27/E1/key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Choose the correct verbs to complete the dialogue.",
      },
    ],
    component: Circle_Write,
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        // initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
        initialWordStyle: {
          margin: "5px 0",
          border: "none",
          borderColor: "transparent",
          borderRadius: "50%",
        },
        selectWordStyle: {
          border: "solid 2px",
          borderColor: "rgb(187, 7, 59)",
          margin: "-2px",
          padding: 5,
        },
        limitSelect: 1,
        listWords: [
          "must / needn't",
          "have_to / mustn't",
          "don't_have_to / mustn't",
          "don't_have_to / must",
          "have_to / needn't",
          "don't_have_to / must",
          "have_to / needn't",
          "mustn't / needn't",
        ],
        answers: ["0-0", "1-0", "2-4", "3-0", "4-0", "5-4", "6-4", "7-0"],
        initialValue: [],
      },
      Layout: `
				<div style='margin-top: 30px; display: flex; gap: 5px;'>
					<div style='flex: 3; font-size: 22px;'>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>Is this your new video game?</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>Yes, it is. You <strong><sup>1</sup><input id='0' type='Circle' /></strong> try it. It’s great!</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>How do you play?</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>First, you <sup>2</sup><strong><input id='1' type='Circle' /></strong> escape from the castle.</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>How? Down the stairs?</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>Stop! You <sup>3</sup><strong><input id='2' type='Circle' /></strong> open that door!</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>Oh! Too late! Am I dead?</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>Yes. But you <sup>4</sup><strong><input id='3' type='Circle' /></strong> start again. Just press ‘B’ to continue.</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>OK, thanks. What now?</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>You <sup>5</sup><strong><input id='4' type='Circle' /></strong> climb out of the window. But slowly! You <sup>6</sup><strong><input id='5' type='Circle' /></strong> be careful.</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>Oh no. I’m dead again. I’m not very good at this.</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Ryan</strong>
							<div>Well, we <sup>7</sup><strong><input id='6' type='Circle' /></strong> play this game. I’ve got lots of others …</div>
						</div>
						<div style='display: flex; gap: 20px;'>
							<strong>Alice</strong>
							<div>No, no. I <sup>8</sup><strong><input id='7' type='Circle' /></strong> give up. Do I press ‘B’ again?</div>
						</div>
					</div>
					<div style='flex: 2'><img src="img/FriendsPlus/Page27/E1/1.jpg" style="max-width: 100%; height: auto;" /></div>
				</div>
      `,
    },
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P27-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page27/E2/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the sentences with  <i>mustn't</i> or <i> don't / doesn't</i>
have to.
			  `,
        color: "black",
      },
    ],
    inputSize: 200,
    // stylesTextInput: {
    //   borderBottom: "dash",
    //   textAlign: "center",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 23,
    // },
    maxLength: 15,
    textAlign: "center",
    titleImage: "",
    questions: [
      {
        title: `
        <div style='line-height:45px;width:20cm'>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
            He's a brilliant student – he#word hard to get good marks.
          </div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
            You can stay up late, but you#make a lot
            of noise and wake up your sister.
          </div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
            We#forget the name of our hotel.
          </div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
            She always pays by credit card so that she#carry a lot of money with her.
          </div>
        </div>
        <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
            You#tell Jack about the party – it's a secret!
          </div>
        </div>
        <div style='display:flex'>
          <b>6</b>
          <div style='margin-left:20px'>
            The bus leaves at 9 a.m. exactly, so you#be late.
          </div>
        </div>
        <div style='display:flex'>
          <b>7</b>
          <div style='margin-left:20px'>
              We#wear uniforms to school, but we#wear jeans or trainers.
          </div>
        </div>
        </div>
        

			  `,
        answer: [
          "doesn't have to",
          "mustn't",
          "mustn't",
          "doesn't have to",
          "mustn't",
          "mustn't",
          "don't have to",
          "mustn't",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P27-E3",
    audio: "",
    video: "",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page27/E3/key/answerKey.png",
    isHiddenCheck: true,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title: `
				Look at the advert for the quiz night. Then complete the
sentences with <i>must / have to</i>, <i>mustn't</i> or <i>don't have to /
needn't</i>. Sometimes more than one answer is possible.
			  `,
        color: "black",
      },
    ],
    inputSize: 270,
    textAlign: "center",
    titleImage: "",
    questions: [
      {
        title: `
					<div style='display: flex; gap: 20px;'>
						<div style='padding: 15px; border-radius: 20px; border: solid 1px rgb(254, 211, 168);'>
							<div style='display: flex; gap: 20px;'>
								<strong>1</strong>
								<div>You # be part of a team.</div>
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>2</strong>
								<div>You # arrive after 6 o’clock.</div>
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>3</strong>
								<div>You # pay £3 to enter the quiz.</div>
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>4</strong>
								<div>You # be a student at the school.</div>
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>5</strong>
								<div>You # use a smartphone during the quiz.</div>
							</div>
						</div>
						<div><img src="img/FriendsPlus/Page27/E3/1.jpg" style="max-width: 100%; height: auto; border-radius: 20px;" /></div>
					</div>
			  `,
        answer: [
          "don't have to / needn't",
          "mustn't",
          "must / have to",
          "must / have to",
          "mustn't",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P27-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page27/E4/key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				Make the active sentences passive. Use modal verbs.
			  `,
        color: "black",
      },
    ],
    inputSize: 530,
    maxLength: 47,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    questions: [
      {
        title: `
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>
             You mustn't eat those mushrooms<br><i style='color:rgb(94 133 151)' >Those mushrooms mustn't be eaten.</i>
          </div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>
             They needn't cancel the concert.<br>#
          </div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>
             We mustn't use mobile phones during lessons.<br>#
          </div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>
             You have to write your name in capital letters.<br>#
          </div>
        </div>
        <div style='display:flex'>
          <b>5</b>
          <div style='margin-left:20px'>
             We must hand in homework on time.<br>#
          </div>
        </div>
        <div style='display:flex'>
          <b>6</b>
          <div style='margin-left:20px'>
             You have to wear school uniforms to school. <br>#
          </div>
        </div>
        

			  `,
        answer: [
          "The concert needn't be cancelled.",
          "Mobile phones mustn't be used during lessons.",
          "Your name has to be written in capital letters.",
          "Homework must be handed in on time.",
          "School uniforms have to be worn to school.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P27-E5",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title: `
				Write sentences about rules at school. Use have to, must and
needn't and the ideas below. If you can, add your own ideas.
			  `,
        color: "black",
      },
    ],
    inputSize: 620,
    maxLength: 47,
    questions: [
      {
        title: `
        <div style='color:rgb(246 142 31);width:20cm'>
            arrive at school on time&emsp;&ensp; quiet in the corridors&emsp;&ensp;
            eat snacks in class&emsp;&ensp;hand in homework on time&emsp;&ensp;
            have lunch in the canteen&emsp;&ensp;use a mobile phone in class&emsp;&ensp;
            wear school uniforms
        </div>
        <div><textarea id='0' rows= '6'></textarea></div>
			  `,
        answer: [""],
      },
    ],
  },
};

export default json;
