import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			border: 'none',
			padding: 0,
			fontSize: 22,
		},
		textareaStyle: { width: 495, resize: 'none' },
		exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
		inputSize: 147,
		titleQuestion: [
			{
				num: '1',
				title: 'Complete the table.',
			},
		],
		questions: [
			{
				title: `
        <div style="position: relative; margin: 10px; ">
            <div><img src='img/FriendsPlus/Page13/E1/1.jpg' /></div>
            <div >
              <div style=" position: absolute; top: 83px; left: 319px; "><input id='0' /></div>
              <div style=" position: absolute; top: 122px; left: 92px; "><input id='1' /></div>
              <div style=" position: absolute; top: 160px; left: 325px; "><input id='2' /></div>
              <div style=" position: absolute; top: 230px; left: 98px; "><input id='3' /></div>
              <div style=" position: absolute; top: 269px; left: 313px; "><input id='4' /></div>
              <div style=" position: absolute; top: 308px; left: 87px; "><input id='5' /></div>
            </div>
          </div>
        `,
				answer: ['bleed ', 'burn ', 'cut ', 'sprain ', 'injure ', 'broken'],
			},
		],
	},

	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E3',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
		inputSize: 120,
		titleQuestion: [
			{
				num: '2',
				title: 'Complete the sentences with the words below.',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div style="color: rgb(62, 63, 153); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>bruise</div>
                <div>fell over</div>
                <div>hurt</div>
                <div>hurts</div>
                <div>pain</div>
                <div>slipped</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">I've got a big black # where I banged my arm.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">I've got a # in my shoulder.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">My finger # .</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">Joe # himself when he was playing football</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">My mum # on the ice and # .</div></div>
              </div>
        `,
				answer: [
					'bruise ',
					'pain ',
					'hurts ',
					'hurt ',
					'slipped ',
					'fell over',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E3',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderRadius: '10px',
			border: '1px solid',
			padding: 10,
			fontSize: 22,
			textTransform: 'uppercase',
		},
		exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
		inputSize: 33,
		maxLength: 1,
		titleQuestion: [
			{
				num: '3',
				title: 'Read the text. Are the sentences true (T) or false (F)?',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">Lenkei took about half an hour to hypnotise himself. </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">Lenkei didn't speak during the operation.</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">All the operations were successful.</div><div style="flex: 1"> #</div></div></div>
                <div><img src='img/FriendsPlus/Page13/E3/1.jpg' /></div>
              </div>
        `,
				answer: ['F', 'F', 'T'],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P12-E3',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderRadius: '10px',
			border: '1px solid',
			padding: 10,
			fontSize: 22,
			textTransform: 'uppercase',
		},
		exerciseKey: 'img/FriendsPlus/Page13/E4/Key/answerKey.png',
		inputSize: 35,
		maxLength: 1,
		titleQuestion: [
			{
				num: '4',
				title:
					'Read the <span style="color:rgb(62, 63, 153)">Reading Strategy</span>. Then match the questions below with paragraphs 1–3 of the text. ',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div>
              <div style="text-align: center"><img style="width: 50%" src='img/FriendsPlus/Page13/E4/1.jpg' /></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>A </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">when the first operation happened?</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>B </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">why Lenkei needed an operation on his arm? </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>C </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">what Lenkei did just before the operation on his arm? </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>D </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">on what part of his body the third operation was?</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>E </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">when Doctor Llewellyn-Clerk realised that Lenkei was not in pain? </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>F </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">when Lenkei first hypnotised someone?</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>G </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">what happens to pain signals in his body while he is hypnotised? </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>H </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">when Lenkei had his second operation?</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>I </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">how long the operation on his arm took?</div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>J </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">what the doctor did to Lenkei's arm during the operation?</div><div style="flex: 1"> #</div></div></div>
                <div><img src='img/FriendsPlus/Page13/E3/1.jpg' /></div>
              </div>
        `,
				answer: ['3', '1', '1', '3', '2', '3', '1', '1', '2', '2'],
			},
		],
	},
}

export default json
