import React from 'react';
import styles from './headphone.module.css';

function HeadPhone({ name = '', colorText = 'black', colorHeadphone = 'rgb(210, 181, 213)' }) {
  return (
    <div className={styles.container} style={{ backgroundColor: colorText }}>
      <div className={styles.icon_container} style={{ color: colorHeadphone }}>
        <i className={`fas fa-headphones ${styles.icon_container_content}`} />
      </div>
      <div className={styles.name_container}>
        <span className={styles.name_container_content}>{`${name}`}</span>
      </div>
    </div>
  );
}

export default HeadPhone;
