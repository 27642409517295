import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "WB10-U4-P37-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E1/key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the sentences with the correct form of the verbs below.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 160,
    textAlign: "center",
    maxLength: 15,
    titleImage: "",
    questions: [
      {
        title: `
				<div style='color: rgb(64, 63, 155); margin:0px 40px 20px 40px;'><b>
					approach &emsp; head &emsp; land &emsp; reach &emsp; spin &emsp; take off
				</b></div>
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>A snow storm was #, so we went inside.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>The plane # from London at 9 a.m. yesterday and # in Paris an hour later.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>It was very late when we # Oxford.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>The plane was # towards Australia when it disappeared.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>The helicopter began to # out of control.</div>
        </div>
			  `,
        answer: [
          "approaching",
          "took off",
          "landed",
          "reached",
          "heading",
          "spin",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "WB10-U4-P37-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the text, ignoring the gaps. Answer the questions.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 360,
    textAlign: "left",
    maxLength: 85,
    titleImage: "",
    questions: [
      {
        title: `  
        <div style='display:flex; gap:15px;'>
          <div>
            <div style='display:flex; gap:15px;'>
              <b>1</b>
              <div>
                <div>What kind of natural disaster did Diana and Pete experience?</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>2</b>
              <div>
                <div>Where were they when they realised there was a problem?</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>3</b>
              <div>
                <div>Where was Monica?</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>4</b>
              <div>
                <div>In which room did Monica and Diana hide?</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>5</b>
              <div>
                <div>Where did Pete hide?</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>6</b>
              <div>
                <div>How many people died in the disaster?</div>
                <div>#</div>
              </div>
            </div>
          </div>
          <img src='img/FriendsPlus/Page37/E2/1.jpg' style='width:600px;' />
        </div>
			  `,
        answer: [
          "a tornado",
          "at home",
          "at school",
          "a bathroom",
          "in a cupboard under the stairs",
          "twenty-four",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "WB10-U4-P37-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Read the <span style='color:rgb(64, 63, 155);'>Reading Strategy</span>. Then match the phrases (A–I) with gaps 1–8 in the text. Check that each phrase fits the grammar of the sentence. There is one extra phrase.
			  `,
        color: "black",
      },
    ],
    isHiddenCheck: true,
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 40,
      textAlign: "center",
      border: "2px solid black",
      borderRadius: "20%",
    },
    selectOptionValues: ["-", "1", "2", "3", "4", "5", "6", "7", "8"],

    questions: [
      {
        title: `  
        <div style='display:flex; align-items: start; gap:15px;'>
          <div>
            <div style='background-color: rgb(228, 227, 241); margin-top: 20px; margin-left:0px; width:450px; border-radius: 15px; border: 5px dotted rgb(64, 63, 155)'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div ><b style='color:rgb(64, 63, 155) ;'>Reading Strategy</b></div>
                <div>To help you decide which phrases fit which gaps, think about the grammar of each sentence. For example, is the first word in the gap a verb? Or is a different kind of word needed?
                </div>
              </div>
            </div>
            <div style='display:flex; gap:20px; margin-top: 30px;'>            
              <select id='0'></select>
              <div><b>A &ensp;</b>spinning around in the air</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='1'></select>
              <div><b>B &ensp;</b>bring her home</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='2'></select>
              <div><b>C &ensp;</b>ran back to their house</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='3'></select>
              <div><b>D &ensp;</b>which warned them that</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='4'></select>
              <div><b>E &ensp;</b>were not as lucky</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='5'></select>
              <div><b>F &ensp;</b>without looking back</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='6'></select>
              <div><b>G &ensp;</b>holding their maths books</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='7'></select>
              <div><b>H &ensp;</b>realised where he was</div>
            </div>
            <div style='display:flex; gap:20px; margin-top: -15px;'>
              <select id='8'></select>
              <div><b>I &ensp;</b>to leave the school</div>
            </div>
          </div>
          <img src='img/FriendsPlus/Page37/E3/1.jpg' style='width:600px;' />
        </div>
			  `,
        answer: ["5", "2", "6", "1", "8", "-", "3", "7", "4"],
      },
    ],
  },
};

export default json;
