
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'WB10-U6-P51-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '1',
        title: 'Read the example of the past perfect. Then look at the timeline and answer the question below',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page51/E1/Key/answerKey.png',

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: ["A B C"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: /*html */ `
        <div style="background-color: rgb(254 241 226); width: 700px; height: 250p; padding: 20px; font-size: 20px">
          <img src="img/FriendsPlus/Page51/E1/1.png" style="object-fit: cover;" />
          <div style="display: flex; column-gap: 20px">
            <strong>When did somebody tidy my room?</strong>
            <strong style="display: flex; column-gap: 30px"><input id="0" type="Circle"/></strong>
          </div>
        </div>
        
      `,
    },
  },
  2: {
    unit: 'Unit 6',
    id: 'WB10-U6-P51-E2',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the sentences with the past perfect form of the verbs in brackets.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 150,
      textAlign: 'center',
      borderBottom: '1px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page51/E2/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <span>Tom <input /> (take) his bike apart and was trying to fix it.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <span>She took out a letter which she <input /> (keep) in a drawer for years.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <span>When I clicked 'buy', the price <input /> (increase)</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <span>I was cross when the computer suddenly switched off, because I <input /> (not save) my document.</span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <span>He couldn't move back to London because he <input /> (sell) his flat there.</span>
          </div>

        `,
        answer: [
          "had taken",
          "had kept",
          "had increased",
          "hadn't saved / had not saved",
          "had sold",
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'WB10-U6-P51-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Complete the text with the past perfect form of the verbs in brackets.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 150,
      textAlign: 'center',
      borderBottom: '1px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page51/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="position: relative; width: 800px">
          <img src="img/FriendsPlus/Page51/E3/1.png" style="width: 800px; height: 200px; object-fit: contain;" />
          <div style="border-radius: 20px ;border: 5px solid rgb(246 140 31); background-color:rgb(254 241 226); padding: 15px; box-shadow: 9px 9px 1px rgb(254 241 226);">
              <span>
              A family in Wales has tried, without success, to get their
              hands on £5,000 which belongs to them. Six months
              ago, Richard Williams found a note inside a book. It was
              from his father, Allan Jones, who <input /> (die) two
              years earlier. The note told Richard about a metal box
              with £5,000 inside - money that his father <input /> 
              (save up) during his lifetime. Before he died, his father <input />
              (bury) the box in the garden, near the house. Then he <input />
              (write) a note for the family. He <input /> (draw) a small map too, showing the
              exact location of the money. <br />
              Unfortunately, since Allan's death, the Williams family <input />
              (do) some building work. They <input />(add) two rooms to the back of house – directly on top
              of the buried money! ‘If we tried to get the money now,
              we'd cause too much damage. It would cost more than
              £5,000 to repair it,' says Richard, who <input /> (not
                know) anything about his father's money until he found
                the note.
              </span>
          </div>
        </div>
         
        `,
        answer: [
          "had died",
          "had saved up",
          "had buried",
          "had written",
          "had drawn",
          "had done",
          "had added",
          "hadn't known / had not known",
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'WB10-U6-P51-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: 'Rewrite the following sentences using one past perfect verb and one past simple verb.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 450,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page51/E4/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="display: flex; column-gap: 20px">
          <strong>1</strong>
          <div>
            <span>I bought a ticket, then I checked the numbers.</span><br />
            <span>After <span style="font-style: oblique; color:rgb(0 68 98); ">I'd bought a ticket, I checked the numbers</span></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>2</strong>
          <div>
            <span>We bought some chicken, then we made dinner.</span><br />
            <span>When <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>3</strong>
          <div>
            <span>My parents went out, then I got home.</span><br />
            <span>When <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>4</strong>
          <div>
            <span>The sun came out, then we arrived at the beach.</span><br />
            <span>After <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>5</strong>
          <div>
            <span>I took my bike apart, then I cleaned every piece.</span><br />
            <span>After <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>6</strong>
          <div>
            <span>It got dark, then we arrived at the hotel.</span><br />
            <span>When <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>7</strong>
          <div>
            <span>I spoke to my dad, then I applied for the job.</span><br />
            <span>After <input /></span>
          </div>
        </div>
        <div style="display: flex; column-gap: 20px">
          <strong>8</strong>
          <div>
            <span>She read the letter, then she began to laugh.</span><br />
            <span>When <input /></span>
          </div>
        </div>
       
         
        `,
        answer: [
          "we'd bought some chicken, we made dinner",
          "I got home, my parents had gone out",
          "the sun had come out, we arrived at the beach",
          "I had taken my bike apart, I cleaned every piece",
          "we arrived at the hotel, it had got dark.",
          "I had spoken to my dad, I applied for the job",
          "she had read the letter, she began to laugh."
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'WB10-U6-P51-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: 'Look at the updates showing what Callum did yesterday. Then write more sentences like the example. Use the past perfect and the past simple.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 450,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page51/E5/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 30px">
            <img src="img/FriendsPlus/Page51/E5/1.png"  style="object-fit: cover; width: 600px"/>
            <div style="margin-top: 15px">
              <div style="display: flex; column-gap: 20px">
                <strong>1</strong>
                <div>
                  <span>get up / have breakfast</span> <br />
                  <span style="font-style: oblique; color:rgb(0 68 98); ">After he'd got up, he had breakfast.</span>
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
                <strong>2</strong>
                <div>
                  <span>have breakfast / go shopping</span> <br />
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
                <strong>3</strong>
                <div>
                  <span>buy a skateboard / meet friends for lunch</span> <br />
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
                <strong>4</strong>
                <div>
                  <span>finish his homework / practise the guitar</span> <br />
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
                <strong>5</strong>
                <div>
                  <span>have dinner / watch a DVD</span> <br />
                  <input />
                </div>
              </div>
              <div style="display: flex; column-gap: 20px">
                <strong>6</strong>
                <div>
                  <span>read a magazine / go to bed </span> <br />
                  <input />
                </div>
              </div>
            </div>
          </div>
        `,
        answer: [
          "After he'd had breakfast, he went shopping",
          "After he'd bought a skateboard, he met friends for lunch",
          "After he'd finished his homework, he practised the guitar",
          "After he'd had dinner, he watched a DVD",
          "After he'd read a magazine, he went to bed",
        ],
      },
    ],
  },
}
export default json;
