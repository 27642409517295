import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P23-E1",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    inputSize: 200,
    titleImage: "",
    exerciseKey: "img/FriendsPlus/Page23/E1/Key/answerKey.png",
    titleQuestion: [
      { num: "1", title: " Label the photos with the words below." },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              <div style="color: rgb(39, 131, 197); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; line-height: 10px; margin: 15px">
                <div>abseiling</div>
                <div>jet-skiing</div>
                <div>kayaking</div>
                <div>kite surfing</div>
                <div>mountain biking</div>
                <div>orienteering</div>
                <div>quad biking</div>
                <div>rock climbing</div>
              </div>
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page23/E1/1.jpg'>
                <div style="display: flex"><b>1</b><p>#</p></div>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page23/E1/2.jpg'>
                <div style="display: flex"><b>2</b><p>#</p></div>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page23/E1/3.jpg'>
                <div style="display: flex"><b>3</b><p>#</p></div>
              </div>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page23/E1/4.jpg'>
                <div style="display: flex"><b>4</b><p>#</p></div>
              </div>
            </div>
            <div style='display: flex; justify-content: space-between;'>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page23/E1/5.jpg'>
                <div style="display: flex"><b>5</b><p>#</p></div>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page23/E1/6.jpg'>
                <div style="display: flex"><b>6</b><p>#</p></div>
              </div>
              <div>
                <img style='width:80%' src='img/FriendsPlus/Page23/E1/7.jpg'>
                <div style="display: flex"><b>7</b><p>#</p></div>
              </div>
              <div>
                <img  style='width:80%'src='img/FriendsPlus/Page23/E1/8.jpg'>
                <div style="display: flex"><b>8</b><p>#</p></div>
              </div>
            </div>
            </div>

          `,
        answer: [
          "kite surfing ",
          "kayaking ",
          "rock climbing",
          "mountain biking ",
          "quad biking ",
          "abseiling",
          "orienteering ",
          "jet-skiing",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P23-E2",
    exerciseKey: "/img/FriendsPlus/Page23/E2/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 140,
    maxLength: 14,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      borderBottom: "none",
      backgroundColor: "transparent",
      fontSize: 22,
    },
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title: `
        Read the <span style="color: rgb(39, 131, 197)">Writing Strategy</span>. Then complete the invitation and the reply with the abbreviations below.
      `,
      },
    ],

    questions: [
      {
        title: `
            <div style="color: rgb(119, 164, 214); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; line-height: 10px; margin: 15px">
                <div>e.g.</div>
                <div>etc.</div>
                <div>p.m.</div>
                <div>PS</div>
                <div>RSVP</div>
            </div>
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page23/E2/1.jpg"></div>
              <div style=" position: absolute; top: 164px; left: 276px; "><input id='0' /></div>
              <div style=" position: absolute; top: 192px; left: 112px; "><input id='1' /></div>
              <div style=" position: absolute; top: 282px; left: 205px; "><input id='2' /></div>
              <div style=" position: absolute; top: 347px; left: 63px; "><input id='3' /></div>
              <div style=" position: absolute; top: 643px; left: 261px; "><input id='4' /></div>
            </div>
      `,
        answer: ["p.m.", "e.g.", "RSVP", "P.S.", "etc."],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P23-E3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    inputSize: 180,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: "Write an invitation to a party. Include these points:",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
              <ul>
                <li>Give the reason, date, time and place of the party</li>
                <li>Say what activities you are planning and what the person needs to bring, wear, etc.</li>
              </ul>
              <textarea rows='6' width='800px'></textarea>

        `,
        answer: [],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P23-E4",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    inputSize: 180,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `Imagine you are Andy. Write a reply to Mike's invitation to meet up.`,
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
              <ul>
                <li>Say why you cannot meet him.</li>
                <li>Suggest another activity at a later date.</li>
              </ul>
              <textarea rows='6' width='800px'></textarea>

        `,
        answer: [],
      },
    ],
  },
};

export default json;
