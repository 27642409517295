import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P62-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E1/Key/answerKey.png",
    textAlign: "center",
    inputSize: 33,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
                Complete the tourist attractions with <i>a, e, i, o</i> and <i>u</i>.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width:900px; font-size: 26px;'>
            <div >
                <div><b>1 &ensp;</b>m#n#m#nt</div>
                <div><b>2 &ensp;</b>s#f#r# &ensp; p#rk</div>
                <div><b>3 &ensp;</b>c#rn#v#l</div>
                <div><b>4 &ensp;</b>sq##r#</div>
            </div>    
            <div >
                <div><b>5 &ensp;</b>r#st##r#nt</div>
                <div><b>6 &ensp;</b>sh#pp#ng &ensp; d#str#ct</div>
                <div><b>7 &ensp;</b>#p#r#h##s#</div>
            </div>    
          </div>    
        `,
        answer: [
          "o",
          "u",
          "e",
          "a",
          "a",
          "i",
          "a",
          "a",
          "i",
          "a",
          "u",
          "a",
          "e",
          "e",
          "a",
          "u",
          "a",
          "o",
          "i",
          "i",
          "i",
          "o",
          "e",
          "a",
          "o",
          "u",
          "e",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P62-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E2/Key/answerKey.png",
    textAlign: "left",
    inputSize: 400,
    titleQuestion: [
      {
        num: "2",
        title: `
				Put the phrases into the correct groups.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
					<div style='color: rgb(25, 158, 86);'>
						Do you fancy … ? &emsp; Either suggestion is fine by me. <br/>
						I don't mind. &emsp; I don't think that's a great idea. <br/>
						I'd rather not. &emsp; I'm not sure about that. <br/>
						I'm not very keen on that idea. &emsp; Let's do that.  <br/>
						That's a really good plan. <br/>
						They're both good ideas. &emsp; We could always … &emsp; Let's … <br/>
						What a good idea!  <br/>
					</div>

				<b>Making suggestions</b>
				<div><b>&ensp;1 &ensp;</b>#</div>
				<div><b>&ensp;2 &ensp;</b>#</div>
				<div><b>&ensp;3 &ensp;</b>#</div>
				<b>Accepting a suggestion</b>
				<div><b>&ensp;4 &ensp;</b>#</div>
				<div><b>&ensp;5 &ensp;</b>#</div>
				<div><b>&ensp;6 &ensp;</b>#</div>
				<b>Declining a suggestion</b>
				<div><b>&ensp;7 &ensp;</b>#</div>
				<div><b>&ensp;8 &ensp;</b>#</div>
				<div><b>&ensp;9 &ensp;</b>#</div>
				<div><b>10 &ensp;</b>#</div>
				<b>Expressing no preference</b>
				<div><b>11 &ensp;</b>#</div>
				<div><b>12 &ensp;</b>#</div>
				<div><b>13 &ensp;</b>#</div>
        `,
        answer: [
          "Do you fancy ...?",
          "We could always ... ",
          "Let's ... ",
          "Let's do that. ",
          "That's a really good plan",
          "What a good idea. ",
          " I don't think that's a great idea.",
          "I'd rather not. ",
          " I'm not sure about that.",
          " I'm not very keen on that idea.",
          "Either suggestion is fine by me.",
          "I don't mind. ",
          "They're both good ideas.",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 7",
    id: "WB10-U7-P62-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E3/key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with the words below. 
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 100,
    textAlign: "center",
    maxLength: 15,
    titleImage: "",
    questions: [
      {
        title: `
					<div style='color: rgb(25, 158, 86);'><b>
						about&emsp;
						buy&emsp;
						nice&emsp;
						park&emsp;
						repeat&emsp;
						<br/>
						say&emsp;
						town&emsp;
						walk&emsp;
						worth&emsp;
					</b></div>
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>The nicest restaurant is in the # centre. </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>What # the hotel near the station?</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>There's a # little hotel in the Old Town.</div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>It's near the cathedral, which is also # a visit. </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>Sorry, did you # ‘Indian restaurant'? </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>6</b>
          <div>Could you # that, please? </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>7</b>
          <div>The cathedral near the # is very historic and atmospheric. </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>8</b>
          <div>We can # or we can take the tram. </div>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>9</b>
          <div>We could always # a travel card. </div>
        </div>
			  `,
        answer: [
          "town",
          "about",
          "nice",
          "worth",
          "say",
          "repeat",
          "park",
          "walk",
          "buy",
        ],
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-62-E4",
    audio: "/Audios/2.06.mp3",
    video: "",
    exerciseKey: "/img/FriendsPlus/Page62/E4/key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone name='2.06'></headphone> Listen to two people discussing a trip to a city. Choose the correct answers.
        `,
        color: "black",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "more_expensive / cheaper",
          "local / foreign",
          "to_buy / not_to_buy",
        ],
        answers: ["0-0", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex; '>
          <div><b>1 &emsp;</b></div>
          <div>They choose the <input id='0' type='Circle'/> hotel.</div>
        </div>
        <div style='display:flex; '>
          <div><b>2 &emsp;</b></div>
          <div>There are a number of restaurants that serve <input id='1' type='Circle'/> food.</div>
        </div>
        <div style='display:flex; '>
          <div><b>3 &emsp;</b></div>
          <div>They decide <input id='2' type='Circle'/> a travel card.</div>
        </div>
      
        `,
    },
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P62-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E5/Key/answerKey.png",
    textAlign: "left",
    inputSize: 900,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
                Read the <span style='color:rgb(0, 152, 95);'>Speaking Strategy</span> and the task below. Then make notes on each of the four points.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				
				<div style='background-color: rgb(229, 239, 230); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(0, 152, 95)'>
					<div style='padding: 15px 25px 25px 25px;' >
						<div ><b style='color:rgb(0, 152, 95) ;'>Reading Strategy</b></div>
						<div>If you don't understand something someone has said, don't panic! Ask for clarification. You can use these phrases:</div>
						<div><i style='color:rgb(97, 156, 236);'>Sorry, did you say … ? &emsp; Could you repeat that, please?</i></div>
						<div><i style='color:rgb(97, 156, 236);'>Pardon? &emsp; Sorry, what does ‘tourist attraction' mean?</i></div>
					</div>
				</div>

				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>Are there any good places to stay? How much do they cost? Where are they?</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>What interesting buildings or monuments are there to see?</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>What's the best way to get around? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>Are there any good places to eat? Type of food? Where? First recommendation: </div>
						<div>#</div>
						<div>Second recommendation:</div>
						<div>#</div>
					</div>
				</div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P62-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    textAlign: "left",
    inputSize: 900,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title: `
                Now do the task from exercise 5 using your notes.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				
				<div style='background-color: rgb(209, 222, 241); margin-top: 20px; margin-left:100px; width:700px; border-radius: 5px;'>
					<div style='padding: 15px 25px 25px 25px;' >
						<div>You are planning a trip with a friend to a famous town in your country. Discuss the trip with your friend and agree on:</div>
						<div  style='display:flex; gap:40px;'>
							<ul>
								<li>places to stay.</li>
								<li>interesting buildings, monuments, etc.</li>
							</ul>
							<ul>
								<li>ways to travel around.</li>
								<li>good restaurants.</li>
							</ul>
						</div>
					</div>
				</div>

				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>Are there any good places to stay? How much do they cost? Where are they?</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>What interesting buildings or monuments are there to see?</div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>What's the best way to get around? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>Are there any good places to eat? Type of food? Where? First recommendation: </div>
						<div>#</div>
						<div>Second recommendation:</div>
						<div>#</div>
					</div>
				</div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
