/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

function HeadPhone1({ name, src }) {
  const audio = new Audio(src);

  React.useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  return (
    <div style={{ display: "inline-block" }}>
      <img
        src="img/headphone1.jpg"
        alt=""
        style={{
          width: "70px",
          marginLeft: -10,
          cursor: "url(img/volume.png) 12 12, auto",
        }}
        onClick={() => {
          audio.play();
        }}
      />
      <span
        style={{
          fontSize: 17,
          color: "white",
          background: "black",
          marginLeft: "-42px",
        }}
      >{` ${name}`}</span>
    </div>
  );
}

export default HeadPhone1;
