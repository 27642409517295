import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {

  1: {
    // Exercise num
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '',
        title: '',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page50/E1/Key/answerKey.png',

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          "charges / costs",
          "saved / saved&ensp;up",
          "buy / spend",
          "owe / pay&ensp;for",
          "afford / spend",
          "borrow / lend",
          "loses / wastes",
          "borrow / owe",
          "charge / run&ensp;out&ensp;of",
          "borrow / lend",
          "buy / charge",
          "cost / pay&ensp;for",
        ],
        answers: [
          "0-0", "1-0", "2-0", "3-4", "4-0", "5-4", "6-4", "7-4", "8-4", "9-0", "10-0", "11-0",
        ],
        // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
        initialValue: [],
      },
      Layout: /*html */ `
      <div style="position: relative;">
        <img src="img/FriendsPlus/Page50/E1/1.png" style="width: 900px"/>
        <div style="position: absolute; top: 10px; left: 100px">
          <div style="display: flex; column-gap: 20px; font-size: 30px; font-weight: 700;">
            <span style=" color: rgb(195 9 68);">Revision: </span>
            <span>Student Book page 73</span>
          </div>
          <div style="display: flex; column-gap: 20px; font-size: 28px">
            <strong>1</strong>
            <strong>Circle the correct verbs to complete the sentences</strong>
          </div>
          <div style="padding: 10px 25px">
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">1</strong>
              <span>The restaurant <strong><input id="0" type="Circle" /></strong> $3 for water.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">2</strong>
              <span>We <strong><input id="1" type="Circle" /></strong> a lot of money by booking the flights online.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">3</strong>
              <span>If you <strong><input id="2" type="Circle" /></strong> the cinema tickets, I'll <strong><input id="3" type="Circle" /></strong> the drinks and popcorn.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">4</strong>
              <span>Don't worry – if you can't <strong><input id="4" type="Circle" /></strong> to buy lunch, I can <strong><input id="5" type="Circle" /></strong> you some money.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">5</strong>
              <span>He's always short of money because he <strong><input id="6" type="Circle" /></strong> about €5 a day on drinks and snacks.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">6</strong>
              <span>I <strong><input id="7" type="Circle" /></strong> my parents €50 from last summer.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">7</strong>
              <span>If I <strong><input id="8" type="Circle" /></strong> money, I'll <strong><input id="9" type="Circle" /></strong> some from my parents.</span>
            </div>
            <div style=" margin-top: 20px">
              <strong style="margin-right: 15px;">8</strong>
              <span>We can <strong><input id="10" type="Circle" /></strong> three books because they only <strong><input id="11" type="Circle" /></strong> €4 each.</span>
            </div>

          </div>
        </div>
      </div>
        
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E2',
    audio: 'Audios/1.20.mp3',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title: '<headphone name="1.20"></headphone>  Listen to the true story of a student in Australia. Which is the best title? Tick a, b or c.',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page50/E2/Key/answerKey.png',

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: ["a </br> b </br> c"],
        answers: ["0-0"],
        initialValue: [],
      },
      Layout: /*html */ `
        <div style="display: flex; column-gap: 20px; padding: 10px 30px">
          <strong>
            <input id="0" type="Circle" />
          </strong>
          <div>
            <div>An honest student gets a reward</div>
            <div>Crime doesn't pay</div>
            <div>How to find a job in TV </div>
          </div>
        </div>
        
      `,
    },
  },
  3: {
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E3',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: 'Read the <span style="color: rgb(195 9 68);">Listening Strategy</span> and the summary below. What kind of information do you need for each answer? Choose from the alternatives below',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 100,
      textAlign: 'center',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; flex-wrap: wrap ;column-gap: 50px; color: rgb(195 9 68); font-weight: 600 ">
            <span>an adjective to describe a feeling</span>
            <span>a country</span>
            <span>a job</span>
            <span>a number</span>
            <span>a period of time</span>
            <span>a room</span>
          </div>
          <div style="display: flex; column-gap: 20px; margin-top: 20px">
            <div style="padding: 10px 30px; width: 500px">
              <div style="border: 5px dotted rgb(195 9 68); background-color: rgb(248 227 226); ;padding: 15px; border-radius: 10px">
                  <strong style="color: rgb(195 9 68)">Listening Strategy</strong>
                  <span>
                  Before you listen, carefully read the summary and
                  think about what you need to fill each gap. Think
                  about the part of speech (noun, adjective, verb, etc.)
                  and the kind of information (a time or date, number,
                  age, place, an adjective that describes a feeling, etc.)
                  that should go in each gap.
                  </span>
              </div>
            </div>
            <div style="width: 800px; border-radius: 10px; padding: 20px; margin-top: 20px; background-image: linear-gradient(rgb(248 227 226) 55%, rgb(228 156 155);); box-shadow: 10px 15px 19px -11px;">
              <span>Mr Amarsinghe was short of money so he took a part-time job
              as <input />. He was working in <input /> on the ground floor
              of a TV company, when he found some money. At first, Mr
              Amarsinghe, who was born in <input />, thought it was a trick
              and decided not to take the money. The police arrived and
              found more than <input /> dollars. No one claimed the money
              but the police phoned Amarsinghe after <input /> and told him
              that most of the money was now his. A judge decided that
              he deserved the money for his honesty. Amarsinghe was of
              course  <input /> when he heard this, but he wasn't sure how he
              would spend the money
              </span>
            </div>
          
          </div>
          <div style="margin-top: 40px">
            <div>
              <strong>1</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
            <div>
              <strong>2</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
            <div>
              <strong>3</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
            <div>
              <strong>4</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
            <div>
              <strong>5</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
            <div>
              <strong>6</strong>
              <span style="margin-left: 20px">The information needed is <input width="350px" /></span>
            </div>
          </div>
          `,
        answer: [
          "a job",
          "a room",
          "a country",
          "a number",
          "a period of time",
          "an adjective to describe a feeling",
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E4',
    audio: 'Audios/1.20.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: '<headphone name="1.20"></headphone>  Listen again and write the correct words to complete the gaps in the summary in exercise 3. Use between 1 and 3 words for each gap.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 600,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E4/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <input /> 
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <input /> 
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <input /> 
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <input /> 
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>5</strong>
            <input /> 
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>6</strong>
            <input /> 
          </div>
          `,
        answer: [
          "a cleaner",
          "a bathroom",
          "New Zealand",
          "100,000",
          "three years",
          "delighted",
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E5',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title: 'Read the questions and think about the kind of information you need for the answers to the questions.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 150,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E5/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <span>Where is the apartment that speaker 1 mentions?<input /></span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <span>How much did speaker 2 spend on cosmetics?<input /></span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <span>How long has speaker 3 had his job?<input /></span>
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <span>What is speaker 4's hobby?<input /></span>
          </div>
          
          `,
        answer: [
          "a place",
          "a number",
          "a period of time",
          "a thing",
        ],
      },
    ],
  },
  6: {
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E6',
    audio: 'Audios/1.21.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: '<headphone name="1.21"></headphone> Listen to four different speakers and answer the questions in exercise 5.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 500,
      textAlign: 'left',
      borderBottom: '2px solid',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E6/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>1</strong>
            <div>
              <input />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>2</strong>
            <div>
              <input />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>3</strong>
            <div>
              <input />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>4</strong>
            <div>
              <input />
            </div>  
          </div>
          
          
          `,
        answer: [
          "The apartment is in Spain",
          "She spent nearly one hundred pounds",
          "He's had his job for three years/He is had his job for three years",
          "His hobby is cycling",
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 6',
    id: 'WB10-U6-P50-E7',
    audio: 'Audios/1.21.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '7',
        title: '<headphone name="1.21"></headphone> Listen again and match speakers 1–4 with sentences A–E. There is one extra sentence.',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      width: 36,
      textAlign: 'left',
      border: '1px solid',
      borderRadius: 5,
      backgroundColor: 'transparent',
    },
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E7/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <strong>A</strong>
            <div style="display: flex; align-items: center; column-gap: 10px">
              <span>asked for a pay rise at work, but did not get it.</span>
              <input id="0" />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>B</strong>
            <div style="display: flex; align-items: center; column-gap: 10px">
              <span>borrowed money for a holiday, but did not go.</span>
              <input id="1" />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>C</strong>
            <div style="display: flex; align-items: center; column-gap: 10px">
              <span>receives gifts of money, but never spends it.</span>
              <input id="2" />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>D</strong>
            <div style="display: flex; align-items: center; column-gap: 10px">
              <span>tried to raise money for charity, but did not get much.</span>
              <input id="3" />
            </div>  
          </div>
          <div style="display: flex; column-gap: 20px">
            <strong>E</strong>
            <div style="display: flex; align-items: center; column-gap: 10px">
              <span>tried to get a refund in a shop, but failed.</span>
              <input id="4" />
            </div>  
          </div>
          <div style="display: flex; align-items: center; column-gap: 10px; margin-top: 15px">
              <strong>The extra sentence is </strong>
              <input id="5" />
            </div> 
          
          `,
        answer: [
          "3", //A
          "1", //B
          "4", //C
          " ", //D
          "2", //E
          "D", //
        ],
      },
    ],
  },
}
export default json;
