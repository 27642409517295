import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: {
		unit: 'Unit 6',
		id: 'WB10-U6-P55-E1',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '1',
				title: 'Read the task and the essay. Are the sentences true (T) or false (F)?',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 20,
			width: 35,
			textAlign: 'center',
			border: '2px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page55/E1/Key/answerKey.png',
		isHiddenCheck: true,
		questions: [
			{
				title: /*html */`
					<div style="display: flex; column-gap: 20px">
						<strong>1</strong>
						<span>Sarah wants a sound system because she hasn't got
						anything for listening to music. <input /></span>
					</div>
					<div style="display: flex; column-gap: 20px">
						<strong>2</strong>
						<span>Her brother loves football.<input /></span>
					</div>
					<div style="display: flex; column-gap: 20px">
						<strong>3</strong>
						<span>She'd give money to an organisation that helps homeless
						people.<input /></span>
					</div>
					<div style="display: flex; column-gap: 20px">
						<strong>4</strong>
						<span>She'd spend most of the money. <input /></span>
					</div>

						<div style="background-color: rgb(209 222 241); padding: 15px; ">
						<strong>If you won €1 million in the lottery, what would you do
						with it? Write an essay and include your ideas. Give reasons
						for your choices.</strong>
						</div>
						<div>
							<img src="img/FriendsPlus/Page55/E1/1.png" />
						</div>
				`,
				answer: [
					"F",
					"T",
					"T",
					"F",
				],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: 'WB10-U6-P55-E2',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page55/E2/Key/answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: 'Read the  <span style="color:rgb(62 63 153)"> Writing Strategy</span>. Then underline the following in the essay:',
				color: "#000000"
			}
		],
		question: {
		Write: {
			inputStyle: { width: 70 },
			answers: [],
			initialValue: [],
		},
		Circle: {
			// initialWordStyle: { border: 'none', borderRadius: '50%', borderColor: 'transparent' },
			selectWordStyle: { padding: 5, textDecorationLine: "underline", fontWeight: 'bold', borderWidth: 2},
			// limitSelect: 1,
			listWords: [
			`If&ensp;I&ensp;won&ensp;€1&ensp;million, there&ensp;are&ensp;a&ensp;lot&ensp;of&ensp;things&ensp;I&ensp;would&ensp;like&ensp;to&ensp;do&ensp;with&ensp;the&ensp;money. 
			It&ensp;would&ensp;be&ensp;nice&ensp;to&ensp;buy&ensp;myself&ensp;something, but&ensp;it&ensp;would&ensp;also&ensp;be&ensp;good&ensp;to&ensp;help&ensp;others. <br/>
			First&ensp;of&ensp;all, I&ensp;would&ensp;buy&ensp;myself&ensp;a&ensp;docking&ensp;station&ensp;with&ensp;fantastic&ensp;speakers. 
			Although&ensp;I&ensp;have&ensp;got&ensp;an&ensp;MP3&ensp;player&ensp;and&ensp;some&ensp;speakers, they&ensp;are&ensp;not&ensp;very&ensp;good. <br/>
			Secondly, I&ensp;would&ensp;buy&ensp;a&ensp;new&ensp;car&ensp;for&ensp;my&ensp;mum. 
			Her&ensp;car&ensp;is&ensp;really&ensp;old. 
			I&ensp;would&ensp;buy&ensp;my&ensp;brother&ensp;a&ensp;Liverpool&ensp;kit&ensp;and&ensp;a&ensp;scarf. 
			He&ensp;is&ensp;a&ensp;really&ensp;big&ensp;Liverpool&ensp;fan! <br/>
			Thirdly, there&ensp;are&ensp;a&ensp;lot&ensp;of&ensp;homeless&ensp;people&ensp;in&ensp;my&ensp;city.
			I&ensp;would&ensp;donate&ensp;money&ensp;to&ensp;the&ensp;local&ensp;hostel. 
			If&ensp;I&ensp;gave&ensp;them&ensp;€5,000, they&ensp;could&ensp;buy a&ensp;lot&ensp;of&ensp;meals&ensp;for&ensp;homeless&ensp;people. <br/>
			Finally, I&ensp;would&ensp;save&ensp;at&ensp;least&ensp;half&ensp;of&ensp;the&ensp;money. 
			In&ensp;my&ensp;opinion, it&ensp;would&ensp;be&ensp;silly&ensp;to&ensp;spend&ensp;it&ensp;all&ensp;now. 
			What&ensp;is&ensp;more, if&ensp;I&ensp;invested&ensp;the&ensp;money, it&ensp;would&ensp;grow, and&ensp;I&ensp;would&ensp;have&ensp;more&ensp;to&ensp;spend&ensp;in&ensp;the&ensp;future.<br/>
			To&ensp;sum&ensp;up, I&ensp;would&ensp;buy&ensp;something&ensp;nice&ensp;for&ensp;myself&ensp;and&ensp;other&ensp;members&ensp;of&ensp;my&ensp;family, support&ensp;local&ensp;charities, and&ensp;also&ensp;save&ensp;some&ensp;money&ensp;for&ensp;the&ensp;future.`
			],
			answers: [ 
				"0-0",
				"0-2",
				"0-30",
				"0-90",
				"0-92",
				"0-124",
				"0-126",
				"0-112",
				"0-20",
				"0-40",
				"0-74",
				"0-102",
				"0-122",
		],
        initialValue: [],
      },
      Layout:  /*html */
	  `
					
				<div style="background-color: rgb(209 222 241); padding: 10px 20px; border-radius: 5px; margin-top: 20px;">
					<span style="font-weight: 600; font-size: 20px">If you won €1 million in the lottery, what would you do
					with it? Write an essay and include your ideas. Give reasons
					for your choices.</span>
				</div>
				<div style="display: flex; column-gap: 20px; margin-top: 20px">
					<div>
						<div style="position: relative; width: 700px">
							<img src="img/FriendsPlus/Page55/E2/1.png" style="width: 100%" />
							<div style="position: absolute; top: 30px; left: 50px; font-size: 18px; width: 530px">
	  							<input id="0" type="Circle" />
							</div>
						</div>
						<div>
							<div style="background-color:rgb(232 238 248) ; border: 5px dotted rgb(39 131 197); width: 580px; margin: 20px 0 0 10px; height: 320px; border-radius: 20px; padding: 10px 20px">
								<strong style="color: rgb(39 131 197); font-size: 25px">Writing Strategy</strong>
								<div style="font-size: 18px">
									<div>In your writing, avoid starting too many sentences
									with the same words. Remember that you can:</div>
									<div>
										<ul>
											<li>use a variety of phrases for introducing opinions
											(It seems to me …, In my opinion …, etc.) and for
											making additional points (Moreover …, Furthermore
											…, etc.).</li>
											<li>start conditional sentences with the if clause or the
											main clause</li>
											<li>use concession clauses (Although …, Even though …)
											at the beginning or end of a sentence.</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div style="font-size: 20px; padding: 10px 20px">
							<div>
								<strong>1</strong>
								<span style="margin-left: 20px">one concession clause (starting with Even though, Although, etc.)</span>

							</div>
							<div>
								<strong>2</strong>
								<span style="margin-left: 20px">three second conditional sentences (with an if clause and a main clause) </span>

							</div>
							<div>
								<strong>3</strong>
								<span style="margin-left: 20px">one phrase for introducing an opinion</span>

							</div>
							<div>
								<strong>4</strong>
								<span style="margin-left: 20px">four phrases for listing four points</span>

							</div>
							<div>
								<strong>5</strong>
								<span style="margin-left: 20px">one phrase for introducing an additional point</span>

							</div>
						</div>
					</div>
				</div>
					`,
			}
	},
	3: {
		unit: 'Unit 6',
		id: 'WB10-U6-P55-E3',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '3',
				title: 'Read the task in exercise 1 again. Make notes for your answer.',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 20,
			width: 500,
			textAlign: 'left',
			borderBottom: '1px solid',
			backgroundColor: 'transparent',
		},
		// maxLength: 1,
		textareaStyle: { width: 500 },
		exerciseKey: 'img/FriendsPlus/Page55/E1/Key/answerKey.png',
		isHiddenCheck: true,
		hideBtnFooter: true,
		questions: [
			{
				title: /*html */`
				<div style="display: flex; column-gap: 20px;">
					<div>
						<div style=" font-size: 20px">Introduction:</div>
						<textarea></textarea>
					</div>
					
					<div style=" font-size: 20px">
						<div>First idea:</div>
						<input />
					</div>
				</div>
				<div style="display: flex; column-gap: 20px;">
					<div>
						<div style=" font-size: 20px">Reasons:</div>
						<textarea></textarea>
					</div>
					<div>
						<div style=" font-size: 20px">Second idea:</div>
						<input />
					</div>
				</div>
				<div style="display: flex; column-gap: 20px;">
					<div>
						<div style=" font-size: 20px">Reasons:</div>
						<textarea></textarea>
					</div>
					<div>
						<div style=" font-size: 20px">Third idea:</div>
						<input />
					</div>
				</div>
				<div style="display: flex; column-gap: 20px;">
					<div>
						<div style=" font-size: 20px">Reasons:</div>
						<textarea></textarea>
					</div>
					<div>
						<div style=" font-size: 20px">Fourth idea:</div>
						<input />
					</div>
				</div>
				<div style="display: flex; column-gap: 20px;">
					<div>
						<div style=" font-size: 20px">Reasons:</div>
						<textarea></textarea>
					</div>
					<div>
						<div style=" font-size: 20px">Conclusion</div>
						<input />
					</div>
				</div>
				`,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Unit 6',
		id: 'WB10-U6-P55-E4',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '',
				title: '<span style="color: rgb(39 131 197); font-size: 30px; font-weight: 800">Writing Guide</span><br /> <span>4</span> Use your notes from exercise 3 to write an essay. Include an introduction and a conclusion.',
				color: "#000000"
			}
		],
		stylesTextInput: {
			fontSize: 20,
			width: 35,
			textAlign: 'center',
			border: '2px solid',
			borderRadius: 5,
			backgroundColor: 'transparent',
		},
		maxLength: 1,
		exerciseKey: '',
		isHiddenCheck: true,
		hideBtnFooter: true,
		questions: [
			{
				title: /*html */`
				<div style="position: relative; margin-top:50px">
					<img src="img/FriendsPlus/Page55/E4/1.png"/>
					<div style="position: absolute; top: 36px; left: 97px">
						<div style="font-size: 25px; font-weight: 800; color: rgb(39 131 197)">CHECK YOUR WORK</div>
						<div style="font-size: 23px; font-weight: 800;">Have you ...</div>
						<div style="    margin-top: 20px; font-size: 23px">
							<span>
								<input />
								used a variety of different ways to begin sentences?
							</span>
						</div>
						<div>
							<span>
								<input />
								checked your spelling and grammar?
							</span>
						</div>
					</div>
				</div>
				`,
				answer: [],
			},
		],
	},
}


export default json;