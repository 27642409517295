import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import { BsArrowUpLeft } from "react-icons/bs";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: "Cumulative",
    id: "FG10-C-P76-E1",
    audio: "Audios/2.13.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page76/E1/Key/answerKey.png",
    component: T6,
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 64,
      textAlign: "center",
      border: "2px solid black",
    },
    selectOptionValues: ["1", "2", "3", "4",],
    titleQuestion: [
      {
        num: "1",
        title: `
            <headphone name="2.13"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
             Listen and match conversations 1–4 with
            contexts a–d.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div style='display:flex; gap:15px;'>
            <b>a</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 350px;'>
                <div>a job interview</div>
                <select id='0'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>b</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 350px;'>
                <div>a talk </div>
                <select id='1'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>c</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 350px;'>
                <div>a job advert </div>
                <select id='2'></select>
            </div>
          </div>
          <div style='display:flex; gap:15px;'>
            <b>d</b>
            <div style='display:flex; gap:15px; justify-content: space-between;width: 350px;'>
                <div>an informal conversation</div>
                <select id='3'></select>
            </div>
          </div>
            `,
        answer: ["4", "1", "3", "2"],
      },
    ],
  },

  2: {
    unit: "Cumulative",
    id: "FG10-C-P76-E2",
    audio: "Audios/2.13.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page76/E2/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
            <headphone name="2.13"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
             Listen again and choose the best answers.
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "a <br/> b <br/> c",
          "a <br/> b <br/> c",
          "a <br/> b <br/> c",
          "a <br/> b <br/> c",
        ],
        answers: ['0-4', '1-8', '2-4', '3-0'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div>Which information should you always include on a CV?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='0' type='Circle' /></div>
              <div>
                <div>Your date of birth</div>
                <div>Your phone number</div>
                <div>Your place of birth</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div>What does Paul like about his job?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='1' type='Circle' /></div>
              <div>
                <div>It’s challenging.</div>
                <div>He loves travelling.</div>
                <div>It’s very varied.</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div>What do applicants need for the job?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='2' type='Circle' /></div>
              <div>
                <div>Communication skills</div>
                <div>Experience</div>
                <div>Special qualifications</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div>Why did Annabel apply for the job?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='3' type='Circle' /></div>
              <div>
                <div>She wants to do something more challenging.</div>
                <div>She wants to earn more money.</div>
                <div>She wants to work with some friends.</div>
              </div>
            </div>
          </div>
        </div>

      `,
    },
  },

  3: {
    unit: "Cumulative",
    id: "FG10-C-P76-E3",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    inputSize: 50,
    maxLength: 1,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
            <span style="color: rgb(0, 110, 147)"> SPEAKING</span> 
             Work in pairs. Look at the photo and answer the questions.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ol type="1">
                  <li>How do you think the people are feeling?</li>
                  <li>Which would you prefer: a face-to-face job interview or
                  an interview by phone?</li>
                  <li>How would you describe the last time you had to make a
                  good impression?</li>
                </ol>
                <img src="img/FriendsPlus/Page76/E3/1.jpg" alt="" width=50%/>
              </div>
            `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Cumulative",
    id: "FG10-C-P76-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page76/E4/Key/answerKey.png",
    component: T6,
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 110,
      textAlign: "center",
      border: "2px solid black",
    },
    selectOptionValues: ["NOT", "1", "2", "3", "4", "5", ],
    titleQuestion: [
      {
        num: "4",
        title: `
            <span style="color: rgb(0, 110, 147)"> READING</span> 
             Read the article about a job at a chocolate company. Match
            sentences A–F with gaps 1–5 in the article. There is one
            extra sentence.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div style="background-image: url('img/FriendsPlus/Page76/E4/1.jpg'); background-repeat: no-repeat; background-size: 1360px 1310px; width:1360px; border-radius: 16px">
                <div style="padding: 570px 170px 10px 230px;">
                      Are you out of work? Do you love chocolate?
                      Then you might be interested in an advertisement that
                      appeared last week in cinemas all over the country. <b><sup>1</sup></b>___<br/>
                      The successful candidate will have to travel abroad
                      regularly to look for the best ingredients for the company's
                      products. <b><sup>2</sup></b>___ The best part of the job will be testing
                      the new products, which will be done, of course, by the
                      Taste Assistant.<br/>
                      No formal qualifications are needed for the job, but there
                      is one rather special requirement. <b><sup>3</sup></b>___ The company
                      is looking for someone with ‘a good nose'. They have no
                      preference over men or women for the job, and age is not
                      important. According to experts, however, women are
                      usually more sensitive to taste than men. They also say that
                      we lose our sense of taste as we get older. For this reason,
                      the successful applicant is likely to be young and female.<br/>
                      The first stage in the selection process will be an interview.
                      <b><sup>4</sup></b>___ Here, they must identify the flavours of
                      chocolate bars that the company has prepared
                      specially. They won't be able to see the
                      chocolate that they have to taste. The final stage
                      will be a live ‘Taste Challenge' in which the five
                      best candidates will take part. The prize for the
                      winner will be the Taste Assistant job.<br/>
                      If you are interested in the post, you need to
                      send in a CV with a covering letter. <b><sup>5</sup></b>___ The
                      company will hold interviews in the first week of
                      January, and the successful candidate will start
                      their new job early next year.
                </div>
              </div>

                <div style="margin-left: 200px;margin-top: 20px;">
                  <div style='display:flex; gap:15px;'>
                    <b>A</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>Applicants need an excellent sense of taste and smell.</div>
                        <select id='0'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>B</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>The closing date for applications is 16 December.</div>
                        <select id='1'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>C</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>If candidates are successful, they will go on to do a blind taste test.</div>
                        <select id='2'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>D</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>A major chocolate company is looking for a new Taste Assistant for their UK office.</div>
                        <select id='3'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>E</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>Many people eat too quickly to pay attention to what they are eating.</div>
                        <select id='4'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>F</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>The rest of the time will be spent at the company’s headquarters experimenting with different flavours.</div>
                        <select id='5'></select>
                    </div>
                  </div>
                </div>
            `,
        answer: ["3", "5", "4", "1", "NOT","2"],
      },
    ],
  },
};;

export default json;

