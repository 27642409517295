import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P21-E1",
    component: T6,
    inputSize: 130,
    stylesTextInput: { border: "none", fontSize: 21 },
    exerciseKey: "/img/FriendsPlus/Page21/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          " Match the pairs of adjectives with similar meanings. Put them in the correct columns.",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style="display: flex; align-items: start;">
            <div style="color: rgb(62, 63, 153); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 600px; line-height: 10px; margin: 15px">
                <div><s>astonishing</s></div>
                <div>bad</div>
                <div>big</div>
                <div>disgusting</div>
                <div>exhausted</div>
                <div>filthy</div>
                <div>good</div>
                <div>hungry</div>
                <div>sad</div>
                <div>stunning</div>
                <div>tasty</div>
                <div>terrifying</div>
                <div>tiny</div>
            </div>

            <div style="font-size: 21px;">
              <table style="border-collapse: collapse;">
              <thead>
                <tr style="border: 1px solid rgb(62, 63, 153); background-color: rgb(62, 63, 153); color: white;">
                  <th style="border: 1px solid;padding: 8px">Verb</th>
                  <th style="border: 1px solid ; padding: 8px">Nouns </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>surprising</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>1</sup><span style="color:rgb(0, 68, 98)">astonishing</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>2</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>delicious</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>beautiful</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>3</sup>#
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>4</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>awful</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>unpleasant</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>5</sup>#
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>6</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>starving</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>small</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>7</sup>#
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>8</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>brilliant</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>frightening</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>9</sup>#
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>10</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>tragic</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>dirty</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>11</sup>#
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>12</sup>#
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>huge</span>
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <span>tired</span>
                  </td>
                  <td style="border: 1px solid rgb(62, 63, 153); padding: 8px;">
                    <sup>13</sup>#
                  </td>
                </tr>
                </tbody> 
              </table>
            </div>
            </div>
                `,
        answer: [
          "tasty ",
          "stunning ",
          "bad ",
          "disgusting ",
          "hungry ",
          "tiny ",
          "good ",
          "terrifying ",
          "sad ",
          "filthy ",
          "big ",
          "exhausted",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P21-E2",
    component: T6,
    stylesTextInput: {
      backgroundColor: "transparent",
      borderBottom: "1px solid",
      padding: 10,
      fontSize: 20,
      textAlign: "center",
    },
    exerciseKey: "img/FriendsPlus/Page21/E2/Key/answerKey.png",
    inputSize: 120,
    maxLength: 10,
    isHiddenCheck: true,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title: "Read the text. Find five extreme adjectives from exercise 1.",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
            <div> # # # # #</div>
            <div><img style="width: 90%; margin-top: 10px" src="/img/FriendsPlus/Page21/E2/1.jpg"></div>
        `,
        answer: [
          "awful/huge/starving/exhausted/terrifying",
          "awful/huge/starving/exhausted/terrifying",
          "awful/huge/starving/exhausted/terrifying",
          "awful/huge/starving/exhausted/terrifying",
          "awful/huge/starving/exhausted/terrifying",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P21-E3",
    component: T6,
    stylesTextInput: {
      backgroundColor: "transparent",
      border: "none",
      padding: 10,
      fontSize: 20,
      textAlign: "center",
      textTransform: "uppercase",
    },
    exerciseKey: "img/FriendsPlus/Page21/E3/Key/answerKey.png",
    inputSize: 40,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          'Read the <span style="color: rgb(62, 63, 153)">Reading Strategy</span>. Match sentences A–G with gaps 1–5 in the text. There are two extra sentences',
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
              <div style="font-size: 22px">
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>A </b></div><div style="margin-left: 30px; flex: 1; "> During this time, Steven saw nine ships.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>B </b></div><div style="margin-left: 30px; flex: 1; "> Water was coming in and the boat was sinking fast.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>C </b></div><div style="margin-left: 30px; flex: 1; "> But he couldn't swim back to the yacht.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>D </b></div><div style="margin-left: 30px; flex: 1; "> It contained lots of useful information about surviving at sea.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>E </b></div><div style="margin-left: 30px; flex: 1; "> He was planning to sail to the Caribbean.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>F </b></div><div style="margin-left: 30px; flex: 1; "> Some men were fishing near Steven's raft.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>G </b></div><div style="margin-left: 30px; flex: 1; "> Alone in the life raft, Steven travelled slowly across the Atlantic Ocean.</div></div>
              </div>
              <div style="position: relative;">
                <img style=" margin-top: 10px" src="/img/FriendsPlus/Page21/E2/1.jpg">
                <div style=" position: absolute; top: 190px; left: 87px; "><input id='0' /></div>
                <div style=" position: absolute; top: 216px; left: 377px; "><input id='1' /></div>
                <div style=" position: absolute; top: 324px; left: 87px; "><input id='2' /></div>
                <div style=" position: absolute; top: 357px; left: 87px; "><input id='3' /></div>
                <div style=" position: absolute; top: 442px; left: 300px; "><input id='4' /></div>
              </div>
        `,
        answer: ["E ", "B ", "D ", "G ", "F"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P21-E4",
    component: T6,
    stylesTextInput: {
      backgroundColor: "transparent",
      border: "1px solid",
      borderRadius: "7px",
      padding: 10,
      fontSize: 20,
      textAlign: "center",
      textTransform: "uppercase",
    },
    exerciseKey: "img/FriendsPlus/Page21/E4/Key/answerKey.png",
    inputSize: 35,
    maxLength: 1,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Read the text again, including the missing sentences. Are the sentences true (T) or false (F)? Write the paragraph (A–E) which contains the answer.",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
              <div style="font-size: 22px">
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">Steven wanted to sail to the Canary Islands. T or F? # Paragraph? #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">Steven's nightmare started four days into the trip. T or F? # Paragraph? #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">Steven managed to get some useful things from the sinking yacht. T or F? # Paragraph? #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">Steven ate birds and fish in order to survive. T or F? # Paragraph? # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">Steven didn't reach the Caribbean. T or F? # Paragraph? #  </div></div>
              </div>
              <img style="width:90%; margin-top: 10px" src="/img/FriendsPlus/Page21/E2/1.jpg">
        `,
        answer: ["F", "A ", "F", "A ", "T", "B ", "T", "C ", "F", "E"],
      },
    ],
  },
};

export default json;
