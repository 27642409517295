import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import T6v2 from '../../components/ExcerciseTypes/TypeIn/T6v2'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P33-E1',
		audio: '',
		video: '',
		component: T6v2,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			border: 'none',
			padding: 0,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page33/E1/Key/answerKey.png',
		inputSize: 280,
		titleQuestion: [
			{
				num: '1',
				title: 'Complete the table with the comparative forms.',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
        <div style="position: relative; margin: 0px; ">
            <div><img src='img/FriendsPlus/Page33/E1/1.jpg' style='width:650px;'/></div>
            <div >
              <div style=" position: absolute; top: 88px; left: 348px; "><input id='0' /></div>
              <div style=" position: absolute; top: 130px; left: 348px; "><input id='1' /></div>
              <div style=" position: absolute; top: 172px; left: 348px; "><input id='2' /></div>
              <div style=" position: absolute; top: 214px; left: 348px; "><input id='3' /></div>
              <div style=" position: absolute; top: 299px; left: 348px; "><input id='4' /></div>
              <div style=" position: absolute; top: 340px; left: 348px; "><input id='5' /></div>
              <div style=" position: absolute; top: 424px; left: 348px; "><input id='6' /></div>
              <div style=" position: absolute; top: 466px; left: 348px; "><input id='7' /></div>
              <div style=" position: absolute; top: 508px; left: 348px; "><input id='8' /></div>
              <div style=" position: absolute; top: 593px; left: 353px; "><input id='9' /></div>
              <div style=" position: absolute; top: 635px; left: 353px; "><input id='10' /></div>
              <div style=" position: absolute; top: 677px; left: 353px; "><input id='11' /></div>
            </div>
          </div>
        `,
				answer: [
					'taller ',
					'larger ',
					'hotter ',
					'earlier ',
					'more powerful',
					'more spectacular',
					'better  ',
					'worse ',
					'further / farther | farther / further',
					'fewer ',
					'more',
					'less ',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P33-E2',
		audio: '',
		video: '',
		component: T6v2,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid gray',
			fontSize: 18,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page33/E2/Key/answerKey.png',
		inputSize: 150,
		titleQuestion: [
			{
				num: '2',
				title:
					'Complete the fact file. Use the comparative form of the words in brackets',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
					<div style="position: relative; font-size: 18px;">
						<div><img src="img/FriendsPlus/Page33/E2/1.jpg" style="max-width: 43%; height: auto;" /></div>

						<div style="position: absolute; top: 173px; left: 377px; display: flex; flex-direction: column; gap: 9px; background: rgb(254, 228, 201); padding: 10px; border-radius: 20px;">
							<div style="display: flex;">
								<div><img src="img/FriendsPlus/Page33/E2/list-item-marker.jpg" style="max-width: 50%; height: auto;" /></div>
								<div style="margin-left: -15px; max-width: 700px;">
									Comets are made of ice and pieces of rock. Asteroids are made of rock and metal, and are <sup>1</sup># (heavy).
								</div>
							</div>
							<div style="display: flex;">
								<div><img src="img/FriendsPlus/Page33/E2/list-item-marker.jpg" style="max-width: 50%; height: auto;" /></div>
								<div style="margin-left: -15px; max-width: 700px;">
									Most asteroids are much <sup>2</sup># (big) than most comets.
								</div>
							</div>
							<div style="display: flex;">
								<div><img src="img/FriendsPlus/Page33/E2/list-item-marker.jpg" style="max-width: 50%; height: auto;" /></div>
								<div style="margin-left: -15px; max-width: 700px;">
									You can find asteroids <sup>3</sup># (close) to the sun than comets. If comets get too close to the sun, they melt. So you can only find comets much <sup>4</sup># (far) from the sun, where it is <sup>5</sup># (cold) and ice doesn’t melt.
								</div>
							</div>
							<div style="display: flex;">
								<div><img src="img/FriendsPlus/Page33/E2/list-item-marker.jpg" style="max-width: 50%; height: auto;" /></div>
								<div style="margin-left: -15px; max-width: 700px;">
									Comets are <sup>6</sup># (bright) than asteroids because they have long, white tails.
								</div>
							</div>
							<div style="display: flex;">
								<div><img src="img/FriendsPlus/Page33/E2/list-item-marker.jpg" style="max-width: 50%; height: auto;" /></div>
								<div style="margin-left: -15px; max-width: 700px;">
									Comets are <sup>7</sup># (rare) than asteroids. There are hundreds of millions of asteroids in our solar system, but far <sup>8</sup># (few) comets – only about 4,000. For this reason, asteroids are <sup>9</sup># (dangerous), as one of them is <sup>10</sup># (likely) to hit the Earth.
								</div>
							</div>
						</div>
					</div>
        `,
				answer: [
					'heavier ',
					'bigger ',
					'closer ',
					'further / farther | farther / further',
					'colder ',
					'brighter ',
					'rarer ',
					'fewer ',
					'more dangerous',
					'more likely',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P33-E3',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page33/E3/Key/answerKey.png',
		inputSize: 500,
		titleQuestion: [
			{
				num: '3',
				title:
					'Compare the weather in the two cities. Write sentences with <i>as … as</i> and <i>not as … as</i>.',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
        <div><img src='img/FriendsPlus/Page33/E3/1.jpg' style='width:650px; margin-left: 0px;'/></div>
        <div style='display:flex; gap:15px;'>
          <div>
            <div><b>1</b></div>
            <div><b>2</b></div>
            <div><b>3</b></div>
            <div><b>4</b></div>
            <div><b>5</b></div>
            <div><b>6</b></div>
            <div><b>7</b></div>
          </div>
          <div>
            <div><i style='color:rgb(97, 156, 236);'>Edinburgh isn't as sunny as London.</i>(sunny)</div>
            <div>#(warm)</div>
            <div>#(cloudy)</div>
            <div>#(bright)</div>
            <div>#(wet)</div>
            <div>#(windy)</div>
            <div>#(dry)</div>
          </div>
        </div>

        `,
				answer: [
					'London is as warm as Edinburgh.',
					"London isn't as cloudy as Edinburgh.",
					"Edinburgh isn't as bright as London.",
					"London isn't as wet as Edinburgh.",
					'London is as windy as Edinburgh.',
					"Edinburgh isn't as dry as London.",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P33-E4',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page33/E4/Key/answerKey.png',
		inputSize: 100,
		isHiddenCheck: true,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '4',
				title:
					'Complete the sentences with <i>as, more, much</i> or <i>than</i>.',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>Mist isn't as thick # fog.</div>
        </div>

        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>The weather today is # wetter # yesterday.</div>
        </div>

        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>‘Is a hurricane # powerful than a thunderstorm?' ‘Yes, it's # stronger.</div>
        </div>

        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>The moon isn't  # bright # the sun.</div>
        </div>

        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>Are hurricanes more dangerous # tornadoes?</div>
        </div>

        <div style='display:flex; gap:15px;'>
          <b>6</b>
          <div>

            <div>‘I think maths is much # interesting # geography.'</div>
            <div>‘Do you? I don't think it's # interesting as geography, but it's # more useful.'</div>
          </div>
        </div>
        `,
				answer: [
					'as',
					'much',
					'than',
					'more',
					'much',
					'as',
					'as',
					'than',
					'more',
					'than',
					'as',
					'much',
				],
			},
		],
	},
}

export default json
