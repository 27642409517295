import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
  1: {
    unit: 'Unit 5',
    id: 'WB10-U5-P42-E1',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '',
        title: '',
        color: '#000000',
      },
    ],
    stylesTextInput: {
      fontSize: 20,
      borderBottom: '1px solid',
      textAlign: 'center',
      backgroundColor: 'transparent',
    },
    // maxLength: 1,
    width: 250,
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    isHiddenCheck: true,
    questions: [
      // Row
      {
        title: /*html */ `
        <div style="width: 1200px; display:flex ; column-gap: 50px ; align-items: stretch">
        <div style="position: relative">
          <img src="img/FriendsPlus/Page42/E1/1.png" style="width: 700px">      
          <div style="position: absolute; top: 12px; left: 47px; background-color: rgb(251 241 240); width: 642px; border-radius: 18px; height: 680px; padding: 20px">
          <div>
            <strong><span style="color: rgb(196 11 69)">Revision:</span> Student Book page 61</strong>
          </div>
          <div>
            <strong>1</strong>
            <strong style="margin-left: 20px">Look at the jobs and answer the questions</strong>
          </div>
            <div style="display: flex; align-item: center; column-gap: 30px ;flex-wrap: wrap; color: rgb(196 11 69); font-weight: 700; font-size: 20px; margin-top: 15px">
              <span>builder</span>
              <span >estate agent</span>
              <span >gardener</span>
              <span >journalist</span>
              <span >locksmith</span>
              <span >photographer</span>
              <span >pizza delivery man (woman)</span>
              <span >police officer</span>
              <span >stunt performer</span>
              <span >surfing instructor</span>
              <span >video game developer</span>
            </div>
            <div style="margin-top: 10px; font-size: 22px">
              <div>Which job is connected with …</div>
              <div>
                <strong>1</strong> 
                <span style="margin-left: 20px">flowers and plants? <input /></span>
              </div>
              <div>
                <strong>2</strong> 
                <span style="margin-left: 20px">selling houses? <input /></span>
              </div>
              <div>
                <strong>3</strong> 
                <span style="margin-left: 20px">a water sport? <input /></span>
              </div>
              <div>
                <strong>4</strong> 
                <span style="margin-left: 20px">fast food?  <input /></span>
              </div>
              <div>
                <strong>5</strong> 
                <span style="margin-left: 20px">cameras? <input /></span>
              </div>
              <div>
                <strong>6</strong> 
                <span style="margin-left: 20px">copying keys? <input /></span>
              </div>
              <div>
                <strong>7</strong> 
                <span style="margin-left: 20px">fighting crime? <input /></span>
              </div>
              <div>
                <strong>8</strong> 
                <span style="margin-left: 20px">film and TV?<input /></span>
              </div>
              <div>
                <strong>9</strong> 
                <span style="margin-left: 20px">computers and entertainment?<input /></span>
              </div>
              <div>
                <strong>10</strong> 
                <span style="margin-left: 20px">construction?<input /></span>
              </div>
              <div>
                <strong>11</strong> 
                <span style="margin-left: 20px">writing for newspapers?<input /></span>
              </div>
            </div>
          </div>

        </div>
          <div style="width: 550px; height: 300px; border:3px dotted rgb(117 6 58);background-color: rgb(249 238 237); border-radius: 10px; padding: 0.7rem">
            <span style="font-weight: 600; font-size: 22px; color: rgb(117 6 58)">Listening Strategy</span>
            </br><span style="font-size: 18px;">Some words and phrases can help you to predict
            what is coming next in a listening. For example, if
            you hear however, you know that it will be followed
            by a contrasting point. Listen out for ‘signposts' like
            these that help you to understand the structure of the
            listening.</span>
          </div>
        </div>
        
        `,
        answer: [
          'gardener',
          'estate agent',
          'surfing instructor',
          'pizza delivery man (woman)',
          'photographer',
          'locksmith',
          'police officer',
          'stunt performer',
          'video game developer',
          'builder',
          'journalist',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 5',
    id: 'WB10-U5-P42-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '2',
        title:
          'Read the <span style="color: rgb(245, 25, 10)">Listening Strategy</span>. Then choose the correct words and phrases to complete the sentences.',
        color: '#000000',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page42/E2/Key/answerKey.png',

    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
        listWords: [
          'as&ensp;I&ensp;see&ensp;it / not&ensp;only&ensp;that',
          'moreover / such&ensp;as',
          'althought / for&ensp;example',
          'as&ensp;a&ensp;result / in&ensp;spite&ensp;of&ensp;this',
          'nevertheless / that’s&ensp;because',
          'However / What&ensp;is&ensp;more',
        ],
answers: ['0-0', '1-4', '2-0', '3-4', '4-4', '5-4'],
  initialValue: [],
      },
Layout: /*html */ `
      <div >
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>1 </strong>
          <div>
            Being a surfing instructor sounds great, but 
              <strong>
                <input id='0' type='Circle' />
              </strong> 
            , it's quite repetitive.
          </div>
        </div>
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>2 </strong>
          <div>
            Stunt performers have to do a lot of dangerous things, 
              <strong>
                <input id='1' type='Circle' />
              </strong> 
              falling off buildings.
          </div>
        </div>
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>3 </strong>
          <div>
            Photographers often travel around a lot, 
              <strong>
                <input id='2' type='Circle' />
              </strong> 
              some of them work in a studio.
          </div>
        </div>
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>4 </strong>
          <div>
            Gardeners are not well paid, but 
              <strong>
                <input id='3' type='Circle' />
              </strong> 
            many of them love their job.
          </div>
        </div>
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>5 </strong>
          <div>
            Estate agents do badly during recessions, and  
              <strong>
                <input id='4' type='Circle' />
              </strong> 
            fewer people buy houses.
          </div>
        </div>
        <div style="display: flex; column-gap: 20px;margin-bottom: 15px">
          <strong>6 </strong>
          <div>
            Being a builder is hard work. 
              <strong>
                <input id='5' type='Circle' />
              </strong> 
            you're often on your feet all day.
          </div>
        </div>
      </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 5',
      id: 'WB10-U5-P42-E3',
        audio: '',
          video: '',
            component: Circle_Write,
              titleQuestion: [
                {
                  num: '3',
                  title: 'Read the sentences and choose the correct ending, a or b.',
                  color: '#000000',
                },
              ],
                exerciseKey: 'img/FriendsPlus/Page42/E3/Key/answerKey.png',

                  question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
          initialValue: [],
        },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
          listWords: ['a </br> b', 'a </br> b', 'a </br> b', 'a </br> b', 'a </br> b'],
            answers: ['0-4', '1-0', '2-4', '3-0', '4-4'],
              initialValue: [],
        },
      Layout: /*html */ `
          <div>
            <div>
              <div><strong>1 </strong>Being a sports coach isn't well paid. On the other hand,</div>
              <div style="display: flex; align-items: center; padding-left: 30px">
                <div>
                  <strong>
                    <input id="0" type="Circle" />
                  </strong>
                </div>
                <div style="margin-left: 15px">
                  <div>it can be quite repetitive</div>
                  <div>b it can be very rewarding</div>
                </div>
              </div>
              <div><strong>2 </strong>Farm workers have a physically demanding job. In other words,</div>
              <div style="display: flex; align-items: center; padding-left: 30px">
                <div>
                  <strong>
                    <input id="1" type="Circle" />
                  </strong>
                </div>
                <div style="margin-left: 15px">
                  <div>it's often very tiring.</div>
                  <div>it's often quite varied.</div>
                </div>
              </div>
              <div><strong>3 </strong>My uncle wanted to be an engineer. However,</div>
              <div style="display: flex; align-items: center; padding-left: 30px">
                <div>
                  <strong>
                    <input id="2" type="Circle" />
                  </strong>
                </div>
                <div style="margin-left: 15px">
                  <div>he worked hard and got the qualifications</div>
                  <div>he couldn't afford to get the qualifications</div>
                </div>
              </div>
              <div><strong>4 </strong>My aunt paid for her degree by getting various part-time jobs; for example,</div>
              <div style="display: flex; align-items: center; padding-left: 30px">
                <div>
                  <strong>
                    <input id="3" type="Circle" />
                  </strong>
                </div>
                <div style="margin-left: 15px">
                  <div>she worked as a cleaner every evening</div>
                  <div>she studied hard and did well in her exams</div>
                </div>
              </div>
              <div><strong>5 </strong>My grandfather worked as a police officer, a paramedic, a farm worker … What I mean is, </div>
              <div style="display: flex; align-items: center; padding-left: 30px">
                <div>
                  <strong>
                    <input id="4" type="Circle" />
                  </strong>
                </div>
                <div style="margin-left: 15px">
                  <div>gardener was the job he liked best</div>
                  <div>he did a variety of different jobs during his life.</div>
                </div>
              </div>

              
          </div>
          
        `,
      },
  },
  4: {
    // Exercise num
    unit: 'Unit 5',
      id: 'WB10-U5-P42-E4',
        audio: 'Audios/1.16.mp3',
          video: '',
            component: Circle_Write,
              titleQuestion: [
                {
                  num: '4',
                  title:
                    '<headphone name="1.16"></headphone> Listen to an interview with a female lorry driver called Sally Stone. What did she do before she became a lorry driver?',
                  color: '#000000',
                },
              ],
                exerciseKey: 'img/FriendsPlus/Page42/E4/Key/answerKey.png',

                  question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
          initialValue: [],
        },
      Circle: {
        initialWordStyle: { padding: 0, border: 'none', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, border: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 1,
          listWords: ['a </br> b </br> c'],
            answers: ['0-0'],
              // initialValue: ['3-2', '4-8', '4-10', '0-8', '1-6', '2-8', '3-6', '3-16', '5-6'],
              initialValue: [],
        },
      Layout: /*html */ `
          <div style="display: flex; column-gap: 20px">
            <div>
              <input id="0" type="Circle" />
            </div>
            <div>
              <div>She went to college.</div>
              <div>She worked as a hairdresser.</div>
              <div>She worked at her dad's company.</div>
            </div>
          </div>
          
        `,
      },
  },
  5: {
    unit: 'Unit 5',
      id: 'WB10-U5-P42-E5',
        audio: 'Audios/1.16.mp3',
          video: '',
            component: T6,
              titleQuestion: [
                {
                  num: '5',
                  title: '<headphone name="1.16"></headphone> Read the sentences below. Then listen again. Are the sentences true (T) or false (F)?',
                  color: '#000000',
                },
              ],
                stylesTextInput: {
      fontSize: 20,
        border: '2px solid',
          backgroundColor: 'transparent',
      },
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page42/E5/Key/answerKey.png',
      isHiddenCheck: true,
        questions: [
          // Row
          {
            title: /*html */ `
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>1</strong>
            <span>Sally is a qualified hairdresser</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>2</strong>
            <span>Sally decided to become a lorry driver because it is easier than hairdressing</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>3</strong>
            <span>Sally and her father work in the same job sector</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>4</strong>
            <span>Sally took the driving test only once.</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>5</strong>
            <span>Sally says most men are surprised to see a woman driving a lorry</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          <div style="display: flex; align-items: center; gap: 20px" >
            <strong>6</strong>
            <span>Sally thinks that female lorry drivers are safer than male lorry drivers.</span>
            <input width="36px"  border-radius="5px" maxlength="1" text-align="center"/>
          </div>
          
          `,
            answer: ['F', 'F', 'T', 'T', 'T', 'F'],
          },
        ],
  },
}

export default json
