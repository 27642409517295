import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E1/key/answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: `
				Match slogans 1–6 with types of product A–F.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      width: 36,
      height: 36,
      borderRadius: 5,
      borderBottom: "dash",
      textAlign: "center",
      border: "2px solid black",
      // backgroundColor: "#fee4cb",
      fontSize: 22,
    },
    maxLength: 1,

    titleImage: "",
    questions: [
      {
        title: `
					<div style='margin-top: 30px; display: flex;'>
						<div style='flex: 1; display: flex; flex-direction: column; gap: 7px;'>
							<div style='display: flex; gap: 20px;'>
								<strong>A</strong>
								<div style='min-width: 150px;'>sports clothes</div>
								#
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>B</strong>
								<div style='min-width: 150px;'>cosmetics</div>
								#
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>C</strong>
								<div style='min-width: 150px;'>credit cards</div>
								#
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>D</strong>
								<div style='min-width: 150px;'>cars</div>
								#
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>E</strong>
								<div style='min-width: 150px;'>sweets</div>
								#
							</div>
							<div style='display: flex; gap: 20px;'>
								<strong>F</strong>
								<div style='min-width: 150px;'>fast food</div>
								#
							</div>
						</div>
						<div style='flex: 2;'>
							<img src="img/FriendsPlus/Page26/E1/1.jpg" style="max-width: 100%; height: auto;" />
						</div>
					</div>
			  `,
        answer: ["2", "4", "1", "3", "5", "6"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P26-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title:
          "Read the <span style='color:rgb(199 10 69)'>Listening Strategy</span>. You are going to hear adverts for three products. Circle the word (a–c) you are most likely to hear in each advert.",
        color: "black",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c", //4
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c", //4
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; c", //4
        ],
        answers: ["2-8", "1-4", "0-4"],
        initialValue: [],
      },
      Layout: `
      <div style=' box-shadow: 2px 3px 15px gray;border: 5px dotted rgb(199 10 69);background:rgb(249 227 229); width :22cm;border-radius:20px; padding:5px 20px' >
          <b style='color:rgb(199 10 69);font-size:30px'>Listening Strategy</b>
          <div style='margin-top:10px'>
              In listening tasks, the instructions and questions usually
              give you some idea of the kind of topic and the type of
              language you are going to hear. Reading them carefully
              before you listen will help you to ‘tune in'
          </div>
      </div>

      <div style="position: relative">
        <div style='margin-top:30px;line-height:50px; display:flex'>
          <div>
              <b>A&ensp;sports clothes</b><br>
              <b>B&ensp;a car</b><br>
              <b>C&ensp;fast food</b>
          </div>
          <div style='margin-left:100px'>
            <input id='0' type='Circle' /><br>
            <input id='1' type='Circle' /><br>
            <input id='2' type='Circle' />
          </div>
        </div>


      
        <div style="line-height:50px; position: absolute; top: 0px; left: 335px; ">
          motorway<br>
          script<br>
          anxious
        </div>
        <div style="line-height:50px; position: absolute; top: 0px; left: 544px; ">
          race<br>
          engine<br>
          tired
        </div>
        <div style="line-height:50px; position: absolute; top: 0px; left: 755px; ">
           beach<br>
           station<br>hungry
        </div>
        
      </div>
      
        `,
    },
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E3",
    audio: "Audios/1.09.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E3/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,
    inputSize: 200,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "3",
        title: `
				<headphone1 name='1.09'></headphone1> Now listen to the adverts and write the products.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 14,

    titleImage: "",
    questions: [
      {
        title: `

      <div style='line-height:70px'>
        Advert 1 is for#.<br>
        Advert 2 is for#.<br>
        Advert 3 is for#.<br>
      </div>

			  `,
        answer: ["fast food", "sports clothes", "a car"],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E4",
    audio: "Audios/1.09.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E4/key/answerKey.png",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    checkDuplicated: true,
    inputSize: 120,
    titleQuestion: [
      {
        num: "4",
        title: `
				<headphone1 name='1.09'></headphone1> Listen again. Which words from exercise 2 did
        you hear?
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      textAlign: "center",
    },
    maxLength: 6,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='margin:30px 0px'>
          #&emsp;&emsp;#&emsp;&emsp;#
        </div>
			  `,
        answer: [
          "race / engine / hungry",
          "race / engine / hungry",
          "race / engine / hungry",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E5/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,
    inputSize: 200,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "5",
        title: `
				You are going to hear three dialogues. Read sentences A–D
and match each one to a phrase (1– 4) you are likely to hear.
Use the underlined words to help you.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      width: 40,
      height: 40,
      borderRadius: 5,
      borderBottom: "dash",
      textAlign: "center",
      border: "2px solid black",
      // backgroundColor: "#fee4cb",
      fontSize: 22,
    },
    maxLength: 1,

    titleImage: "",
    questions: [
      {
        title: `

        <div style="position: relative">
        <div> <img src='img/FriendsPlus/Page26/E5/1.jpg' /> </div>
          <div style=" position: absolute; top: 223px; left: 494px; ">#</div>
          <div style=" position: absolute; top: 265px; left: 494px; ">#</div>
          <div style=" position: absolute; top: 308px; left: 494px; ">#</div>
          <div style=" position: absolute; top: 350px; left: 494px; ">#</div>
        </div>

			  `,
        answer: ["C", "A", "B", "D"],
      },
    ],
  },
  6: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E6",
    audio: "Audios/1.10.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E6/key/answerKey.png",
    component: T6,
    inputSize: 200,
    titleQuestion: [
      {
        num: "6",
        title: `
          <headphone1 name='1.10'></headphone1> Now listen to the dialogues one at a time, and
          match each one with one of the sentences A–D in exercise 5.
          There is one extra sentence.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      width: 40,
      height: 40,
      borderRadius: 5,
      borderBottom: "dash",
      textAlign: "center",
      border: "2px solid black",
      // backgroundColor: "#fee4cb",
      fontSize: 22,
    },
    maxLength: 1,
    titleImage: "",
    questions: [
      {
        title: `
        <div>
          <div style="display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">A</div>
            <div>The speakers start by <u>agreeing</u>, but then <u>disagree</u>.</div>
          </div>
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">B</div>
            <div>One speaker is <u>complaining</u> about something he bought.</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">C</div>
            <div>One speaker is <u>giving directions</u> to the nearest cinema.</div>
          </div>	
          <div style="margin-top: 7px; display: flex; gap: 20px;">
            <div style="width: 40px; font-weight: bold; text-align: right;">D</div>
            <div>One speaker is <u>narrating</u> an amusing event that happened.</div>
          </div>	
        </div>

        <div style="margin: 20px 0px 0px 25px; line-height: 60px;">
          Dialogue 1: sentence #<br>
          Dialogue 2: sentence #<br>
          Dialogue 3: sentence #<br>
        </div>

			  `,
        answer: ["D", "A", "B"],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P28-E7",
    audio: "Audios/1.10.mp3",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "7",
        title:
          "<headphone1 name='1.10'></headphone1> Listen again. Check your answers to exercise 6 by listening for phrases 1–4 in exercise 5.",
        color: "BLACK",
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page26/E7/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page26/E5/1.jpg" },
      ],
    ],
  },
  8: {
    unit: "Unit 3",
    id: "WB10-U3-P26-E8",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    inputSize: 500,
    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "8",
        title: `
				Write slogans to advertise these products.
			  `,
        color: "black",
      },
    ],
    titleImage: "",
    questions: [
      {
        title: `
          <div style="display: flex;">
            <div style="line-height:45px;">
              A #<br>
              B #<br>
              C #<br>
            </div>  
            <div style=""><img src='img/FriendsPlus/Page26/E8/1.jpg' /></div>
          </div>
        
			  `,
        answer: [],
      },
    ],
  },
};

export default json;
