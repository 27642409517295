import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Cumulative",
    id: "FG10-C-P72-E1",
    audio: "Audios/2.11.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E1/Key/answerKey.png",
    component: T6,
    inputSize: 350,
    maxLength: 21,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "1",
        title: `
            <headphone name="2.11"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
            Listen to four speakers talking about a past
            situation. Write adjectives to describe how the
            situation made all four speakers feel.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ul style= "list-style: none">
                  <li>1:  #</li>
                  <li>2:  #</li>
                  <li>3:  #</li>
                  <li>4:  #</li>
                </ul>
              </div>
            `,
        answer: [
          "embarrassed / ashamed",
          "embarrassed / ashamed",
          "embarrassed / ashamed",
          "embarrassed / ashamed",
        ],
      },
    ],
  },

  2: {
    unit: "Cumulative",
    id: "FG10-C-P72-E2",
    audio: "Audios/2.11.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E2/Key/answerKey.png",
    component: T6,
    inputSize: 50,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "2",
        title: `
            <headphone name="2.11"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
            Listen again. Match speakers 1–4 with
            sentences A–E. There is one extra sentence.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ul style= "list-style: none">
                  <li><b>A</b>:  He / She said the wrong thing</li>
                  <li><b>B</b>:  He / She needed a change of clothes.</li>
                  <li><b>C</b>:  He / She had an accident. </li>
                  <li><b>D</b>:  He / She spoiled a surprise </li>
                  <li><b>E</b>:  He / She didn't know what to say. </li>
                </ul>
                <strong>
                    <span style="margin-right: 40px">ANSWER: </span>
                    <span style="margin-right: 40px">1: #</span>
                    <span style="margin-right: 40px">2: #</span>
                    <span style="margin-right: 40px">3: #</span>
                    <span style="margin-right: 40px">4: #</span>
              </div>
            `,
        answer: ["C / D", "B", "E", "A"],
      },
    ],
  },

  3: {
    unit: "Cumulative",
    id: "FG10-C-P72-E3",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    inputSize: 50,
    maxLength: 1,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
            <span style="color: rgb(0, 110, 147)"> SPEAKING</span> 
             Work in pairs. You are going to take turns to
            tell your partner about a situation when you
            felt embarrassed. React appropriately
            when your partner is narrating. Use these
            ideas to help you:
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <ul>
                  <li>set the scene</li>
                  <li>say what happened</li>
                  <li>say how you felt</li>
                </ul>
              </div>
            `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Cumulative",
    id: "FG10-C-P72-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E4/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
            <span style="color: rgb(0, 110, 147)"> READING</span> 
             Read the text and choose the best summary
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "black",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 3px",
          borderColor: "gray",
        },
        limitSelect: 1,
        listWords: [
          "a <br/> b <br/> c",
        ],
        answers: ['0-8'],
        initialValue: [],
      },
      Layout: `
                  <div><img src="img/FriendsPlus/Page72/E4/1.jpg" alt="image" width=100%/></div>
                  <div style="margin-top: 20px">
                    <b>The best summary</b>
                    <div style='display:flex'>
                      <div style='width:43px;'><input id='0' type='Circle' /></div>
                      <div>
                      How therapy can help people with phobias.<br/>
                      The top five phobias and how to deal with them.<br/>
                      How phobias can affect our lives.<br/>
                      </div>
                    </div>
                  </div>
      `,
    },
  },
  5: {
    unit: "Cumulative",
    id: "FG10-C-P72-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page72/E5/Key/answerKey.png",
    component: T6,
    selectStyle: {
      color: "black",
      fontWeight: "bold",
      width: 64,
      textAlign: "center",
      border: "2px solid black",
    },
    selectOptionValues: ["A", "B", "C", "D", "E",],
    titleQuestion: [
      {
        num: "5",
        title: `
            <span style="color: rgb(0, 110, 147)"> READING</span> 
            Read the text again. Match paragraphs
            A–E of the text with the questions below.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
                <div>
                    <img src="img/FriendsPlus/Page72/E4/1.jpg" alt="image" width=100%/>
                </div>
                <div style="margin-top: 20px">
                  <div>In which paragraph does the writer tell us …</div>
                  <div style='display:flex; gap:15px;'>
                    <b>1</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>what caused Christine’s phobia?</div>
                        <select id='0'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>2</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>what happened when Christine saw a spider in her car? </div>
                        <select id='1'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>3</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>what Christine hopes for the future?</div>
                        <select id='2'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>4</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>what other kinds of phobias exist?</div>
                        <select id='3'></select>
                    </div>
                  </div>
                  <div style='display:flex; gap:15px;'>
                    <b>5</b>
                    <div style='display:flex; gap:15px; justify-content: space-between;width: 700px;'>
                        <div>what therapists can do to help?</div>
                        <select id='4'></select>
                    </div>
                  </div>
                </div>
            </div>
            `,
        answer: ["C", "A", "E", "B", "D"],
      },
    ],
  },
};

export default json;

