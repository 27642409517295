import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Cumulative',
    id: 'FG10-C-P74-E1',
    audio: 'Audios/2.12.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page74/E1/Key/answerKey.png',
    component: T6,
    selectStyle: {
      color: 'black',
      fontWeight: 'bold',
      width: 64,
      textAlign: 'center',
      border: '2px solid black',
    },
    selectOptionValues: ['T', 'F'],
    titleQuestion: [
      {
        num: '1',
        title: `
            <headphone name="2.12"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
            Read the sentences and underline the key words. Then listen to the information about the London Cinema Museum. Are the sentences true (T) or false (F)?

            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; gap:15px;'>
          <b>1</b>
          <div>The London Cinema Museum isn’t very well-known. </div>
          <select id='0'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>2</b>
          <div>The actor Charlie Chaplin once visited the museum. </div>
          <select id='1'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>3</b>
          <div>You needn’t go on a guided tour if you want to visit the collection.</div>
          <select id='2'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>4</b>
          <div>You don’t have to buy a ticket to see a film before the day of the showing. </div>
          <select id='3'></select>
        </div>
        <div style='display:flex; gap:15px;'>
          <b>5</b>
          <div>The museum is difficult to get to.</div>
          <select id='4'></select>
        </div>
            `,
        answer: ['T', 'F', 'F', 'T', 'F'],
      },
    ],
  },

  2: {
    unit: 'Cumulative',
    id: 'FG10-C-P74-E2',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T6,
    inputSize: 50,
    maxLength: 1,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
            <span style="color: rgb(0, 110, 147)"> SPEAKING</span> 
             Work in pairs. You are at home with a friend and there is
            nothing you want to watch on TV. You are looking at the
            films available on the pay-per-view channel. Express your
            opinions about the films below. Which film will you pay to
            see, and why? Why are you rejecting the other options?
            `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
              <div style="margin-left: 100px; margin-top: 20px">
                <img src="img/FriendsPlus/Page74/E2/1.jpg" alt="image" />
              </div>
            `,
        answer: [],
      },
    ],
  },

  3: {
    unit: 'Cumulative',
    id: 'FG10-C-P74-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page74/E3/Key/answerKey.png',
    component: Circle_Write,
    titleQuestion: [
      {
        num: '3',
        title: `
            <span style="color: rgb(0, 110, 147)"> READING</span> 
             Read the text and choose the best answers. Underline
            the sentences in the text that helped you decide.
            `,
        color: '#000',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 3px',
          borderColor: 'gray',
        },
        limitSelect: 1,
        listWords: [
          'a <br/> b <br/> c <br/> d ',
          'a <br/> b <br/> c <br/> d ',
          'a <br/> b <br/> c <br/> d ',
          'a <br/> b <br/> c <br/> d ',
          'a <br/> b <br/> c <br/> d ',
        ],
        answers: ['0-4', '1-0', '2-8', '3-12', '4-8'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div>What is Mike Owen doing today?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='0' type='Circle' /></div>
              <div>
                <div>He is playing computer games.</div>
                <div>He is watching a TV series from start to finish.</div>
                <div>He is running a difficult race.</div>
                <div>He is watching an interesting documentary on TV. </div>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div>According to the text, waiting for the next episode of a TV series can make viewers feel</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='1' type='Circle' /></div>
              <div>
                <div>anxious</div>
                <div>bored</div>
                <div>delighted</div>
                <div>frightened</div>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div>How much does it cost to use an online streaming service?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='2' type='Circle' /></div>
              <div>
                <div>Nothing – it’s free.</div>
                <div>It depends on the film.</div>
                <div>The same every month.</div>
                <div>Users pay by the hour.</div>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div>Viewers who stream programmes</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='3' type='Circle' /></div>
              <div>
                <div>can leave the room during the adverts.</div>
                <div>needn’t receive phone calls.</div>
                <div>mustn’t get up in the middle of a film.</div>
                <div>don’t have to watch at particular times.</div>
              </div>
            </div>
          </div>
        </div>
 
        <div style='display:flex'>
          <b>5 &emsp;</b>
          <div>
            <div>What makes Mike choose to watch <i>House of Cards</i> today?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='4' type='Circle' /></div>
              <div>
                <div>It only has thirteen episodes.</div>
                <div>Many TV channels show it at the same time.</div>
                <div>It was highly recognised as a huge success.</div>
                <div>There is just one episode each week.</div>
              </div>
            </div>
          </div>
        </div>
 
      `,
    },
  },
};

export default json;
