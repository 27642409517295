import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P8-E1',
		component: D1,
		totalInput: 4,
		isHiddenCheck: true,
		textAlign: 'center',
		padding: '0px 0px 5px 0px',
		exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
		titleImage: '',
		titleQuestion: [
			{
				num: '1',
				title:
					'Look at the pictures and complete the puzzle with adjectives to describe feelings.',
			},
		],
		questionImage: [
			// Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page8/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page8/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/3.jpg', input: true, answer: 'x' },
				{ url: 'img/FriendsPlus/Page8/E1/4.jpg', input: true, answer: 'c' },
				{ url: 'img/FriendsPlus/Page8/E1/5.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/6.jpg', input: true, answer: 't' },
				{ url: 'img/FriendsPlus/Page8/E1/7.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/8.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/9.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/11.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/12.jpg', input: true, answer: 'l' },
				{ url: 'img/FriendsPlus/Page8/E1/13.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/14.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/15.jpg', input: true, answer: 'v' },
				{ url: 'img/FriendsPlus/Page8/E1/16.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/17.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/18.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/19.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/20.jpg', input: true, answer: 'r' },
				{ url: 'img/FriendsPlus/Page8/E1/21.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/22.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/23.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/24.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/25.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/26.jpg', input: true, answer: 'a' },
				{ url: 'img/FriendsPlus/Page8/E1/27.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/28.jpg', input: true, answer: 'x' },
				{ url: 'img/FriendsPlus/Page8/E1/29.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/30.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/31.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/32.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/33.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/34.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/35.jpg', input: true, answer: 'p' },
				{ url: 'img/FriendsPlus/Page8/E1/36.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/37.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/38.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/39.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/40.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/41.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/42.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/43.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/44.jpg', input: true, answer: 'p' },
				{ url: 'img/FriendsPlus/Page8/E1/45.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/46.jpg', input: true, answer: 'c' },
				{ url: 'img/FriendsPlus/Page8/E1/47.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/48.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/49.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/50.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/51.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/52.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/53.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/54.jpg', input: true, answer: 'r' },
				{ url: 'img/FriendsPlus/Page8/E1/55.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/56.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/57.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/58.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/59.jpg', input: true, answer: 'r' },
				{ url: 'img/FriendsPlus/Page8/E1/60.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/61.jpg', input: true, answer: 'g' },
				{ url: 'img/FriendsPlus/Page8/E1/62.jpg', input: true, answer: 'h' },
				{ url: 'img/FriendsPlus/Page8/E1/63.jpg', input: true, answer: 't' },
				{ url: 'img/FriendsPlus/Page8/E1/64.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/65.jpg', input: true, answer: 'n' },
				{ url: 'img/FriendsPlus/Page8/E1/66.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/67.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/68.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/69.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/70.jpg', input: true, answer: 'n' },
				{ url: 'img/FriendsPlus/Page8/E1/71.jpg', input: true, answer: 'v' },
				{ url: 'img/FriendsPlus/Page8/E1/72.jpg', input: true, answer: 'i' },
				{ url: 'img/FriendsPlus/Page8/E1/73.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/74.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/75.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/76.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/77.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/78.jpg', input: true, answer: 'p' },
				{ url: 'img/FriendsPlus/Page8/E1/79.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/80.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/81.jpg', input: true, answer: 't' },
				{ url: 'img/FriendsPlus/Page8/E1/82.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/83.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/84.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/85.jpg', input: true, answer: 'n' },
				{ url: 'img/FriendsPlus/Page8/E1/86.jpg', input: true, answer: 'f' },
				{ url: 'img/FriendsPlus/Page8/E1/87.jpg', input: true, answer: 'u' },
				{ url: 'img/FriendsPlus/Page8/E1/88.jpg', input: true, answer: 's' },
				{ url: 'img/FriendsPlus/Page8/E1/89.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/90.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/91.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/92.jpg' },
				{ url: 'img/FriendsPlus/Page8/E1/93.jpg', input: true, answer: 'h' },
				{ url: 'img/FriendsPlus/Page8/E1/94.jpg', input: true, answer: 'o' },
				{ url: 'img/FriendsPlus/Page8/E1/95.jpg', input: true, answer: 'c' },
				{ url: 'img/FriendsPlus/Page8/E1/96.jpg', input: true, answer: 'k' },
				{ url: 'img/FriendsPlus/Page8/E1/97.jpg', input: true, answer: 'e' },
				{ url: 'img/FriendsPlus/Page8/E1/98.jpg', input: true, answer: 'd' },
				{ url: 'img/FriendsPlus/Page8/E1/99.jpg' },
			],
			[
				// Column1
				{ url: 'img/FriendsPlus/Page8/E1/100.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page8/E1/101.jpg' },
				{
					url: 'img/FriendsPlus/Page8/E1/102.jpg',
					input: true,
					answer: 'disappointed',
				},
				{ url: 'img/FriendsPlus/Page8/E1/103.jpg' },
			],
			[
				// Column1
				{ url: 'img/FriendsPlus/Page8/E1/104.jpg' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P8-E2',
		component: T6,
		maxLength: 11,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page6/E2/Key/answerKey.png',
		inputSize: 140,
		titleQuestion: [
			{
				num: '2',
				title: 'Match the words below with the descriptions.',
			},
		],
		questions: [
			{
				title: `
              <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>anxious</div>
                <div>delighted</div>
                <div>embarrassed</div>
                <div>frightened</div>
                <div>proud</div>
                <div>relieved</div>
                <div><s>shocked</s></div>
                <div>upset</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">The waiter was really rude to me when I asked for a glass of water. I was a bit <span style="color: rgb(0, 68, 98)">shocked</span>.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">I got a new smartphone for my birthday. I was #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">My dad started to dance at my party and all my friends saw him! I was really ! #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">My little brother won a singing competition. I was very #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">Our dog is very old and is really ill. I think he might die. We're very # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">I've got a difficult, important exam tomorrow. I'm feeling a little bit # </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7. </b></div><div style="margin-left: 30px; flex: 1; ">I went on the tallest, fastest ride at the theme park. I was really #  </div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8. </b></div><div style="margin-left: 30px; flex: 1; ">I left my mobile on the bus, but someone found it. I was extremely # </div></div>
              </div>
        `,
				answer: [
					'delighted ',
					'embarrassed ',
					'proud ',
					'upset ',
					'anxious ',
					'frightened ',
					'relieved',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P8-E3',
		audio: 'Audios/1.02.mp3',
		video: '',
		component: T6,
		maxLength: 10,
		textareaStyle: {
			width: 575,
			background: '#e4e3f1',
			border: '1px solid #777580',
			resize: 'none',
		},
		stylesTextInput: {
			borderBottom: '1px solid',
		},
		exerciseKey: 'img/FriendsPlus/Page8/E3/Key/answerKey.png',
		inputSize: 150,
		titleQuestion: [
			{
				num: '3',
				title:
					'<headphone name="1.02"></headphone>Listen. How are the people feeling? Choose from the adjectives below. There are two extra adjectives. ',
			},
		],
		questions: [
			{
				title: `
              <div style="color: rgb(144, 38, 143); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>cross</div>
                <div>delighted</div>
                <div>envious</div>
                <div>relieved</div>
                <div>suspicious </div>
              </div>
              <div style="font-weight: bold;">
                <div>Speaker 1 #</div>
                <div>Speaker 2 #</div>
                <div>Speaker 3 #</div>
              </div>
        `,
				answer: ['relieved ', 'suspicious ', 'envious'],
			},
		],
	},

	4: {
		// Exercise num
		unit: 'Unit 2',
		id: 'WB10-U1-P8-E4',
		component: T6,
		questionImage: [],
		textareaStyle: { width: 700, resize: 'none', borderBottom: 'none' },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: '4',
				title: ' Complete the sentences with your own words.',
			},
		],
		questions: [
			{
				title: `
            <div>
              <div style="display: flex; margin-block: -10px"><b style="padding-top: 6px;">1</b><textarea id="1" rows="2" >I feel confused when </textarea></div>
              <div style="display: flex; margin-block: -10px"><b style="padding-top: 6px;">2</b><textarea id="2" rows="2" >I feel bored when </textarea></div>
              <div style="display: flex; margin-block: -10px"><b style="padding-top: 6px;">3</b><textarea id="3" rows="2" >I feel proud when  </textarea></div>
              <div style="display: flex; margin-block: -10px"><b style="padding-top: 6px;">4</b><textarea id="4" rows="2" >I feel excited when </textarea></div>
              <div style="display: flex; margin-block: -10px"><b style="padding-top: 6px;">5</b><textarea id="5" rows="2" >I feel disappointed when  </textarea></div>
            </div>
        `,
				answer: [],
			},
		],
	},
};

export default json;
