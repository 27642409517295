import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P63-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page63/E1/Key/answerKey.png",
    textAlign: "center",
    inputSize: 80,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: `
                Complete the collocations with the verbs below.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
		       	<div style='color: rgb(33, 133, 198);'><b>
            go (x2)&emsp;
            hire&emsp;
            visit</b></div>


                <div><b>1 &ensp;</b>#for a walk / for a bike ride / on a boat trip</div>
                <div><b>2 &ensp;</b>#a castle / a mosque / a museum</div>
                <div><b>3 &ensp;</b>#a car / a kayak / a bike</div>
                <div><b>4 &ensp;</b>#abseiling / mountain biking / surfing / shopping / kayaking</div>
        `,
        answer: ["go", "visit", "hire", "go"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 7",
    id: "SB10-U7-P63-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "/img/FriendsPlus/Page63/E2/key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: ` Read the blog post. Find two more activities you can add to 1–4 in exercise 1.
			  `,
        color: "#000",
      },
    ],
    titleImage: "",
    textAlign: "center",
    inputSize: 180,
    questionImage: [],
    questions: [
      {
        title: `
		       	<div style='color: rgb(33, 133, 198);'><b>
            go (x2)&emsp;
            hire&emsp;
            visit</b></div>

        <br/>
        <div> Two activities: #,#.</div>
        <br/>
					  <div style=" width: 760px; background-image: url('/img/FriendsPlus/Page63/E2/1.jpg');  background-repeat: no-repeat;background-size: 760px 740px; margin-left: 0px;">
              <div style='padding:140px 75px 25px 130px;'>
                    <div>Having a great time on our cycling tour of Provence 
in the South of France. We arrived on Saturday after 
a long journey on the train from London. We've 
cycled about 150 km since Saturday morning and 
I'm exhausted! Danny is tired too, but he's fitter than 
I am. The countryside is beautiful, but there are lots 
of hills!! The weather has been warm and sunny 
– maybe a little too hot for me. We've stayed at a 
different place every night so far. Two nights ago we 
stayed at a farm, and last night we stayed in a small 
hotel. Staying in a castle tonight, which is amazing! 
Tomorrow, we're visiting a lake. It's quite a long 
way from here, in the mountains. We'll stay there all 
afternoon and go swimming. Looking forward to it! 
I'll post again in two days.</div>
                </div>
            </div>
			  `,
        answer: ["visit a lake", "go swimming"],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P63-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page63/E3/Key/answerKey.png",
    textAlign: "center",
    inputSize: 150,
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
        Read the <span style='color:rgb(36, 131, 197);'>Writing Strategy</span>. Then find these sentences in the
                blog post. What word or words did the writer omit from
                the start?.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div style='background-color: rgb(234, 239, 249); margin:20px; width:750px; border-radius: 15px; border: 5px dotted rgb(36, 131, 197)'>
              <div style='padding: 15px 25px 25px 25px;' >
                <div ><b style='color:rgb(36, 131, 197) ;'>Writing Strategy</b></div>
                <div>In informal style (e.g. in emails, blogs and postcards) we can omit sentence subjects I and <i>we</i>.</div>
                <i>Must go now! &emsp; Wish you were here!</i>
                <div>With the present continuous, we can also omit the auxiliaries <i>am</i> and <i>are</i>.</div>
                <i>Having a great time in Italy!</i>
                <div>However, only do this once or twice in a text. Do not omit every instance of I and <i>we</i>.</div>
              </div>
            </div>


                <div><b>1 &ensp;</b># having a great time on our cycling tour …</div>
                <div><b>2 &ensp;</b># staying in a castle tonight …</div>
                <div><b>3 &ensp;</b># looking forward to it!</div>
        `,
        answer: ["I'm / We're", "We're", "I'm"],
      },
    ],
  },

  4: {
    unit: "Unit 7",
    id: "WB10-U7-P63-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page63/E4/key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				Read the sentences below. Cross out the words which could be <br/>omitted to make them more informal.
			  `,
        color: "black",
      },
    ],
    // isHiddenCheck: true,
    inputSize: 460,
    textAlign: "left",
    maxLength: 85,
    titleImage: "",
    questions: [
      {
        title: `  
            <div style='display:flex; gap:15px;'>
              <b>1</b>
              <div>
                <div>I wish I could stay here longer!</div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>2</b>
              <div>
                <div>I'm taking lots of photos to show you. </div>
                <div>#</div>
              </div>
            </div>
            <div style='display:flex; gap:15px;'>
              <b>3</b>
              <div>
                <div>I'll see you soon! </div>
                <div>#</div>
              </div>
            </div>
			  `,
        answer: [
          "Wish I could stay here longer!",
          "Taking lots of photos to show you.",
          "See you soon!",
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit 7",
    id: "WB10-U7-P63-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    textAlign: "left",
    inputSize: 900,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title: `
                Read the task. Then prepare to write your blog post by inventing answers to questions 1–5 below.
				`,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
				
				<div style='background-color: rgb(209, 222, 241); margin-top: 20px; margin-left:100px; width:700px; border-radius: 5px;'>
					<div style='padding: 15px 25px 25px 25px;' >
						<div>You are on holiday with a friend, touring around a country. Write a blog entry with information about:</div>
						<div>
							<ul>
								<li>where you are touring and how you are travelling</li>
								<li>the weather</li>
								<li>some places you have stayed</li>
								<li>something you plan to do in the next few days.</li>
							</ul>
						</div>
					</div>
				</div>

				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>Where are you touring? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>How are you travelling? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>What is the weather like? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>Where have you stayed? </div>
						<div>#</div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>5</b>
					<div>
						<div>What are your plans? </div>
						<div>#</div>
					</div>
				</div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit 7",
    id: "SB10-U7-P63-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    titleQuestion: [
      {
        num: "6",
        title: ` Write your blog post using your notes from exercise 5.
			  `,
        color: "#000",
      },
    ],
    textareaStyle: { width: 800 },
    hideBtnFooter: true,
    titleImage: "",
    questions: [
      {
        title: `
        <div><img src='/img/FriendsPlus/Page63/E6/2.jpg' style='width:700px; margin-left: 0px;'/></div>
				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>Where are you touring? </div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>How are you travelling? </div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>What is the weather like? </div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>Where have you stayed? </div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>5</b>
					<div>
						<div>What are your plans? </div>
					</div>
				</div>
				 <textarea id="0" rows="7" ></textarea>
			  `,
        answer: ["Four", "Two"],
      },
    ],
  },
};

export default json;
