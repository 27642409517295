import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-20-E1",
    component: T6,
    inputSize: 160,
    stylesTextInput: { border: "none" },
    exerciseKey: "/img/FriendsPlus/Page20/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          " Write the noun form of these verbs. Some are the same as the verb.",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
            <div style="margin-left: 60px;margin-top:20px">
              <table style="border-collapse: collapse;">
              <thead>
                <tr style="background-color: rgb(146,39,143); color: white;">
                  <th style="border: 1px solid;padding: 8px">Verb</th>
                  <th style="border: 1px solid ; padding: 8px">Nouns </th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                    <span><b>1</b> <span> complete</span></span>
                    <span></span>
                  </td>
                  <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                    <span style="color: rgb(0, 68, 98)">comletion</span>
                  </td>   
                </tr>
                <tr>
                <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                  <span><b>2</b> <span> achieve </span></span>
                </td>
                <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                  <span ><input id="0"></span>
                </td>   
              </tr>
              <tr>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span><b>3</b> <span> need  </span></span>
              </td>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span ><input id="1"></span>
                
              </td>   
              </tr>
            <tr>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span><b>4</b> <span> dream </span></span>
              </td>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span ><input id="2"></span>
                
              </td>   
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span><b>5</b> <span> argue   </span></span>
              </td>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span ><input id="3"></span>
                
              </td>   
            </tr>
            <tr>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span><b>6</b> <span>  fail   </span></span>
              </td>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span ><input id="4"></span>
              </td>   
            </tr>
            <tr>
            <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
              <span><b>7</b> <span> imagine  </span></span>
            </td>
            <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
              <span ><input id="5"></span>
            </td>   
          </tr>

          <tr>
          <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
            <span><b>8</b> <span> erupt </span></span>
          </td>
          <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
            <span ><input id="6"></span>
          </td>   
        </tr>
        <tr>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span><b>9</b> <span> rescue </span></span>
              </td>
              <td style="border: 1px solid rgb(146,39,143); padding: 8px;">
                <span ><input id="7"></span>
              </td>   
            </tr>
          </tbody> 
        </table>
      </div>
        `,
        answer: [
          "achievement",
          "need",
          "dream",
          "argument",
          "failure",
          "imagination",
          "eruption",
          "rescue",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB10-U2-P20-E2",
    exerciseKey: "/img/FriendsPlus/Page20/E2/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 220,
    maxLength: 15,
    // checkDuplicated: true,
    stylesTextInput: {
      textAlign: "center",
      borderBottom: "1px solid",
      backgroundColor: "transparent",
      fontSize: 24,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
      Complete the text with the noun form of the verbs in brackets. You may need the plural form.
      `,
      },
    ],

    questions: [
      {
        title: `
      
            Last year, at the age of seventeen, Mike 
            Perham became the youngest sailor to 
            complete a </br><sup>1</sup> # (circumnavigate) of 
            the world alone. But this year, two sixteen-year-old girls are beginning separate 
            <sup>2</sup> # (attempt) to beat his record. Last 
            year, a thirteen-year-old Dutch girl, Laura 
            Dekker, was finishing her <sup>3</sup> #
            (prepare) for the same journey when a 
            children's court stopped her. Now aged 
            fourteen, she's trying again. It seems that 
            many teenagers today aren't interested in the 
            normal forms of <sup>4</sup> # (entertain) and 
            <sup>5</sup> # (relax), like video games and 
            sports. They want <sup>6</sup> # (experience) 
            that can really excite their</br> <sup>7</sup> #
            (imagine) and also help with the 
            <sup>8</sup> # (develop) of personal skills. So 
            they choose <sup>9</sup> # (explore). Their 
            amazing <sup>10</sup> # (accomplish) certainly 
            look good on their CVs when it's time to find 
            <sup>11</sup> # (employ). But for most of them, 
            that probably isn't the main <sup>12</sup> #
            (motivate).

      `,
        answer: [
          "circumnavigation ",
          "attempts ",
          "preparations ",
          "entertainment ",
          "relaxation ",
          "experiences ",
          "imagination ",
          "development ",
          "exploration ",
          " accomplishments ",
          " employment ",
          " motivation",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB10-U2-P20-E3",
    component: T6,
    isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
    stylesTextInput: {
      backgroundColor: "transparent",
      borderBottom: "1px solid",
      padding: 10,
      // fontSize: 22,
    },
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [
      {
        num: "3",
        title: "Study the dictionary entries. Then answer the questions below.",
      },
    ],
    checkUppercase: true,
    questions: [
      {
        title: `
          <div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">1</div>
              <div>Which noun is countable? #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">2</div>
              <div>Which noun is uncountable? #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">3</div>
              <div>Which noun has no definition? # <br/> (Note: related nouns sometimes have no definition if the meaning is clear without one.)</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">4</div>
              <div>Which entry has example sentences? #</div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">5</div>
              <div>Why are there two examples, not one? <input id='4' width='350px' /></div>
            </div>
            <div style="display: flex; gap: 20px;">
              <div style="width: 40px; font-weight: bold; text-align: right;">6</div>
              <div>Which entry has no information about pronunciation? #</div>
            </div>
          </div>
        `,
        // title: `
        //       <div><img style="width: 60%" src="/img/FriendsPlus/Page20/E3/1.jpg"></div>
        //       <div style="margin-top: 20px; font-size: 22px">
        //         <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; "> #</div></div>
        //       </div>
        // `,
        answer: [
          "explorer ",
          "exploration ",
          "exploration ",
          "explore ",
          "explore has multiple meanings.",
          "exploration",
        ],
      },
    ],
  },
  // 3: {
  // 	// Exercise num
  // 	unit: 'Unit 2',
  // 	id: 'WB10-U2-P20-E3',
  // 	component: T6,
  // 	stylesTextInput: {
  // 		backgroundColor: 'transparent',
  // 		borderBottom: '1px solid',
  // 		padding: 10,
  // 		fontSize: 22,
  // 	},
  // 	exerciseKey: 'img/FriendsPlus/Page20/E3/Key/answerKey.png',
  // 	inputSize: 430,
  // 	maxLength: 1,
  // 	titleQuestion: [
  // 		{
  // 			num: '3',
  // 			title: 'Study the dictionary entries. Then answer the questions below.',
  // 		},
  // 	],
  // 	checkUppercase: true,
  // 	questions: [
  // 		{
  // 			title: `
  //             <div><img style="width: 60%" src="/img/FriendsPlus/Page20/E3/1.jpg"></div>
  //             <div style="font-size: 22px">
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Which noun is countable? </br>#</div></div>
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">Which noun is uncountable? </br>#</div></div>
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">Which noun has no definition? </br>#</br>(Note: related nouns sometimes have no definition if the meaning is clear without one.)</div></div>
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">Which entry has example sentences? </br>#</div></div>
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">Why are there two examples, not one?</br>#</div></div>
  //               <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6. </b></div><div style="margin-left: 30px; flex: 1; ">Which entry has no information about pronunciation? </br>#</div></div>
  //             </div>
  //       `,
  // 			answer: [
  // 				'explorer ',
  // 				'exploration ',
  // 				'exploration ',
  // 				'explore ',
  // 				'explore has multiple meanings',
  // 				'exploration',
  // 			],
  // 		},
  // 	],
  // },

  4: {
    unit: "Unit 2",
    id: "WB10-U2-P20-E4",
    exerciseKey: "/img/FriendsPlus/Page20/E4/Key/answerKey.png",
    audio: "",
    video: "",
    component: T6,
    inputSize: 140,
    maxLength: 14,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      borderBottom: "none",
      backgroundColor: "transparent",
      fontSize: 22,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Read the Vocab boost! box. Then complete the labels and add any other words you can. Use a dictionary to help you.
      `,
      },
    ],

    questions: [
      {
        title: `
      
            <div><img style="width: 70%; margin-bottom: 10px" src="/img/FriendsPlus/Page20/E4/1.jpg"></div>
            <div style='position: relative;'>
              <div><img src="/img/FriendsPlus/Page20/E4/2.jpg"></div>
              <div style=" position: absolute; top: 42px; left: 84px; "><input id='0' /></div>
              <div style=" position: absolute; top: 42px; left: 105px; "><input id='1' /></div>
              <div style=" position: absolute; top: 42px; left: 150px; "><input id='2' /></div>
              <div style=" position: absolute; top: 42px; left: 169px; "><input id='3' /></div>
              <div style=" position: absolute; top: 109px; left: 510px; "><input id='4' /></div>
              <div style=" position: absolute; top: 109px; left: 546px; "><input id='5' /></div>
              <div style=" position: absolute; top: 109px; left: 560px; "><input id='6' /></div>
              <div style=" position: absolute; top: 186px; left: 176px; "><input id='7' /></div>
              <div style=" position: absolute; top: 186px; left: 197px; "><input id='8' /></div>
              <div style=" position: absolute; top: 186px; left: 230px; "><input id='9' /></div>
              <div style=" position: absolute; top: 186px; left: 250px; "><input id='10' /></div>
              <div style=" position: absolute; top: 186px; left: 279px; "><input id='11' /></div>
              <div style=" position: absolute; top: 190px; left: 652px; "><input id='12' /></div>
              <div style=" position: absolute; top: 190px; left: 680px; "><input id='13' /></div>
              <div style=" position: absolute; top: 234px; left: 462px; "><input id='14' /></div>
              <div style=" position: absolute; top: 234px; left: 478px; "><input id='15' /></div>
              <div style=" position: absolute; top: 234px; left: 502px; "><input id='16' /></div>
      `,
        answer: [
          "k",
          "i",
          "i",
          "f",
          "s",
          "i",
          "e",
          "k",
          "i",
          "a",
          "c",
          "e",
          "l",
          "p",
          "s",
          "k",
          "i",
        ],
      },
    ],
  },
};

export default json;
