import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P28-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E1/key/answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: `
				Write the opposite of the adjectives by adding a negative
        prefix: <i>dis-, il-, im-, in-</i> or <i>un-.</i>
			  `,
        color: "black",
      },
    ],
    textAlign: "center",
    maxLength: 20,
    inputSize: 160,
    questions: [
      {
        title: `
      <div style='line-height:45px'>
        <b>1</b>&ensp;satisfied #<br>
        <b>2</b>&ensp;tidy #<br>
        <b>3</b>&ensp;correct #<br>
        <b>4</b>&ensp;legible #<br>
        <b>5</b>&ensp;visible #<br>
        <b>6</b>&ensp;kind #<br>
        <b>7</b>&ensp;believable #<br>
        <b>8</b>&ensp;polite  #<br>
      </div>
			  `,
        answer: [
          "dissatisfied",
          "untidy",
          "incorrect",
          "illegible",
          "invisible",
          "unkind",
          "unbelievable",
          "impolite",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P28-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page28/E2/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the text about illegal downloads. Use the
adjectives in brackets with or without a negative prefix.
			  `,
        color: "black",
      },
    ],
    inputSize: 97,
    stylesTextInput: {
      borderBottom: "dash",
      textAlign: "center",
      backgroundColor: "#fee4cb",
      fontSize: 18,
      height: 19,
    },
    maxLength: 14,

    titleImage: "",
    questions: [
      {
        title: `
      <div style="position: relative; margin:0px 200px">
        <img src='img/FriendsPlus/Page28/E2/1.jpg' /> 
        
        <div style=" position: absolute; top: 160px; left: 221px; "><input id='0' background='rgb(216 217 219)'/></div>
        <div style=" position: absolute; top: 202px; left: 138px; "><input id='1' width='111px' background='rgb(221 221 223)'/></div>
        <div style=" position: absolute; top: 244px; left: 307px; "><input id='2' background='rgb(223 224 226)'/></div>
        <div style=" position: absolute; top: 358px; left: 65px; "><input id='3' width='129px' background='rgb(229 230 232)'/></div>
        <div style=" position: absolute; top: 358px; left: 332px; "><input id='4' background='rgb(229 231 230)'/></div>
        <div style=" position: absolute; top: 398px; left: 92px; "><input id='5' background='rgb(230 230 232)'/></div>
        <div style=" position: absolute; top: 462px; left: 184px; "><input id='6' width='129px' background='rgb(237 237 239)'/></div>
        <div style=" position: absolute; top: 533px; left: 292px; "><input id='7' background='rgb(237 239 238)'/></div>
        <div style=" position: absolute; top: 597px; left: 70px; "><input id='8' background='rgb(235 235 237)'/></div>
      </div>

			  `,
        answer: [
          "unfair",
          "impossible",
          "certain",
          "unacceptable",
          "dishonest",
          "unlikely",
          "irresponsible",
          "impatient ",
          "safe",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "WB10-U3-P28-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E3/key/answerKey.png",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the sentences with the adjectives below, with or
without a negative prefix.
			  `,
        color: "black",
      },
    ],
    textAlign: "center",
    inputSize: 170,
    titleImage: "",
    questions: [
      {
        title: `
        <div style='color:rgb(146 39 143)'>
          honest&emsp;legal&emsp;patient&emsp;polite&emsp;possible&emsp;safe
        </div>

      <div style='line-height:55px'>
        <b>1</b>&ensp;Police are investigating an# file-sharing site
        in the Philippines.<br>
        <b>2</b>&ensp;It was very# of him to arrive an hour late for
        dinner.<br>
        <b>3</b>&ensp;I get very# if I have to wait more than a few
        minutes for a download.<br>
        <b>4</b>&ensp;You don't have to pay before the excursion; it's#
        to pay at the end.<br>
        <b>5</b>&ensp;You mustn't climb on that wall; it's# .<br>
        <b>6</b>&ensp;You can believe everything she tells you; she's always
        completely# .<br>
      </div>
			  `,
        answer: [
          "illegal",
          "impolite",
          "impatient",
          "possible",
          "unsafe",
          "honest",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P28-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E4/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "4",
        title: `
				Study the dictionary entries. Then answer the questions
below.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 11,
    inputSize: 170,

    titleImage: "",
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page28/E4/1.jpg' style='width:16cm' />
          <div style='margin:10px;line-height:50px'>
            <b>1</b>&ensp;What is a synonym for caring?#<br>
            <b>2</b>&ensp;What is a synonym for considerate?# <br>
            <b>3</b>&ensp;Do both adjectives have antonyms beginning with a
            negative prefix?# <br>
            <div style='display:flex'>
              <b>4</b>&ensp;
              <div style='margin-left:10px; width:22cm'>Which dictionary entry has information about which
              preposition you use with the adjective?#<br><div>
            </div>
          </div>
			  `,
        answer: ["kind", "thoughtful", "yes", "considerate"],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P28-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E5/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    // isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "5",
        title: `
				Read the <span style='color:rgb(146 39 143)'>
        Vocab boost!
      </span> box. Use a dictionary to find one
synonym and one antonym of each adjective.
			  `,
        color: "black",
      },
    ],
    // stylesTextInput: {
    //   width: 36,
    //   height: 36,
    //   borderRadius: 5,
    //   borderBottom: "dash",
    textAlign: "center",
    //   border: "2px solid black",
    //   // backgroundColor: "#fee4cb",
    //   fontSize: 22,
    // },
    maxLength: 14,
    inputSize: 180,

    titleImage: "",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page28/E5/1.jpg'/>
        <div style='display:flex;margin:10px;line-height:50px '>
          <div>
            <b>1&ensp;pleasant</b><br>
            <b>2&ensp;fortunate</b><br>
            <b>3&ensp;courteous</b><br>
            <b>4&ensp;probable</b>
          </div>
          <div style='margin-left:40px'>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#<br>
              SYN#&emsp;OPP#
        
          </div>
        </div>

			  `,
        answer: [
          "enjoyable",
          "unpleasant",
          "lucky",
          "unfortunate",
          "polite",
          "discourteous",
          "almost certain",
          "improbable",
        ],
      },
    ],
  },
};

export default json;
