import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  4: {
    unit: "Cumulative",
    id: "FG10-C-P81-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page81/E4/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
          <span style="color: rgb(0, 110, 147);">GRAMMAR AND VOCABULARY</span>
          Choose the best options to fill in the blanks.
        `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],

      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
        ],
        answers: ['0-4', '1-8', '2-0', '3-8', '4-4', '5-8', '6-4', '7-0', '8-0', '9-4'],
        initialValue: [],
      },
      Layout: `
          <div>
            <div style="position: relative;">
              <img src="img/FriendsPlus/Page81/E4/1.jpg" alt="image"  style="width:800px;'/>
              <div style="position: absolute; top: 350px; left: 100px; max-width: 680px" ></div>          
            </div>
            

      <div style="position: relative; width: 800px; margin-top:10px;">
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;1 </b></div>
          <div style='flex: 3;'>buy for</div>
          <div style='flex: 3;'>pay</div>
          <div style='flex: 3;'>pay for</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;2 </b></div>
          <div style='flex: 3;'>how much</div>
          <div style='flex: 3;'>how</div>
          <div style='flex: 3;'>how many</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;3 </b></div>
          <div style='flex: 3;'>few</div>
          <div style='flex: 3;'>short</div>
          <div style='flex: 3;'>little</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;4 </b></div>
          <div style='flex: 3;'>don’t have to</div>
          <div style='flex: 3;'>must</div>
          <div style='flex: 3;'>mustn’t</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;5 </b></div>
          <div style='flex: 3;'>as</div>
          <div style='flex: 3;'>than</div>
          <div style='flex: 3;'>that</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;6 </b></div>
          <div style='flex: 3;'>borrow</div>
          <div style='flex: 3;'>owe</div>
          <div style='flex: 3;'>lend</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;7 </b></div>
          <div style='flex: 3;'>would leave</div>
          <div style='flex: 3;'>will leave</div>
          <div style='flex: 3;'>could leave</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;8 </b></div>
          <div style='flex: 3;'>have</div>
          <div style='flex: 3;'>will have</div>
          <div style='flex: 3;'>would have</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;9 </b></div>
          <div style='flex: 3;'>develops</div>
          <div style='flex: 3;'>is developing</div>
          <div style='flex: 3;'>had developed</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>10</b></div>
          <div style='flex: 3;'>become</div>
          <div style='flex: 3;'>will become</div>
          <div style='flex: 3;'>would become</div>
        </div>

        <div style="position: absolute; top: 0px; left: 49px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 37px; left: 49px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 76px; left: 49px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 49px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 152px; left: 49px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 49px; "><input id='5' type='Circle' /></div>
        <div style="position: absolute; top: 227px; left: 49px; "><input id='6' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 49px; "><input id='7' type='Circle' /></div>
        <div style="position: absolute; top: 302px; left: 49px; "><input id='8' type='Circle' /></div>
        <div style="position: absolute; top: 340px; left: 49px; "><input id='9' type='Circle' /></div>

      </div>
				`,
    },
  },
  5: {
    unit: "Cumulative",
    id: "FG10-C-P81-E5",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title: `
          <span style="color: rgb(0, 110, 147);">WRITING</span>
          Imagine you have just returned home from a visit to a
science museum. Write an email to a friend in which you:
        `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div>
          <ul>
            <li>describe the journey to the museum.</li>
            <li>describe the science museum.</li>
            <li>tell the story of one of the exhibits.</li>
            <li>suggest an activity to do together when your friend visits.</li>
          </ul>
        </div>
                <textarea id="0" rows="7" ></textarea>
				`,
        answer: [],
      },
    ],
  },
};

export default json;
