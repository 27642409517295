import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P15-E1',
		component: T6,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 22,
		},
		exerciseKey: 'img/FriendsPlus/Page15/E1/Key/answerKey.png',
		inputSize: 830,
		maxLength: 1,
		titleQuestion: [
			{
				num: '1',
				title:
					'Rewrite the following sentences. Replace the underlined phrases with the verbs below. Use the correct tenses and any changes if necessary',
			},
		],
		checkUppercase: true,
		questions: [
			{
				title: `
              <div style=" display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>admit</div>
                <div>confuse</div>
                <div>excite</div>
                <div>apologise</div>
                <div>disappoint </div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1. </b></div><div style="margin-left: 30px; flex: 1; ">Maria <u>felt sorry</u> for playing a prank on Mark on his birthday.</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2. </b></div><div style="margin-left: 30px; flex: 1; ">Robert's reactions on the stage <u>were difficult for us to understand</u>.</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3. </b></div><div style="margin-left: 30px; flex: 1; ">We all hope for a picnic and the bad weather <u>makes us really upset</u>.</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4. </b></div><div style="margin-left: 30px; flex: 1; ">My little brother <u>says</u> that he himself put the plastic mouse into my schoolbag.</br>#</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5. </b></div><div style="margin-left: 30px; flex: 1; ">The magician's movements <u>were really interesting</u> to the young children.</br>#</div></div>
              </div>
        `,
				answer: [
					'Maria apologised for playing a prank on Mark on his birthday.',
					"Robert's reactions on the stage confused us.",
					'We all hope for a picnic and the bad weather disappoints us.',
					'My little brother admits that he himself put the plastic mouse into my schoolbag.',
					"The magician's movements excited the young children.",
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P15-E2',
		audio: '',
		video: '',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			border: 'none',
			padding: 0,
			fontSize: 20,
		},
		textareaStyle: { width: 495, resize: 'none' },
		exerciseKey: 'img/FriendsPlus/Page15/E2/Key/answerKey.png',
		inputSize: 147,
		titleQuestion: [
			{
				num: '2',
				title: `Read the <span style="color: rgb(39, 131, 197)">Writing Strategy</span>. Then complete the description using the adjectives below to describe the people's feelings.`,
			},
		],
		questions: [
			{
				title: `
          <div style="display: flex; font-size: 20px;">
            <div style="flex: 2; margin-top: 40px;"><img style="max-width: 100%;" src='img/FriendsPlus/Page15/E2/1.jpg' /></div>

            <div style="margin-left: 40px; flex: 3;">
              <div style="color: rgb(39, 131, 197); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px;">
                <div>guilty</div>
                <div>proud</div>
                <div>scared</div>
                <div>upset</div>
              </div>

              <div style="position: relative;">
                <div><img style="max-width: 80%" src='img/FriendsPlus/Page15/E2/2.jpg' /></div>
                <div>
                  <div style=" position: absolute; top: 268px; left: 181px; "><input id='0' /></div>
                  <div style=" position: absolute; top: 370px; left: 119px; "><input id='1' /></div>
                  <div style=" position: absolute; top: 446px; left: 67px; "><input id='2' /></div>
                  <div style=" position: absolute; top: 494px; left: 161px; "><input id='3' /></div>
                </div>
              </div>
            </div>
          </div>
        `,
				answer: ['proud ', 'scared ', 'upset ', 'guilty'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P15-E3',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		isHiddenCheck: true,
		textareaStyle: { width: 600 },
		exerciseKey: 'img/FriendsPlus/Page15/E3/Key/answerKey.png',
		inputSize: 385,
		titleQuestion: [
			{
				num: '3',
				title:
					'Read the task. Then make brief notes in the table below. Use one of the suggestions in the ideas box or your own idea.',
			},
		],
		questions: [
			{
				title: `
					<div style="display: flex; font-size: 20px; gap: 30px;">
						<div style="display: flex; flex-direction: column; justify-content: space-around;">
							<div style="padding: 10px; background-color: rgb(209, 222, 241); border-radius: 15px; box-shadow: 1px 1px 40px inset white; width: fit-content">
								<b>You did something you feel bad about. Write a description of an event with the title ‘Confession!'</b>
								<ul>
								<li>Give a short description of what you did.</li>
								<li>Say how you felt at the time.</li>
								<li>Describe how other people reacted.</li>
								<li>Explain why you feel bad about it now.</li>
								</ul>
							</div>
							
							<div style="padding: 10px; background-color: rgb(209, 222, 241); border-radius: 15px; box-shadow: 1px 1px 40px inset white; width: fit-content">
								<b style="font-size: 26px">Ideas</b></br>
								<b>You feel bad because you:</b>
								<ul>
									<li>borrowed something without asking and broke it.</li>
									<li>told somebody a secret your friend told you.</li>
									<li>copied some homework from the internet.</li>
									<li>told somebody about a surprise party and spoiled the surprise.</li>
								</ul>
								</div>
							</div>

							<table style="border: 1px solid rgb(39, 131, 197)">
								<tr style="border: 1px solid rgb(39, 131, 197);"><div style="padding: 10px; background-color: rgb(39, 131, 197); color: white; font-weight: bold">Notes</div></tr>
								<tr style="border: 1px solid rgb(39, 131, 197);"><div style="padding: 10px">What did you do <div style="margin-bottom: -20px;"><textarea></textarea></div></div></tr>
								<tr style="border: 1px solid rgb(39, 131, 197);"><div style="padding: 10px">How did you feel at the time? Why? <div style="margin-bottom: -20px;"><textarea></textarea></div></div></tr>
								<tr style="border: 1px solid rgb(39, 131, 197);"><div style="padding: 10px">How did other people feel? How did they react? <div style="margin-bottom: -20px;"><textarea></textarea></div></div></tr>
								<tr style="border: 1px solid rgb(39, 131, 197);"><div style="padding: 10px">How do you feel about it now? Why? <div style="margin-bottom: -20px;"><textarea></textarea></div></div></tr>
							</table>
					</div>
        `,
				answer: [],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P15-E4',
		audio: '',
		video: '',
		component: UI,
		titleQuestion: [
			{
				num: '4',
				title: 'Write your description. Use your notes from exercise 3.',
			},
		],
		questionImage: [],
	},
};

export default json;
