import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import classNames from 'classnames';
import FooterModal from '../../../FooterModal';
import TitleQuestion from '../../../TitleQuestion';

import moment from 'moment';
import 'moment/locale/vi';
import styles from './styles.module.css';

moment.locale('vi');
// const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const alphabet = 'abcdefghijklmnopqrstuvwxyz';

const Multiple1 = (question) => {
  const [state, setState] = React.useState({
    questions: [],
    booleanArray: [],
    studentAnswer: null,
    isPointed: false,
    isDisabledSubmit: true,
    isDisabledRetry: true,
    videoVisible: false,
  });

  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, questions: JSON.parse(JSON.stringify(question.questions)) }));
  }, [question]);
  const onSubmit = React.useCallback(() => {
    const arraySelect = state.questions.map((x) => x.selectedItem);
    const listAnswers = state.questions.map((a) => a.answers);
    let booleanArray = [];
    let studentAnswer = [];
    listAnswers.forEach((item, i) => {
      const Answer_select = item[arraySelect[i]];
      booleanArray.push(Answer_select.isCorrect);
      studentAnswer.push(Answer_select.text);
    });
    let correctAnswer = 0;
    booleanArray.forEach((item, index) => {
      if (item) {
        correctAnswer++;
      }
    });
    const percent = parseInt((correctAnswer / booleanArray.length) * 100);
    // const resultString = `${correctAnswer}/${booleanArray.length} (${percent}%)`
    const result_String = `${correctAnswer}/${booleanArray.length}`;

    const params = {
      score: percent,
      unit: question.unit,
      results: result_String,
      exerciseId: question.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    };
    question.postAnswerToApi(params);

    setState((prevState) => ({
      ...prevState,
      booleanArray,
      studentAnswer,
      isPointed: true,
      isDisabledSubmit: true,
      isDisabledRetry: false,
    }));
  }, [question, state.questions]);

  const onRetry = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isPointed: false,
      isDisabledRetry: true,
      questions: JSON.parse(JSON.stringify(question.questions)),
    }));
  }, [question.questions]);

  const onClickAnswer = React.useCallback(
    (item, index) => {
      item.selectedItem = index;

      setState((prevState) => {
        const count = prevState.questions.reduce((total, countItem) => {
          if (countItem.selectedItem >= 0) {
            return total + 1;
          }
          return total;
        }, 0);

        const isDone = count === prevState.questions.length;

        if (isDone && state.isDisabledSubmit) {
          setState((prevState) => ({ ...prevState, isDisabledSubmit: false }));
        }

        return { ...prevState, questions: prevState.questions };
      });
    },
    [state.isDisabledSubmit]
  );

  const renderTitle = React.useCallback((item) => {
    const titleSplit = item.title?.split(' ');
    if (!titleSplit) return null;
    return titleSplit.map((itemTitle, index) => {
      return itemTitle === '#' ? (
        <span key={index}>__________</span>
      ) : (
        <span key={index} style={{ color: 'black' }}>
          {' '}
          {itemTitle}{' '}
        </span>
      );
    });
  }, []);

  const renderAnswerItem = React.useCallback(
    (qItem) => (answer, answerIndex) => {
      const isSelected = qItem.selectedItem === answerIndex;
      // Check answers
      let isCorrect = false;
      let color = 'black';

      if (state.isPointed) {
        isCorrect = answer.isCorrect;
        color = isSelected ? (isCorrect ? '#2ecc71' : '#E74C3C') : 'white';
      }

      const customStyles = {
        alphabet: {
          fontSize: question.fontSize || 22,
          marginRight: 8,
          color: isSelected ? 'white' : 'black',
          background: isSelected ? (isCorrect ? '#2ecc71' : '#E74C3C') : 'white',
        },
      };
      const buttonStyle = {
        width: `${question.isQuestionVertical ? null : '300px'}`,
        display: `${question.isQuestionVertical ? 'block' : null}`,
      };
      return state.isPointed ? (
        <Button key={answerIndex} type='text' className={`${styles.answerButton} flex flex-1 ml-4`} style={buttonStyle}>
          <Row style={{ marginLeft: 4, fontSize: question.fontSize || 22, display: 'flex', alignItems: 'center' }}>
            <strong className={styles.mutilpleKey} style={customStyles.alphabet}>
              {alphabet[answerIndex]}.
            </strong>
            <span style={{ textAlign: 'left' }}>{answer.text}</span>
            <i
              className={classNames({
                'fas fa-check': isCorrect,
                'fas fa-times': !isCorrect,
              })}
              style={{
                color,
                marginLeft: 3,
                // top: -1,
                // right: 0,
                fontSize: question.fontSize || 22,
                // position: 'absolute',
              }}
            />
          </Row>
        </Button>
      ) : (
        <Button
          type='text'
          key={answerIndex}
          className={`${styles.answerButton} flex flex-1 ml-4`}
          onClick={() => onClickAnswer(qItem, answerIndex)}
          style={buttonStyle}
        >
          <Row style={{ marginLeft: 4, fontSize: question.fontSize || 22, display: 'flex', alignItems: 'center' }}>
            {answer.text !== '' && (
              <span className={isSelected ? styles.mutilpleKeySelected : styles.mutilpleKey}>
                <strong style={{ fontSize: question.fontSize || 22 }}>{alphabet[answerIndex]}.</strong>
              </span>
            )}
            <span style={{ textAlign: 'left' }}>{answer.text}</span>
          </Row>
        </Button>
      );
    },
    [state.isPointed, onClickAnswer]
  );

  const renderQuestion = React.useCallback(
    (item, index) => {
      return (
        <div
          key={index}
          style={{ marginLeft: question.marginLeft ?? 8, fontSize: question.fontSize || 22 }}
          className='mb-3'
        >
          <b style={{ color: 'black' }}>{index + 1}</b> {renderTitle(item)}
          <div style={{ fontSize: question.fontSize || 22 }}>{item.answers.map(renderAnswerItem(item, index))}</div>
        </div>
      );
    },
    [question.marginLeft, renderTitle, renderAnswerItem]
  );

  if (!state.questions) return null;
  return (
    <Container
      className='fluid'
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
      }}
    >
      <Row style={{ width: '100%' }}>
        <TitleQuestion titleQuestion={question.titleQuestion} />
      </Row>
      {question.isQuestionVerticalToImage ? (
        <Col>
          {
            //titleImage left
            question.titleImage_2 ? (
              <Row>
                <img
                  src={question.titleImage_2}
                  alt='...'
                  style={{ width: question?.imgSize ?? '60%', marginBottom: 10 }}
                />
              </Row>
            ) : (
              ''
            )
          }
          <Row className='d-flex justify-content-center'>
            <Col className='d-initial justify-content-center'>
              <span style={{ fontSize: question.fontSize || 22 }}>{state.questions.map(renderQuestion)}</span>
            </Col>
          </Row>
        </Col>
      ) : (
        <Row>
          {
            //titleImage left
            question.titleImage_2 ? (
              <Col>
                <img
                  src={question.titleImage_2}
                  alt='...'
                  style={{ width: question?.imgSize ?? '60%', marginBottom: 10 }}
                />
              </Col>
            ) : (
              ''
            )
          }
          <Col>
            <Row className='d-flex justify-content-center'>
              <Col className='d-initial justify-content-center'>
                <span style={{ fontSize: question.fontSize || 22 }}>{state.questions.map(renderQuestion)}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <FooterModal
          exerciseKey={question.exerciseKey}
          audioUrl={question.audio}
          result={state.booleanArray}
          isDisabledSubmit={state.isDisabledSubmit}
          isDisabledRetry={state.isDisabledRetry}
          onSubmit={onSubmit}
          onRetry={onRetry}
        />
      </Row>
    </Container>
  );
};

Multiple1.propTypes = {
  question: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  sessionId: PropTypes.string,
  classId: PropTypes.string,
};

export default Multiple1;
