import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  5: {
    unit: "Cumulative",
    id: "FG10-C-P77-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page77/E5/Key/answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "5",
        title: `
            <span style="color: rgb(0, 110, 147)"> GRAMMAR AND VOCABULARY</span> 
            Choose the best options to fill in the blanks
            `,
        color: "#000",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        inputStyle: {
          borderBottom: "",
          color: "#000",
          textAlign: "center",
          maxLength: 16,
        },
        answers: [],
        initialValue: [],

      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          border: "solid 3px",
          borderColor: "gray",
          padding: "5px",
        },
        limitSelect: 1,
        listWords: [
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
          "a|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; b|&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&ensp;&nbsp;&nbsp; c|",
        ],
        answers: ['0-0', '1-4', '2-4', '3-0', '4-0', '5-8', '6-4', '7-8', '8-8', '9-4'],
        initialValue: [],
      },
      Layout: `
                <div style="height: 750px; width: 800px; border-radius: 12px; background-color: rgb(187, 215, 229)">
                    <div style="background-color: rgb(0, 111, 148); padding: 30px 0; border-radius: 16px"><h1 style="color: white; font-size: 40px; font-weight: 800; text-align: center" >HOW TO GET YOURSELF NOTICED</h1></div>
                    <div style="padding: 30px;">
                      <span>Marketing graduate Alfred Ajani, aged 22, applied
                      unsuccessfully for over three hundred positions when he
                      finished university. It seemed that the only posts available
                      were for <sup><strong>1</strong></sup>___ temporary jobs. Alfred wanted something
                      <sup><strong>2</strong></sup>___  , so he decided to try a different approach. ‘If I give
                      people my CV in person, perhaps <sup><strong>3</strong></sup>___ a job,' he thought. He
                      went to London's Waterloo Station and <sup><strong>4</strong></sup>___ at the entrance
                      with a pile of CVs and a card advertising his services. At first,
                      commuters were a <sup><strong>5</strong></sup>___ suspicious, but then they started
                      going up to him to ask what he <sup><strong>6</strong></sup>___  . During the day, he
                      met some very interesting people. When he got home, Alfred
                      received a phone call about a marketing job in Barcelona.
                      He was <sup><strong>7</strong></sup>___ because his plan had worked. He <sup><strong>8</strong></sup>___ an
                      interview for the job next week. If he <sup><strong>9</strong></sup>___ the job, he'll be
                      able to work using his studies. Of course, he <sup><strong>10</strong></sup>___ learn a new
                      language too.</span>
                    </div>
                </div>
      <div style="position: relative; width: 800px; margin-top:10px;">
        
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;1 </b></div>
          <div style='flex: 3;'>badly-paid</div>
          <div style='flex: 3;'>creative</div>
          <div style='flex: 3;'>rewarding</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;2 </b></div>
          <div style='flex: 3;'>the best</div>
          <div style='flex: 3;'>better</div>
          <div style='flex: 3;'>best</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;3 </b></div>
          <div style='flex: 3;'>I’m getting</div>
          <div style='flex: 3;'>I’ll get</div>
          <div style='flex: 3;'>I got</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;4 </b></div>
          <div style='flex: 3;'>stood</div>
          <div style='flex: 3;'>stands</div>
          <div style='flex: 3;'>standing</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;5 </b></div>
          <div style='flex: 3;'>bit</div>
          <div style='flex: 3;'>few</div>
          <div style='flex: 3;'>lot</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;6 </b></div>
          <div style='flex: 3;'>do</div>
          <div style='flex: 3;'>had done</div>
          <div style='flex: 3;'>was doing</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;7 </b></div>
          <div style='flex: 3;'>confused</div>
          <div style='flex: 3;'>delighted</div>
          <div style='flex: 3;'>disappointed</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;8 </b></div>
          <div style='flex: 3;'>would have</div>
          <div style='flex: 3;'>have</div>
          <div style='flex: 3;'>’s going to have</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>&ensp;9 </b></div>
          <div style='flex: 3;'>get</div>
          <div style='flex: 3;'>will get</div>
          <div style='flex: 3;'>gets</div>
        </div>
        <div style='display:flex; '>
          <div style='flex: 1;'><b>10</b></div>
          <div style='flex: 3;'>had to</div>
          <div style='flex: 3;'>’ll have to</div>
          <div style='flex: 3;'>have to</div>
        </div>

        <div style="position: absolute; top: 0px; left: 49px; "><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 37px; left: 49px; "><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 76px; left: 49px; "><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 113px; left: 49px; "><input id='3' type='Circle' /></div>
        <div style="position: absolute; top: 152px; left: 49px; "><input id='4' type='Circle' /></div>
        <div style="position: absolute; top: 189px; left: 49px; "><input id='5' type='Circle' /></div>
        <div style="position: absolute; top: 227px; left: 49px; "><input id='6' type='Circle' /></div>
        <div style="position: absolute; top: 264px; left: 49px; "><input id='7' type='Circle' /></div>
        <div style="position: absolute; top: 302px; left: 49px; "><input id='8' type='Circle' /></div>
        <div style="position: absolute; top: 340px; left: 49px; "><input id='9' type='Circle' /></div>

      </div>
        `,
    },
  },
  6: {
    unit: "Cumulative",
    id: "FG10-C-P77-E6",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title: `
            <span style="color: rgb(0, 110, 147)"> WRITING</span> 
            Write an application letter for the job in exercise 4. Include
            this information:
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div>
                <ul>
                  <li>what the job is and how you found out about it</li>
                  <li>why you are interested in the job</li>
                  <li>why you are the right person for the job</li>
                  <li>what you are sending with the letter and when you can
                  start work</li>
                  <li>what you would like to happen next.</li>
                </ul>
            </div>
                <textarea id="0" rows="7" ></textarea>
            `,
        answer: [],
      },
    ],
  },
};

export default json;
