import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";

const json = {
  1: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E1/Key/answerKey.png",
    component: T6,
    inputSize: 250,
    // isHiddenCheck: true,
    maxLength: 30,
    textAlign: 'center',
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "1",
        title: `
            Complete the sentences below with the present perfect
            passive form of <br/>the verbs in brackets.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <div>
                  <ol type="1" style="font-weight: bold">
                    <li><span style="font-weight: normal;">Our car # (steal).</span></li>
                    <li><span style="font-weight: normal;">Two important new drugs # (develop) this year.</span></li>
                    <li><span style="font-weight: normal;">The bus stop # (damage) again.</span></li>
                    <li><span style="font-weight: normal;">The boat # (not see) for two days.</span></li>
                    <li><span style="font-weight: normal;">All our money # (spend).</span></li>
                    <li><span style="font-weight: normal;">I hope that painting # (not sell).</span></li>
                  </ol>
                </div>
              </div>
            `,
        answer: [
          "has been stolen",
          "have been developed",
          "has been damaged ",
          "hasn't been seen ",
          "has been spent",
          "hasn't been sold",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E2/Key/answerKey.png",
    component: T6,
    inputSize: 270,
    // isHiddenCheck: true,
    maxLength: 35,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "2",
        title: `
        Look at the pictures, then complete the sentences with the
        present perfect passive form of the verbs below.
 
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
            <div >
              <div style="color: rgb(249, 157, 41); font-weight: bold; margin-bottom: 30px;">
                <span style= "margin-right: 50px">build</span>
                <span style= "margin-right: 50px">not change</span>
                <span style= "margin-right: 50px">not finish</span>
                <span style= "margin-right: 50px">paint</span>
                <span style= "margin-right: 50px">sell</span>
              </div>
              <div style="display:flex">
                <div style="position: relative">
                  <div style="padding: 5px 30px; position: absolute;top: 20px; left: 20px; background-color: white; border: 1px solid;"><span><strong>10 years ago</strong></span></div>
                  <img src="img/FriendsPlus/Page67/E2/1.jpg" alt="" width=90%/>
                </div>
                <div style="position: relative">
                  <div style="padding: 5px 30px; position: absolute;top: 20px; left: 20px; background-color: white; border: 1px solid;"><span><strong>Now</strong></span></div>
                  <img src="img/FriendsPlus/Page67/E2/2.jpg" alt="" width=90%/>
                </div>
              </div>
              <div>
                <ol type="1" style="font-weight: bold">
                  <li><span style="font-weight: normal;">The factory <i>has been sold</i> .
                  </span></li>
                  <li><span style="font-weight: normal;">The glass in the windows #
                  </span></li>
                  <li><span style="font-weight: normal;">The big front door #
                  </span></li>
                  <li><span style="font-weight: normal;">The metal sign #
                  </span></li>
                  <li><span style="font-weight: normal;">A new road #
                  </span></li>
                  <li><span style="font-weight: normal;">The new roof #
                  </span></li>
                </ol>
              </div>
            </div>
            `,
        answer: [
          "has been replaced",
          "has been painted",
          "hasn't been changed",
          "has been built",
          "hasn't been finished",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/answerKey.png",
    component: T6,
    inputSize: 500,
    // isHiddenCheck: true,
    maxLength: 150,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "3",
        title: `
        Complete the questions using the present perfect
        passive form and <i>ever</i>. Then answer the questions with
        information that is true for you.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
          <div>
            <ol type="1" style="font-weight: bold">
              <li>
              <span style="font-weight: normal;">(you / bite / by a mosquito)</br><i style="color: rgb(19, 70, 109)">Have you ever been bitten by a mosquito?</i></span>
              </li>
              <li><span style="font-weight: normal;">(your home / burgle)</br>#</span></li>
              <li><span style="font-weight: normal;">(you / chase / by a dog)</br>#</span></li>
              <li><span style="font-weight: normal;">(your phone / steal)</br>#</span></li>
            </ol>
          </div>
            `,
        answer: [
          "Has your home ever been burgled?",
          "Have you ever been chased by a dog?",
          "Has your phone ever been stolen?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E4/Key/answerKey.png",
    component: T6,
    inputSize: 580,
    // isHiddenCheck: true,
    maxLength: 100,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "4",
        title: `
        Rewrite these sentences to make them passive. Include
        <i>by …</i> to say who or what carries out the action.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div>
                <span>In the house of the future:</span>
                <ol type="1" style="font-weight: bold">
                  <li>
                  <span style="font-weight: normal;">your fridge will order your food online.</br><i style="color: rgb(19, 70, 109)">your food will be ordered online by your fridge.</i></span>
                  </li>
                  <li><span style="font-weight: normal;">the sun will provide most of your electricity</br>#</span></li>
                  <li><span style="font-weight: normal;">robots will clean all the rooms.</br>#</span></li>
                  <li><span style="font-weight: normal;">machines will prepare all of your food.</br>#</span></li>
                </ol>
              </div>
            `,
        answer: [
          "most of your electricity will be provided by the sun",
          "all the rooms will be cleaned by robots",
          "all of your food will be prepared by machines",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E5/Key/answerKey.png",
    component: T6,
    inputSize: 250,
    // isHiddenCheck: true,
    maxLength: 20,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "5",
        title: `
        Complete the dialogue using the present perfect passive
        and future form of the verbs in brackets.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
              <div style="margin: 30px 0 0 100px">
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Presenter</strong></span></div>
                  <div style="width: 600px;"><span>Can you give us the latest news on this exciting project to build a hotel on the moon?</span></div>
                </div>
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Scientist</strong></span></div>
                  <div style="width: 600px;"><span>The plans for the new hotel <sup><strong>1</strong></sup> #
                  (complete) and a location <sup><strong>2</strong></sup> #
                  (choose).</span></div>
                </div>
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Presenter</strong></span></div>
                  <div style="width: 600px;"><span>So when <sup><strong>3</strong></sup> # (it / build)?</span></div>
                </div>
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Scientist</strong></span></div>
                  <div style="width: 600px;"><span>Well, the exact date <sup><strong>4</strong></sup> # (not
                    decide) until next month at the United Nations
                    meeting. The plans <sup><strong>5</strong></sup> # (approve)
                    at that meeting too.</span></div>
                </div>
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Presenter</strong></span></div>
                  <div style="width: 600px;"><span>And after that?</span></div>
                </div>
                <div style = "display: flex; margin-bottom: 10px">
                  <div style="width: 200px;"><span><strong>Scientist</strong></span></div>
                  <div style="width: 600px;"><span>After that, the first parts of the hotel
                  <sup><strong>6</strong></sup> # (send) to the moon before
                  the end of the year.</span></div>
                </div>
              </div>
            `,
        answer: [
          "have been completed",
          "has been chosen",
          "will it be built",
          "will not be decided",
          "will be approved",
          "will be sent",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FG10-U8-P67-E6",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page67/E6/Key/answerKey.png",
    component: T6,
    inputSize: 250,
    // isHiddenCheck: true,
    maxLength: 20,
    textAlign: 'center',
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "6",
        title: `
        Complete the following sentences about a new software,
        using the correct passive voice.
            `,
        color: "#000",
      },
    ],
    questions: [
      {
        title: `
        <div style="margin-top:  30px">
          <ol type="1" style="font-weight: bold">
            <li><span style="font-weight: normal;">Possible replies to email # (can suggest) for
            your convenience.</span></li>
            <li><span style="font-weight: normal;">Attachments # can store) for ever.</span></li>
            <li><span style="font-weight: normal;">Automatic replies # (may send) when new
            mails arrive.</span></li>
            <li><span style="font-weight: normal;">Updates # (can access) at no extra cost.</span></li>
            <li><span style="font-weight: normal;">Certain features # (may personalise) for
            businesses.</span></li>
          </ol>
        </div>
            `,
        answer: [
          "can be suggested",
          "can be stored",
          "may be sent",
          "can be accessed",
          "may be personalised",
        ],
      },
    ],
  },
};

export default json;
