import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 3",
    id: "WB10-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E1/key/answerKey.png",
    component: T6,
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "1",
        title: `
				Write the phrases 1–10 in the correct groups (A–C) below.
			  `,
        color: "black",
      },
    ],
    stylesTextInput: {
      width: 40,
      height: 40,
      borderRadius: 5,
      borderBottom: "dash",
      textAlign: "center",
      border: "2px solid black",
      // backgroundColor: "#fee4cb",
      fontSize: 22,
    },
    maxLength: 1,
    // inputSize: 200,

    titleImage: "",
    questions: [
      {
        title: `
         <div style='display:flex;line-height:55px'>
          <div style='margin-right:40px'>
            <b>1</b>&ensp;I adore / I can't stand … #<br>
            <b>2</b>&ensp;That's agreed, then. #<br>
            <b>3</b>&ensp;I think … will be better / more fun, etc.  #<br>
            <b>4</b>&ensp;I'd rather / I'd prefer (to) …  #<br>
            <b>5</b>&ensp;I'm / I'm not a big fan of … #<br>
            <b>6</b>&ensp;OK, I agree. #<br>
            <b>7</b>&ensp;I'm / I'm not keen on … #<br>
            <b>8</b>&ensp;Let's / Shall we settle on … #<br>
            <b>9</b>&ensp;That's a great idea. #<br>
            <b>10</b>&ensp;I quite fancy / I don't really fancy … #<br>
          </div>
            <div style='line-height:50px'>
                 <b>A</b>&ensp;Expressing likes and dislikes<br>
                 <b>B</b>&ensp;Expressing a preference<br>
                 <b>C</b>&ensp;Reaching an agreement<br>
          
            </div>
        </div>
        

			  `,
        answer: ["A", "C", "B", "B", "A", "C", "A", "C", "C", "A"],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "WB10-U3-P30-E2",
    audio: "Audios/1.11.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page30/E2/key/answerKey.png",
    // selectStyle: {
    //   color: "gray",
    //   fontWeight: "bold",
    //   width: 40,
    //   textAlign: "center",
    //   border: "2px solid black",
    //   borderRadius: "20%",
    // },
    isHiddenCheck: true,

    selectOptionValues: ["✓", "✗"],
    titleQuestion: [
      {
        num: "2",
        title: `
				<headphone1  name='1.11' src=''></headphone1> Read the task below. Then listen to a student
doing the task. Which game did they choose?
			  `,
        color: "black",
      },
    ],
    inputSize: 610,
    // stylesTextInput: {
    //   borderBottom: "dash",
    textAlign: "center",
    //   backgroundColor: "#fee4cb",
    //   fontSize: 18,
    //   height: 19,
    // },
    maxLength: 52,

    titleImage: "",
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page30/E2/1.jpg' style='width:1000px'/> 
        <div style='margin:0px 205px;'>
          #
        </div>

			  `,
        answer: [
          "The student chose the second game, the driving game. / the driving game.",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "WB10-U3-P30-E3",
    audio: "Audios/1.11.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page30/E3/key/answerKey.png",

    component: DesignUnderLine,
    // recorder: true,
    titleQuestion: [
      {
        num: "3",
        title: `<headphone1  name='1.11'></headphone1> Listen again. Tick (✓) the phrases in exercise 1 that
        they use.`,
        color: "black",
      },
    ],
    totalInput: 6,
    questionImage: [
      // Row
      [
        { url: "img/FriendsPlus/Page30/E3/1.jpg" },
        { url: "img/FriendsPlus/Page30/E3/2.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/3.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/4.jpg" },
        { url: "img/FriendsPlus/Page30/E3/5.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/7.jpg" },
        { url: "img/FriendsPlus/Page30/E3/8.jpg", input: 3 },
        { url: "img/FriendsPlus/Page30/E3/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/10.jpg" },
        { url: "img/FriendsPlus/Page30/E3/11.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/13.jpg" },
        { url: "img/FriendsPlus/Page30/E3/14.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/15.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/16.jpg" },
        { url: "img/FriendsPlus/Page30/E3/17.jpg", input: 6 },
        { url: "img/FriendsPlus/Page30/E3/18.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/19.jpg" },
        { url: "img/FriendsPlus/Page30/E3/20.jpg", input: 7 },
        { url: "img/FriendsPlus/Page30/E3/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/22.jpg" },
        { url: "img/FriendsPlus/Page30/E3/23.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/24.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/25.jpg" },
        { url: "img/FriendsPlus/Page30/E3/26.jpg", input: 9, isCorrect: true },
        { url: "img/FriendsPlus/Page30/E3/27.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page30/E3/28.jpg" },
        { url: "img/FriendsPlus/Page30/E3/29.jpg", input: 10 },
        { url: "img/FriendsPlus/Page30/E3/30.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page30/E3/31.jpg" }],
    ],
  },
  4: {
    unit: "Unit 3",
    id: "WB10-U3-P30-E4",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title: `
					Read the <span style='color:rgb(2 146 69)'>
					Speaking Strategy
						</span>. Then do the task in exercise 2
					using the video games below. Write notes.
			  `,
        color: "black",
      },
    ],
    maxLength: 11,
    inputSize: 500,
    textareaStyle: { width: 500, color: "black" },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    questions: [
      {
        title: `
					<img src='img/FriendsPlus/Page30/E4/1.jpg' />
					<div style='display:flex'>
						<div>
						My choice<br>#<br>
						Why<br> <textarea id='1' rows='2'></textarea>
						</div>
						<div style='margin-left:20px'>
						Play the game where? Why?<br>
						<textarea id='2' rows='2'></textarea>
						<br> 
						Invite other people? Why? / Why not?<br>
						<textarea id='3' rows='3'></textarea>
						</div>
					</div>
			  	`,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "WB10-U3-P30-E5",
    audio: "",
    video: "",
    exerciseKey: "",
    component: T6,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: "5",
        title: `
					Now do the task in exercise 2, using your notes from exercise 4.
				`,
        color: "black",
      },
    ],
    maxLength: 14,
    inputSize: 180,
    questions: [
      {
        title: `
				`,
        answer: [],
      },
    ],
  },
};

export default json;
