import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
	1: {
		unit: 'Cumulative',
		id: 'FG10-C-P80-E1',
		audio: 'Audios/2.15.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page80/E1/Key/answerKey.png',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `
        <headphone name="2.15"></headphone><span style="color: rgb(0, 110, 147)">LISTENING</span> 
        Listen to four conversations about gadgets and
		choose the best answers.
        `,
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: "none",
					borderRadius: "50%",
					borderColor: "transparent",
					color: "black",
					fontWeight: "bold",
				},
				selectWordStyle: {
					padding: 5,
					border: "solid 3px",
					borderColor: "gray",
				},
				limitSelect: 1,
				listWords: [
					"a <br/> b <br/> c",
					"a <br/> b <br/> c",
					"a <br/> b <br/> c",
					"a <br/> b <br/> c",
				],
				answers: ['0-0', '1-0', '2-4', '3-4'],
				initialValue: [],
			},
			Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
          <div>
            <div> Listen to an answerphone message. What is the speaker’s intention?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='0' type='Circle' /></div>
              <div>
                <div> To apologise to her friend</div>
                <div> To tell her friend about her holiday</div>
                <div> To thank her friend</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>2 &emsp;</b>
          <div>
            <div> Listen to a conversation at the police station. What colour is the tablet?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='1' type='Circle' /></div>
              <div>
                <div> Black</div>
                <div> Purple</div>
                <div> White</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>3 &emsp;</b>
          <div>
            <div> Listen to a radio announcement. What is the speaker’s intention?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='2' type='Circle' /></div>
              <div>
                <div> To challenge the listeners</div>
                <div> To persuade the listeners to do something</div>
                <div> To describe something to the listeners</div>
              </div>
            </div>
          </div>
        </div>

        <div style='display:flex'>
          <b>4 &emsp;</b>
          <div>
            <div> Listen to a conversation between a mother and her son. What does she give him for his birthday?</div>
            <div style='display:flex'>
              <div style='width:40px;'><input id='3' type='Circle' /></div>
              <div>
                <div> A computer</div>
                <div> A games console</div>
                <div> A television</div>
              </div>
            </div>
          </div>
        </div>

      `,
		},
	},
	2: {
		unit: 'Cumulative',
		id: 'FG10-C-P80-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		hideBtnFooter: true,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '2',
				title: `
		<span style="color: rgb(0, 110, 147);">SPEAKING</span>
		Work in pairs. Look at the photo and answer the questions.`,
			},
		],
		questions: [
			{
				title: `
		<div>
			<div>
				<div style="display: flex; margin-bottom: 10px">
					<span style="font-weight: bold; padding-right: 10px;">1</span> 
					<span>How do you think the person is feeling?</span>
				</div>
				<div style="display: flex; margin-bottom: 10px"">
					<span style="font-weight: bold; padding-right: 10px;">2</span> 
					<span>Which is worse in your opinion, a broken computer or a
					broken mobile phone?</span>
				</div>
				<div style="display: flex; margin-bottom: 10px"">
					<span style="font-weight: bold; padding-right: 10px;">3</span> 
					<span>When was the last time you had a problem with a
					gadget? What happened?</span>
				</div>
			</div>
			<div>
				<img src="img/FriendsPlus/Page80/E2/1.jpg" alt="image" />
			</div>
		</div>
				`,
				answer: [],
			},
		],
	},
	3: {
		unit: 'Cumulative',
		id: 'FG10-C-P80-E3',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page80/E3/Key/answerKey.png',
		component: T6,
		inputSize: 70,
		maxLength: 4,
		titleQuestion: [
			{
				num: '3',
				title: `
          <span style="color: rgb(0, 110, 147);">READING</span>
          Match texts A–D with questions 1–6 below. 
          Some questions match with more than one text.
        `,
			},
		],
		questions: [
			{
				title: `
        <div>
			<strong>Which invention(s) …</strong>
			<div>
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">1</span> 
					<span>was / were discovered during the scientist's free time? #</span>
				</div>
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">2</span> 
					<span>was / were made when scientists were working on something else?   #</span>
				</div >
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">3</span> 
					<span>was / were previously used for something different?  #</span>
				</div>
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">4</span> 
					<span>resulted from something getting too hot?  #</span>
				</div>
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">5</span> 
					<span>is / are used for joining two things together?  #</span>
				</div>
				<div style="display: flex">
					<span style="font-weight: bold; padding-right: 10px;">6</span> 
					<span>took the longest to be developed?  #</span>
				</div>
			</div>
			<div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 150px 30px; margin-top: 20px;">
				<div style="position: relative;">
					<div style="border: 2px solid rgb(33, 133, 198); padding: 20px 20px 150px 20px; border-radius: 12px">
						<h2 style="color: black;">A MICROWAVE OVEN</h2>
						<span style="font-size: 15px; font-weight: 600; line-height: 10px;">This useful kitchen device was invented by an electronics
						genius called Percy Spencer. Spencer had been in the Navy,
						where he had taught himself how to be an engineer. When
						he left, he got a job at Raytheon, an important company in
						the arms industry. In 1945, Spencer was experimenting with
						radar when something strange happened. He noticed that a
						bar of chocolate in his pocket had melted. Spencer worked
						out that it was the microwaves from the radar that had
						heated the chocolate. Spencer continued investigating the
						effects of microwaves on food and developed the microwave
						oven. The first machine was sold just a year after Spencer
						had made his discovery. </span>

					</div>
					<img src="img/FriendsPlus/Page80/E3/1.jpg" alt="image" style="height: auto; width: 400px; position: absolute;
					bottom: -100px;" >
				</div>
				<div style="position: relative;">
					<div style="border: 2px solid rgb(33, 133, 198); padding: 20px 20px 150px 20px; border-radius: 12px">
						<h2 style="color: black;">B VELCRO</h2>
						<span style="font-size: 15px; font-weight: 600; line-height: 10px;">Velcro was invented by
						a Swiss engineer called
						George de Mestral. In
						1948, Mestral was out
						walking with his dog when
						he noticed that the dog was covered in
						small green balls. The balls were the seeds
						of a plant which was common in the area.
						Mestral wanted to know what made these balls stick to his
						dog's fur, so he looked at one of them under his microscope.
						He saw that it had a lot of tiny hooks. Mestral realised that this
						technique could be used to make two strips of material stick
						together. He experimented with a variety of materials for years
						until the invention of nylon, which was perfect for his idea. The
						first Velcro was finally produced in 1955.</span>

					</div>
					<img src="img/FriendsPlus/Page80/E3/2.jpg" alt="image" style="height: auto; width: 300px; position: absolute;
					bottom: -100px;" >
				</div>
				<div style="position: relative;">
					<div style="border: 2px solid rgb(33, 133, 198); padding: 20px 20px 150px 20px ; border-radius: 12px">
						<h2 style="color: black;">C PLAY-DOH</h2>
						<span style="font-size: 15px; font-weight: 600; line-height: 10px;">The children's toy Play-Doh actually
						started life as a cleaning product. It
						was made by an American company
						called Kutol Products and it was
						used to clean wallpaper. Sadly, the
						wallpaper cleaner did not sell very
						well, so Kutol Products began losing
						money. That is, until the company
						realised that its product was being
						used for a different purpose. Children
						had started using it to make Christmas ornaments in the shape
						of people and animals. Kutol Products adapted their product
						almost overnight by taking out the cleaning ingredient and adding
						colours and a nice smell. They also gave it a new name. Play-Doh
						went on to become one of the best-selling children's toys ever</span>

					</div>
					<img src="img/FriendsPlus/Page80/E3/3.jpg" alt="image" style="height: auto; width: 300px; position: absolute;bottom: -180px;left: 0;" >
				</div>
				<div style="position: relative;">
					<div style="border: 2px solid rgb(33, 133, 198); padding: 20px 20px 150px 20px; border-radius: 12px">
						<h2 style="color: black;">D SUPERGLUE</h2>
						<span style="font-size: 15px; font-weight: 600; line-height: 10px;">In 1942, Dr Harry Coover of Eastman-Kodak Laboratories was
						trying to find a new material to use for making part of a gun.
						He was disappointed to see that his latest effort, a substance
						called cyanoacrylate, was a complete failure because it stuck to
						everything it touched. Coover threw it away and forgot about it.
						Six years later, he came across cyanoacrylate once more when
						his company was developing a new design for part of a plane.
						Again, the substance stuck to everything in sight. But this time,
						Coover realised that the substance might be useful, because it
						didn't need heat to make it stick. Coover carried on
						experimenting with cyanoacrylate in his lab,
						and it appeared in DIY stores as superglue
						sixteen years after he had
						first used it.</span>

					</div>
					<img src="img/FriendsPlus/Page80/E3/4.jpg" alt="image" style="height: auto; width: 400px; position: absolute;
					bottom: -110px;" >
				</div>
			</div>
		</div>
				`,
				answer: [`B`, `A, D`, `C`, `A`, `B, D`, `D`],
			},
		],
	},
};

export default json;
