import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';

const json = {
	1: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E1',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
		question: {
			Write: {
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				// initialWordStyle: { margin: '5px 0', border: 'solid 2px', borderColor: 'black', borderRadius: '50%', padding: '0 5px' },
				initialWordStyle: {
					margin: '5px 0',
					border: 'none',
					borderColor: 'transparent',
					borderRadius: '50%',
				},
				selectWordStyle: {
					border: 'solid 2px',
					borderColor: 'rgb(187, 7, 59)',
					margin: '-2px',
					padding: 5,
				},
				limitSelect: 1,
				listWords: [
					'do / make',
					'have / tell',
					'give / make ',
					'keep / take ',
					'made / took',
					'make / tell',
					'do / take',
					'give / tell',
				],
				answers: ['0-4', '1-0', '2-0', '3-4', '4-0', '5-4', '6-4', '7-4'],
				initialValue: [],
			},
			Layout: `
        <div style='position:relative;'>
          <div><img src='img/FriendsPlus/Page10/E1/1.jpg' /></div>
          <div style="position: absolute; left: 75px; top: 25px">
            <b style="font-size: 28px"> 1 &emsp; Choose the correct verb.</b>
            <div style="margin-left: 30px">
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; ">We should <b><input id='0' type='Circle' /></b> a plan for the weekend. </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">Can you <b><input id='1' type='Circle' /></b> a word with your sister? </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">You should <b><input id='2' type='Circle' /></b> her a call and apologise. </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">Don't <b><input id='3' type='Circle' /></b> offence. It was only a joke!</div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">I <b><input id='4' type='Circle' /></b> an excuse and left the party early </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1; ">Is it always wrong to <b><input id='5' type='Circle' /></b> a lie? </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7 </b></div><div style="margin-left: 30px; flex: 1; ">I'm really tired. Can we <b><input id='6' type='Circle' /></b> a break now? </div></div>
              <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8 </b></div><div style="margin-left: 30px; flex: 1; ">I nearly always <b><input id='7' type='Circle' /></b> the truth. </div></div>
            </div>  
          </div>
        </div>
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E2',
		component: T6,
		stylesTextInput: {
			padding: 0,
			fontSize: 22,
			textAlign: 'center',
		},
		exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
		inputSize: 180,
		titleQuestion: [
			{
				num: '2',
				title: `Complete the advice with should or shouldn't and the verbs below.`,
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
              <div style="color: rgb(187, 7, 59); display: inline-flex; font-weight: bold; gap: 30px; flex-wrap: wrap; width: 660px; line-height: 10px; margin: 15px">
                <div>feel</div>
                <div>go</div>
                <div>invite</div>
                <div>listen</div>
                <div>look</div>
                <div>open</div>
                <div>spend</div>
                <div>tell</div>
              </div>
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> ‘It's really hot in here.' ‘We <span style="color: rgb(0, 68, 98)">should open</span> a window.'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; ">My ears are hurting.' ‘You # to loud music so often.'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; ">‘I think we're lost.' ‘Yes. We # at a map.'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1; ">‘I only got 68% in my exam.' ‘You # disappointed. That's a good mark!'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>5 </b></div><div style="margin-left: 30px; flex: 1; ">‘I really like the girl next door.' ‘We # her to our party.</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>6 </b></div><div style="margin-left: 30px; flex: 1; ">‘Do you like Reece's new shirt?' ‘No – but I don't think you # him that.'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>7 </b></div><div style="margin-left: 30px; flex: 1; ">‘My science project isn't very good.' ‘Maybe you # more time on it.'</div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>8 </b></div><div style="margin-left: 30px; flex: 1; ">‘I don't feel well.' ‘You # to bed early.</div></div>
              </div>
        `,
				answer: [
					"shouldn't listen ",
					'should look ',
					"shouldn't feel ",
					'should invite ',
					'should tell ',
					'should spend ',
					'should go',
				],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E3',
		component: T6,
		textareaStyle: { width: 1000 },
		exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
		inputSize: 180,
		titleQuestion: [
			{
				num: '3',
				title: `Write advice for these problems. Use I think / I don't think you should … and your own ideas.`,
			},
		],
		isHiddenCheck: true,
		hideBtnFooter: true,
		questions: [
			{
				title: `
              <div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1; "> My best friend gave me a T-shirt for my birthday, but I hate it.<textarea></textarea></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1; "> I want to go to the cinema this weekend, but I haven't got any money. <textarea></textarea></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1; "> I borrowed a DVD from my friend, but I've lost it.<textarea></textarea></div></div>
              </div>
        `,
				answer: [],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E4',
		audio: 'Audios/1.03.mp3',
		component: MC2,
		fontSize: 22,
		exerciseKey: 'img/FriendsPlus/Page10/E4/Key/answerKey.png',
		titleQuestion: [
			{
				num: '4',
				title:
					" <headphone name='1.03'></headphone>Read the <span style='color: rgb(195, 9, 68)'>Listening Strategy</span>. Then listen to two short dialogues. For each one, choose the best summary of the general meaning (a or b).",
			},
		],
		titleImage_2: 'img/FriendsPlus/Page10/E4/1.jpg',
		isQuestionVertical: true,
		isQuestionVerticalToImage: true,
		questions: [
			{
				title: `Ellie is upset because`,
				answers: [
					{
						image: '',
						isCorrect: true,
						right: '',
						text: `she can't take part in the school show.`,
					},
					{
						image: '',
						isCorrect: false,
						right: '',
						text: `she can't go to a family wedding.`,
					},
				],
				no: 1,
				type: '',
			},
			{
				title: `Daisy is excited because`,
				answers: [
					{
						image: '',
						isCorrect: false,
						right: '',
						text: 'she likes seeing her cousin.',
					},
					{
						image: '',
						isCorrect: true,
						right: '',
						text: 'her cousin has got a present for her.',
					},
				],
				no: 2,
				type: '',
			},
		],
	},
	5: {
		// Exercise num
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E5',
		audio: 'Audios/1.04.mp3',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			borderRadius: '10px',
			border: '1px solid',
			padding: 10,
			fontSize: 22,
			textTransform: 'uppercase',
		},
		titleQuestion: [
			{
				num: '5',
				title:
					'<headphone name="1.04"></headphone>Listen to two dialogues (A and B). Match the dialogues with sentences 1 - 3 below. There is one extra sentence.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page10/E5/Key/answerKey.png',
		inputSize: 38,
		maxLength: 1,
		questionImage: [],
		questions: [
			{
				title: `
               <div>
                <b>The person with the problem:</b>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">asks for advice, but decides not to follow it.  </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">gets angry when their friend gives advice.  </div><div style="flex: 1"> #</div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">does not get any advice from their friend.  </div><div style="flex: 1"> #</div></div></div>
              </div>
        `,
				answer: ['b', 'none', 'a'],
			},
		],
	},
	6: {
		unit: 'Unit 1',
		id: 'WB10-U1-P10-E6',
		audio: 'Audios/1.04.mp3',
		component: T6,
		isHiddenCheck: true,
		stylesTextInput: {
			backgroundColor: 'transparent',
			bordeRadius: '10px',
			borderBottom: '1px solid',
			padding: 10,
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '6',
				title:
					'<headphone name="1.04"></headphone>Listen again. Complete the missing words in these lines from the dialogues.',
			},
		],
		exerciseKey: 'img/FriendsPlus/Page10/E6/Key/answerKey.png',
		inputSize: 168,
		questionImage: [],
		questions: [
			{
				title: `
              <div>
                <b>Dialogue A</b>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>1 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">I # what to do. </div></div></div>
                <div style="display: flex; margin-bottom: 40px"><div style="display: inline-block; width: 40px; text-align: right;"><b>2 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">Oh dear, I # problem. </div></div></div>
                <b>Dialogue B</b>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>3 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">Can I # something? </div></div></div>
                <div style="display: flex"><div style="display: inline-block; width: 40px; text-align: right;"><b>4 </b></div><div style="margin-left: 30px; flex: 1;display: flex "><div style="flex: 12">Just talk to him. # advice. </div></div></div>
              </div>
        `,
				answer: ["just don't know", 'see your', 'ask you', "That's my"],
			},
		],
	},
};

export default json;
