import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P34-E1',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page34/E1/Key/answerKey.png',
		inputSize: 200,
		textAlign: 'center',
		titleQuestion: [
			{
				num: '1',
				title: 'Match the words below with definitions 1–10.',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style='color: rgb(195, 11, 69); margin:0px 40px 20px 40px;'><b>
					avalanche &emsp; drought &emsp; earthquake &emsp; epidemic &emsp; famine &emsp; flood &emsp; forest fire &emsp;<br/> mudslide &emsp; tsunami &emsp; volcanic eruption
				</b></div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;1</b>
					<div>A(n) # is when the ground shakes and buildings collapse.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;2</b>
					<div>A(n) # is when hot rocks, fire and steam suddenly come out of the ground.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;3</b>
					<div>A(n) # is when water covers the ground in places which are usually dry.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;4</b>
					<div>A(n) # is when snow and ice fall quickly down a mountain.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;5</b>
					<div>A(n) # is a long period of time without enough food in a region.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;6</b>
					<div>A(n) # is a very large and long wave following an earthquake under the ocean.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;7</b>
					<div>A(n) # is when there is no rain for a long time.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;8</b>
					<div>A(n) # is a fire that spreads quickly through a large area of trees.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>&ensp;9</b>
					<div>A(n) # is when a serious disease spreads to lots of people.</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>10</b>
					<div>A(n) # is when a large quantity of wet earth suddenly falls down the side of a hill.</div>
				</div>
        `,
				answer: [
					'earthquake',
					'volcanic eruption',
					'flood',
					'avalanche',
					'famine',
					'tsunami',
					'drought',
					'forest fire',
					'epidemic',
					'mudslide',
				],
			},
		],
	},
	2: {
		unit: 'Unit 4',
		id: 'WB10-U4-P34-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page34/E2/Key/answerKey.png',
		inputSize: 200,
		textAlign: 'center',
		hintBox: [
			{
				src: [
					'avalanche',
					'drought',
					'earthquake',
					'epidemic',
					'famine',
					'flood',
					'forest fire',
					'mudslide',
					'tsunami',
					'volcanic eruption',
				],
				// arrow: true,
				width: 800,
				borderColor: '#c30b45',
			},
		],
		titleQuestion: [
			{
				num: '2',
				title:
					'Label photos A and B with the natural disaster words from exercise 1.',
				color: '#000',
			},
		],
		questions: [
			{
				title: `
        		<img src='img/FriendsPlus/Page34/E2/1.jpg' style='width:450px; margin-left: 0px;'/>
        		<img src='img/FriendsPlus/Page34/E2/2.jpg' style='width:450px; margin-left: 0px;'/>
				
				<div style='display:flex; gap:245px;'>
					<div><b>A </b> #</div>
					<div><b>B </b> #</div>
				</div>
        `,
				answer: ['volcanic eruption', 'drought'],
			},
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P34-E3',
		audio: 'Audios/1.13.mp3',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page34/E3/Key/answerKey.png',
		textAlign: 'left',
		inputSize: 620,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '3',
				title: `<headphone1 name='1.13'></headphone1> Read the  <span style='color:rgb(199, 22, 76);'>Listening Strategy</span>. Then listen to two dialogues and answer the questions. Write the clues which gave you the answers.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style='background-color: rgb(249, 227, 229); margin-top: 20px; margin-left:100px; width:700px; border-radius: 15px; border: 5px dotted rgb(199, 22, 76)'>
					<div style='padding: 15px 25px 25px 25px;' >
						<div ><b style='color:rgb(199, 22, 76) ;'>Listening Strategy</b></div>
						<div>It is important to be able to identify the context of the listening, i.e. who is speaking, where and when they are speaking, and what the situation is. This is not always obvious, so listen carefully for clues to help you.
						</div>
					</div>
				</div>
				<b>Dialogue 1</b>
				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>
						<div>Where are they? </div>
						<div><input id='0' /></div>
						<div>Clues: <input id='1' /></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>
						<div>What natural disaster caused the damage? </div>
						<div><input id='2' /></div>
						<div>Clues: <input id='3' /></div>
					</div>
				</div>
				<b>Dialogue 2</b>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>
						<div>What kind of natural disaster are they discussing?</div>
						<div><input id='4' /></div>
						<div>Clues: <input id='5' /></div>
					</div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>4</b>
					<div>
						<div>What time of day is it?</div>
						<div><input id='6' /></div>
						<div>Clues: <input id='7' /></div>
					</div>
				</div>

        `,
				answer: [
					"They are in Mrs Dennington's home.",
					"She says 'Come in'.",
					'A flood caused the damage.',
					'twenty centimetres deep in my living room!',
					"They're discussing a drought.",
					"They need rain!; it didn't rain at all; The ground is so dry.",
					'It is early morning; they are having breakfast.',
					"(the shop) isn't open yet; toast and jam is just fine.",
				],
			},
		],
	},
	4: {
		// Exercise num
		unit: 'Unit 4',
		id: 'WB10-U4-P34-E4',
		audio: 'Audios/1.14.mp3',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page34/E4/Key/answerKey.png',
		textAlign: 'left',
		inputSize: 100,
		titleQuestion: [
			{
				num: '4',
				title: `<headphone1 name='1.14'></headphone1> Listen to three extracts one at a time. Match each extract with a natural disaster from exercise 1.
				`,
				color: '#000',
			},
		],
		questions: [
			{
				title: `
				<div style='display:flex; gap:15px;'>
					<b>1</b>
					<div>Natural disaster: <input id='1' width='125px'></div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>2</b>
					<div>Natural disaster: <input id='1' width='125px'></div>
				</div>
				<div style='display:flex; gap:15px;'>
					<b>3</b>
					<div>Natural disaster: <input id='1' width='125px'></div>
				</div>

        `,
				answer: ['famine', 'tsunami', 'epidemic'],
			},
		],
	},

	5: {
		unit: 'Unit 4',
		id: 'WB10-U4-P34-E5',
		audio: 'Audios/1.14.mp3',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page34/E5/Key/answerKey.png',
		titleQuestion: [
			{
				num: '5',
				title:
					"<headphone1 name='1.14'></headphone1>  Listen again and choose the correct answers.",
				color: 'black',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: 'black',
					fontWeight: 'bold',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 3px',
					borderColor: 'gray',
				},
				limitSelect: 1,
				listWords: [
					'a <br/> b <br/> c',
					'a <br/> b <br/> c',
					'a <br/> b <br/> c',
				],
				answers: ['0-4', '1-4', '2-8'],
				initialValue: [],
			},
			Layout: `
        <div style='display:flex'>
          <b>1 &emsp;</b>
		  <div>
			<div>The dialogue takes place</div>
			<div style='display:flex'>
				<div><input id='0' type='Circle' /> &ensp;</div>
				<div>
					in a shopping centre.<br/>
					in the street.<br/>
					in a supermarket.<br/>
				</div>
			</div>
		  </div>
        </div>
        <div style='display:flex'>
          <b>2 &emsp;</b>
		  <div>
			<div>The disaster happened while the speaker</div>
			<div style='display:flex'>
				<div><input id='1' type='Circle' /> &ensp;</div>
				<div>
					was on holiday in Japan.<br/>
					was in a business meeting.<br/>
					was in a Japanese lesson.<br/>
				</div>
			</div>
		  </div>
        </div>
        <div style='display:flex'>
          <b>3 &emsp;</b>
		  <div>
			<div>The speaker first went to Africa</div>
			<div style='display:flex'>
				<div><input id='2' type='Circle' /> &ensp;</div>
				<div>
					about three years ago.<br/>
					about two years ago.<br/>
					when he finished university.<br/>
				</div>
			</div>
		  </div>
        </div>

        
      `,
		},
	},
}

export default json
