import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    unit: 'Cumulative',
    id: 'FG10-C-P78-E1',
    audio: 'Audios/2.14.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page78/E1/Key/answerKey.png',
    component: T6,
    inputSize: 200,
    maxLength: 21,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '1',
        title: `
        <headphone name="2.14"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
        Listen to four speakers talking about their
shopping habits. Match speakers 1–4 with the item
below that they are most likely to buy.
        `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
          <b style="color: rgb(50, 113, 157); margi: 20px">
            <span style="margin-right: 40px">an album</span>
            <span style="margin-right: 40px">an electronic device </span>
            <span style="margin-right: 40px">earrings</span>
            <span style="margin-right: 40px">a guitar</span>
            <span style="margin-right: 40px">an e-book</span><br/>
            <span style="margin-right: 40px">some potatoes</span>
            <span style="margin-right: 40px">a dress</span>
          </b>
          <div>
            <ul style= "list-style: none">
              <li>Speaker 1:  #</li>
              <li>Speaker 2:  #</li>
              <li>Speaker 3:  #</li>
              <li>Speaker 4:  #</li>
            </ul>
          </div>
        `,
        answer: ['a dress', 'an e-book', 'some potatoes', 'an album'],
      },
    ],
  },

  2: {
    unit: 'Cumulative',
    id: 'FG10-C-P78-E2',
    audio: 'Audios/2.14.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page78/E2/Key/answerKey.png',
    component: T6,
    inputSize: 40,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '2',
        title: `
        <headphone name="2.14"></headphone><span style="color: rgb(0, 110, 147)"> LISTENING</span> 
         Listen again. Match speakers 1–4 with
        sentences A–E. There is one extra sentence.
        `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
          <div>
            <ul style= "list-style: none">
              <li><span style="margin-right: 10px"><strong>A</strong></span>Speaker ____ buys something which is not often
              used today.</li>
              <li><span style="margin-right: 10px"><strong>B</strong></span>Speaker ____ spends money online</li>
              <li><span style="margin-right: 10px"><strong>C</strong></span>Speaker ____ only saves money by buying a lot of
              something.</li>
              <li><span style="margin-right: 10px"><strong>D</strong></span>Speaker ____ pays for things with somebody else's
              money</li>
              <li><span style="margin-right: 10px"><strong>E</strong></span>Speaker ____ is running out of space for all the
              things he / she buys.</li>
            </ul>
          </div>
          <div>
            <strong>
              <span style="margin-right: 10px">ANSWER: </span>
              <span style="margin-right: 40px">1: #</span>
              <span style="margin-right: 40px">2: #</span>
              <span style="margin-right: 40px">3: #</span>
              <span style="margin-right: 40px">4: #</span>
            </strong>
          </div>
        `,
        answer: ['E', 'B', 'C', 'A'],
      },
    ],
  },

  3: {
    unit: 'Cumulative',
    id: 'FG10-C-P78-E3',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T6,
    textareaStyle: { width: 1000, resize: 'none', borderBottom: 'none' },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '3',
        title: `
        <span style="color: rgb(0, 110, 147)"> SPEAKING</span> 
         Work in pairs. There are plans to open new businesses
        in your town. Which one of the shops and services
        below would be most successful and why? Why are
        you rejecting the other options? Write notes below.
        `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
          <b style="color: rgb(50, 113, 157); margin-bottom: 20px">
            <span style="margin-right: 40px">baker's</span>
            <span style="margin-right: 40px">charity shop </span>
            <span style="margin-right: 40px">clothes shop</span>
            <span style="margin-right: 40px">estate agent's</span>
            <span style="margin-right: 40px">jeweller's</span>
            <span style="margin-right: 40px">takeaway</span>
          </b>
          <div>                
            <textarea id="0" rows="3" >Choices:</textarea>
            <textarea id="1" rows="3" >Reasons for rejecting other options:</textarea>
          </div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: 'Cumulative',
    id: 'FG10-C-P78-E4',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page78/E4/Key/answerKey.png',
    component: Circle_Write,
    inputSize: 40,
    maxLength: 1,
    // hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '4',
        title: `
        <span style="color: rgb(0, 110, 147)"> READING</span> 
         Read the three texts. For each text, choose the
        correct answer (a, b or c).
        `,
        color: '#000',
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'bold',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 3px',
          borderColor: 'gray',
        },
        limitSelect: 1,
        listWords: ['a <br/> b <br/> c', 'a <br/> b <br/> c', 'a <br/> b <br/> c'],
        answers: ['0-4', '1-0', '2-8'],
        initialValue: [],
      },
      Layout: `
          <div style="display: flex; margin-top: 20px; margin-left:-10px;gap: 20px">
            <div style="display: flex; flex-direction: column;">
              <div style="display: flex; justify-content: center; align-items: center; gap: 20px; margin-bottom: 20px">
                 <div style="position: relative">
                    <div style="border: 1px solid; border-radius: 100rem;  height: 48px; width: 48px; position: absolute; top: -15px; left: -15px; background-color: rgb(0, 110, 147);color: white; display: flex; justify-content: center; align-items: center;"><strong>1</strong></div>
                    <img src="img/FriendsPlus/Page78/E4/1.jpg" alt="image" style="width: 500px" />
                 </div>
                 <div style="position: relative">
                    <div style="border: 1px solid; border-radius: 100rem;  height: 48px; width: 48px; position: absolute; top: -15px; left: -15px; background-color: rgb(0, 110, 147);color: white; display: flex; justify-content: center; align-items: center;"><strong>2</strong></div>
                    <img src="img/FriendsPlus/Page78/E4/2.jpg" alt="image" style="width: 550px"/>
                 </div>
              </div>
              <div style="padding: 30px">
                <div style='display:flex'>
                  <b>1 &emsp;</b>
                  <div>
                    <div> The author of the text wants to</div>
                    <div style='display:flex'>
                      <div style='width:40px;'><input id='0' type='Circle' /></div>
                      <div>
                        <div> give advice to millionaires on what to do with their money.</div>
                        <div> give an opinion of a TV programme.</div>
                        <div> explain how reality shows can be good for society</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style='display:flex'>
                  <b>2 &emsp;</b>
                  <div>
                    <div> What was the students’ first idea when they realised they had $40,000?</div>
                    <div style='display:flex'>
                      <div style='width:40px;'><input id='1' type='Circle' /></div>
                      <div>
                        <div> To split up the money and spend it</div>
                        <div> To find the person who it belonged to</div>
                        <div> To call the charity shop about the sofa</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style='display:flex'>
                  <b>3 &emsp;</b>
                  <div>
                    <div> The text gives readers</div>
                    <div style='display:flex'>
                      <div style='width:40px;'><input id='2' type='Circle' /></div>
                      <div>
                        <div> some information about different kinds of bank accounts</div>
                        <div> tips on how to manage your money as a student</div>
                        <div> a number of reasons why they should open one particular account</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="position: relative">
              <div style="border: 1px solid; border-radius: 100rem;  height: 48px; width: 48px; position: absolute; top: -15px; left: -15px; background-color: rgb(0, 110, 147);color: white; display: flex; justify-content: center; align-items: center;"><strong>3</strong></div>
              <img src="img/FriendsPlus/Page78/E4/3.jpg" alt="image" style="width: 450px"/>
            </div>
          </div>
      `,
    },
  },
  5: {
    unit: 'Cumulative',
    id: 'FG10-C-P78-E5',
    audio: '',
    video: '',
    exerciseKey: '',
    component: T6,
    textareaStyle: { width: 1000 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
        <span style="color: rgb(0, 110, 147)"> READING</span> 
        Look back at questions 1–3 in exercise 4. Explain
        in your own words why the other options are not
        correct.
        `,
        color: '#000',
      },
    ],
    questions: [
      {
        title: `
               <div>
                  <span><strong style="margin-right: 10px">1</strong>The author of the text wants to</span>
                  <div>
                    <ul style="list-style: none">
                      <li><strong style="margin-right: 10px">a</strong> give advice to millionaires on what to do with their money.<li>
                      <li><strong style="margin-right: 10px">b</strong> give an opinion of a TV programme.
                      <li>
                      <li><strong style="margin-right: 10px">c</strong> explain how reality shows can be good for society.<li>
                    </ul>
                  </div>
                <textarea id="0" rows="0">Explain:</textarea>
                </div>

                <div>
                  <span><strong style="margin-right: 10px">2</strong>What was the students' first idea when they realised they
                  had $40,000?</span>
                  <div>
                    <ul style="list-style: none">
                      <li><strong style="margin-right: 10px">a</strong> To split up the money and spend it<li>
                      <li><strong style="margin-right: 10px">b</strong> To find the person who it belonged to<li>
                      <li><strong style="margin-right: 10px">c</strong> To call the charity shop about the sofa<li>
                    </ul>
                  </div>
                <textarea id="0" rows="2">Explain:</textarea>
                </div>

                <div>
                  <span><strong style="margin-right: 10px">3</strong>The text gives readers</span>
                  <div>
                    <ul style="list-style: none">
                      <li><strong style="margin-right: 10px">a</strong> some information about different kinds of bank
                      accounts<li>
                      <li><strong style="margin-right: 10px">b</strong> tips on how to manage your money as a student<li>
                      <li><strong style="margin-right: 10px">c</strong> a number of reasons why they should open one
                      particular account<li>
                    </ul>
                  </div>
                <textarea id="0" rows="2">Explain:</textarea>
                </div>
        `,
        answer: ['', '', ''],
      },
    ],
  },
};
export default json;
